import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/styles';
import { tableThemer } from '../../../themes';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import { ChevronRight, Warning, Edit, VideogameAssetOutlined as GameAsset, FavoriteBorderOutlined as Heart, Timer } from '@mui/icons-material';
import { axios, CancelToken } from '../../../services/networkRequest';
import GameProgress from '../LN/YoungPersonComponents/GameProgress';
import ContactDetails from './ContactDetails';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../../utilities/localisation/translationKeys';
import style, {
  activeColor,
  greyBorderColor,
  listBackgroundColor,
  notActiveColor,
  numberTextColor,
} from '../../../Styles';
import { genericAlert, getChain, identity } from '../../../services/helpers';
import * as PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import Footer from '../../content/Footer';
import CotsYoungPersonPerformanceChart from '../../CotsYoungPersonPerformanceChart';
import UsageGraph from '../UsageGraph';
import { TypographyAverageTime, TypographyHoursAndMinutes } from '../../Typography/TypographyNumberSpan';
import { useNavigate } from 'react-router-dom';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { IconButton, Paper, TableContainer, TableRow, Table, TableCell, TableHead, TableBody } from '@mui/material';

const StyledCard = styled(Card)({
  minWidth: 275,
  marginBottom: '4px',
});

const StyledLeftTypography = styled(Typography)({
  display: 'inline-block',
  textAlign: 'left',
  float: 'left',
  marginTop: '8px',
});

const StyledButton = styled(Button)({
  display: 'inline-block',
  textAlign: 'right',
  float: 'right',
  // marginBottom: '16px',
  '&:hover': {
    backgroundColor: '#0079C6',
  },
  '&:active': {
    backgroundColor: 'white',
    borderColor: 'white',
    color: '#0079C6',
  },
});

// const StyledBlock = styled('div')({
//   display: 'block',
//   width: '100%',
// });

const StyledFlex = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: "center",
  width: '100%'
});

const StyledText = styled(Grid)({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  paddingTop: '50px',
});

const StyledButtonSpace = styled(Grid)({
  paddingTop: '40px',
});

const StyledDeactivateGrid = styled(Grid)({
  marginLeft: 'auto',
  color: '#3B3B3B',
  textDecoration: 'underline',
});

const StyledDeactivateButton = styled(Button)({
  marginLeft: 'auto',
  color: '#3B3B3B',
  textDecoration: 'underline',
});

const order = new Proxy({
  'complete': 1,
  'started': 2,
  'incomplete': 3,
}, {
  get: (target, key) => target[key] || 4,
});

export const setter = (list, updater) => (rowData) => {
  const index = list.findIndex(yp => yp.id === rowData.id);
  if (index > -1) {
    // shallow copy is need for react to know we changed the state or a re-render doesn't trigger
    const newList = [...list];
    newList[index] = rowData;
    updater(newList);
  }
};

export default function YoungPeoplePage(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [youngPersons, setYoungPersons] = useState(undefined);
  const [notStartedYoungPersons, setNotStartedYoungPersons] = useState(undefined);
  const [optedOutYoungPersons, setOptedOutYoungPersons] = useState(undefined);
  const [gameKeys, setGameKeys] = useState({ alert: false, totalKeys: 0, usedKeys: 0 });
  const [allPeople, setAllPeople] = useState(undefined);
  const [dataState, setDataState] = useState(false);
  const [youngPersonsExpandedRow, setYoungPersonsExpandedRow] = useState(null);
  const [notStartedExpandedRow, setNotStartedExpandedRow] = useState(null);
  const [optedOutExpandedRow, setOptedOutExpandedRow] = useState(null);

  const toggleRowExpansion = (rowId, setExpandedRow) => {
    setExpandedRow((prevRowId) => (prevRowId === rowId ? null : rowId));
  };

  const youngPersonsColumns = [
    {
      id: t(translationKey.TitlePlayingTheGame),
      header: t(translationKey.TitlePlayingTheGame),
      accessorFn: rowData => `${ rowData.first_name } ${ rowData.last_name }`,
      cell: rowData => <Typography color="textPrimary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.TitleInvited),
      accessorFn: rowData =>  rowData.created_at && new Date(rowData.created_at).toDateString(),
      cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.TitleStartedGame),
      accessorFn: rowData =>  rowData.first_play && new Date(rowData.first_play).toDateString(),
      cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.TitleSessionsPlayed),
      accessorKey: 'session_count',
      cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.TitleGameKey),
      accessorKey: 'game_key',
      cell: ({ row }) => {
        return (
          <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
            <Typography color="textSecondary" align={"left"}>{row.original.game_key}</Typography>
            <IconButton disableRipple>
              {youngPersonsExpandedRow === row.id ? <ChevronRight style={{ transform: 'rotate(90deg)' }} /> : <ChevronRight />}
            </IconButton>
          </div>
        )
      }
    }
  ];

  const notStartedYoungPersonsColumns = [
    {
      id: t(translationKey.TitleNotYetStarted),
      header: t(translationKey.TitleNotYetStarted),
      accessorFn: rowData => `${ rowData.first_name } ${ rowData.last_name }`,
      cell: rowData => <Typography color="textPrimary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.TitleInvited),
      accessorFn: rowData =>  rowData.created_at && new Date(rowData.created_at).toDateString(),
      cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.TitleStartedGame),
      accessorFn: rowData =>  rowData.first_play && new Date(rowData.first_play).toDateString(),
      cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.TitleGameKey),
      accessorKey: 'game_key',
      cell: ({ row }) => {
        return (
          <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
            <Typography color="textSecondary" align={"left"}>{row.original.game_key}</Typography>
            <IconButton disableRipple>
              {youngPersonsExpandedRow === row.id ? <ChevronRight style={{ transform: 'rotate(90deg)' }} /> : <ChevronRight />}
            </IconButton>
          </div>
        )
      }
    }
  ];

  const optedOutYoungPersonsColumns = [
    {
      id: t(translationKey.TitleOptedOut),
      header: t(translationKey.TitleOptedOut),
      accessorFn: rowData => `${ rowData.first_name } ${ rowData.last_name }`,
      cell: rowData => <Typography color="textPrimary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.TitleInvited),
      accessorFn: rowData =>  rowData.created_at && new Date(rowData.created_at).toDateString(),
      cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.TitleStartedGame),
      accessorFn: rowData =>  rowData.first_play && new Date(rowData.first_play).toDateString(),
      cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.TitleGameKey),
      accessorKey: 'game_key',
      cell: ({ row }) => {
        return (
          <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
            <Typography color="textSecondary" align={"left"}>{row.original.game_key}</Typography>
            <IconButton disableRipple>
              {youngPersonsExpandedRow === row.id ? <ChevronRight style={{ transform: 'rotate(90deg)' }} /> : <ChevronRight />}
            </IconButton>
          </div>
        )
      }
    }
  ];

  const youngPersonsTable = useReactTable({
    data: youngPersons ?? [],
    columns: youngPersonsColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  const notStartedYoungPersonsTable = useReactTable({
    data: notStartedYoungPersons ?? [],
    columns: notStartedYoungPersonsColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  const optedOutYoungPersonsTable = useReactTable({
    data: optedOutYoungPersons ?? [],
    columns: optedOutYoungPersonsColumns,
    getCoreRowModel: getCoreRowModel(),
  });


  const reloadData = () => {
    setDataState(!dataState);
    setAllPeople(undefined);
    setYoungPersons([]);
    setNotStartedYoungPersons([]);
    setOptedOutYoungPersons([]);
    toggleRowExpansion(null, setYoungPersonsExpandedRow);
    toggleRowExpansion(null, setNotStartedExpandedRow);
    toggleRowExpansion(null, setOptedOutExpandedRow);
  };

  useEffect(() => {
    let cancel;
    const cancelToken = new CancelToken(c => {
      cancel = c;
    });

    axios.get('/cots/young_persons', {
      cancelToken,
    }).then(({ data }) => {
      setAllPeople(data.young_persons.active.length + data.young_persons.not_yet_active.length + data.young_persons.inactive.length);
      setYoungPersons(data.young_persons.active);
      setNotStartedYoungPersons(data.young_persons.not_yet_active);
      setOptedOutYoungPersons(data.young_persons.inactive);
      setGameKeys({ alert: data.alert, totalKeys: data.total_keys, usedKeys: data.used_keys });
    }).catch(identity);

    return () => {
      cancel();
    };
  }, [dataState]);

  const renderZeroState = () => {
    return (
      <Container component="main" maxWidth="lg">
        <StyledCard>
          <CardContent>
            <StyledText container direction='column' alignItems='center' justify='center'>
              <Grid size={{ xs: 6 }}>
                <Typography component="h5" align="center" variant="h5" style={ { fontWeight: 800 } }>
                  {t(translationKey.LabelInviteYoungPerson)}
                </Typography>
              </Grid>
            </StyledText>
            <StyledButtonSpace container spacing={ 2 } justify='center'>
              <div>
                <StyledButton variant="contained" color="primary"
                        onClick={ () => {
                          navigate('/cots/young_person/create');
                        } }>
                  {t(translationKey.ButtonInviteYoungPerson)}
                </StyledButton>
              </div>
            </StyledButtonSpace>
            <div style={ { margin: '25px -50px', borderTop: `${ numberTextColor } solid 4px` } } />
            <CardMedia image="/GettingStartedCots.jpg" title="" style={ { height: 700 } } />
          </CardContent>
        </StyledCard>
        <Footer />
      </Container>
    );
  };

  const RenderDetailFactory = ({borderColour = "#FFF", setter, rowData}) => <RenderDetails
    setYoungPersonData={ setter }
    borderColour={ borderColour }
    reloadData={ reloadData }
    rowData={ rowData } { ...props } />;

  const renderList = props => {
    return (
      <Container component="main" maxWidth="lg" style={ { marginTop: '10px' } }>
        <StyledCard>
          <CardContent style={{ padding: '16px' }}>
            <StyledFlex>
              <StyledLeftTypography color="textSecondary">
                <strong>{t(translationKey.TitleLicensesUsed)}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>{ gameKeys.alert ?
                <Warning htmlColor='black' style={ {
                  height: '0.7em',
                  marginBottom: '-0.12em',
                } } /> : null }{t(translationKey.LabelLicensesUsed,{ used: gameKeys.usedKeys, total: gameKeys.totalKeys})}
              </StyledLeftTypography>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={ () => navigate('/cots/young_person/create') }>
                {t(translationKey.ButtonInviteYoungPerson)}
              </StyledButton>
            </StyledFlex>
          </CardContent>
        </StyledCard>
        <ThemeProvider theme={ tableThemer(youngPersons) }>
          <Paper>
            <TableContainer>
              <Table>
                <TableHead  style={{ backgroundColor: listBackgroundColor }}>
                  {youngPersonsTable.getHeaderGroups().map((headerGroup) => (
                    <TableRow key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <TableCell key={header.id}>
                          {flexRender(header.column.columnDef.header, header.getContext()) }
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableHead>
                <TableBody>
                  {youngPersonsTable.getRowModel().rows.map((row, index) => (
                    <React.Fragment key={row.id}>
                      <TableRow 
                        style={{ cursor: 'pointer', borderBottom: `1px solid ${greyBorderColor}`, borderLeft: `20px solid ${ activeColor }`, borderRight: `1px solid ${ greyBorderColor }`, borderTop: `1px solid ${ greyBorderColor }`}} 
                        onClick={() => toggleRowExpansion(row.id, setYoungPersonsExpandedRow)}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <TableCell key={cell.id} style={{ padding: '10px' }}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </TableCell>
                        ))}
                      </TableRow>
                      {youngPersonsExpandedRow === row.id && (
                        <TableRow  style={{ cursor: 'pointer', borderBottom: `1px solid ${greyBorderColor}`, borderLeft: `20px solid ${ activeColor }`, borderRight: `1px solid ${ greyBorderColor }`, borderTop: `1px solid ${ greyBorderColor }`}}>
                          <TableCell colSpan={youngPersonsColumns.length} style={{ backgroundColor: listBackgroundColor }}>
                            <RenderDetailFactory activeColor={activeColor} setter={ setter(youngPersons, setYoungPersons)} rowData={row.original} />
                          </TableCell>
                        </TableRow>
                      )}
                      {/* Spacer Row */}
                      {index !== youngPersonsTable.getRowModel().rows.length - 1 && (
                        <TableRow sx={{ height: "10px", backgroundColor: "transparent" }}>
                          <TableCell colSpan={youngPersonsColumns.length} sx={{padding: 0, height: "10px"}}/>
                        </TableRow>
                      )}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </ThemeProvider>
        <ThemeProvider theme={ tableThemer(notStartedYoungPersons) }>
          <Paper>
            <TableContainer>
              <Table>
                <TableHead style={{ backgroundColor: listBackgroundColor }}>
                  {notStartedYoungPersonsTable.getHeaderGroups().map((headerGroup) => (
                    <TableRow key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <TableCell key={header.id}>
                          {flexRender(header.column.columnDef.header, header.getContext()) }
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableHead>
                <TableBody>
                  {notStartedYoungPersonsTable.getRowModel().rows.map((row, index) => (
                    <React.Fragment key={row.id}>
                      <TableRow
                        style={{ cursor: 'pointer', borderBottom: `1px solid ${greyBorderColor}`, borderLeft: `20px solid ${ notActiveColor }`, borderRight: `1px solid ${ greyBorderColor }`, borderTop: `1px solid ${ greyBorderColor }`}} 
                        onClick={() => toggleRowExpansion(row.id, setNotStartedExpandedRow)}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <TableCell key={cell.id} style={{ padding: '10px' }}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </TableCell>
                        ))}
                      </TableRow>
                      {notStartedExpandedRow === row.id && (
                        <TableRow  style={{ cursor: 'pointer', borderBottom: `1px solid ${greyBorderColor}`, borderLeft: `20px solid ${ notActiveColor }`, borderRight: `1px solid ${ greyBorderColor }`, borderTop: `1px solid ${ greyBorderColor }`}}>
                          <TableCell colSpan={notStartedYoungPersonsColumns.length} style={{ backgroundColor: listBackgroundColor }}>
                            <RenderDetailFactory activeColor={notActiveColor} setter={ setter(notStartedYoungPersons, setNotStartedYoungPersons)} rowData={row.original} />
                          </TableCell>
                        </TableRow>
                      )}
                      {/* Spacer Row */}
                      {index !== notStartedYoungPersonsTable.getRowModel().rows.length - 1 && (
                        <TableRow sx={{ height: "10px", backgroundColor: "transparent" }}>
                          <TableCell colSpan={notStartedYoungPersonsColumns.length} sx={{padding: 0, height: "10px"}}/>
                        </TableRow>
                      )}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </ThemeProvider>
        <ThemeProvider theme={ tableThemer(optedOutYoungPersons) }>
          <Paper>
            <TableContainer>
              <Table>
                <TableHead style={{ backgroundColor: listBackgroundColor }}>
                  {optedOutYoungPersonsTable.getHeaderGroups().map((headerGroup) => (
                    <TableRow key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <TableCell key={header.id}>
                          {flexRender(header.column.columnDef.header, header.getContext()) }
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableHead>
                <TableBody>
                  {optedOutYoungPersonsTable.getRowModel().rows.map((row, index) => (
                    <React.Fragment key={row.id}>
                      <TableRow
                        style={{ cursor: 'pointer', borderBottom: `1px solid ${greyBorderColor}`, borderLeft: `20px solid ${ greyBorderColor }`, borderRight: `1px solid ${ greyBorderColor }`, borderTop: `1px solid ${ greyBorderColor }`}} 
                        onClick={() => toggleRowExpansion(row.id, setOptedOutExpandedRow)}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <TableCell key={cell.id} style={{ padding: '10px' }}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </TableCell>
                        ))}
                      </TableRow>
                      {optedOutExpandedRow === row.id && (
                        <TableRow style={{ cursor: 'pointer', borderBottom: `1px solid ${greyBorderColor}`, borderLeft: `20px solid ${ greyBorderColor }`, borderRight: `1px solid ${ greyBorderColor }`, borderTop: `1px solid ${ greyBorderColor }`}}>
                          <TableCell colSpan={optedOutYoungPersonsColumns.length} style={{ backgroundColor: listBackgroundColor }}>
                            <RenderDetailFactory activeColor={greyBorderColor} setter={ setter(optedOutYoungPersons, setOptedOutYoungPersons)} rowData={row.original} />
                          </TableCell>
                        </TableRow>
                      )}
                      {/* Spacer Row */}
                      {index !== optedOutYoungPersonsTable.getRowModel().rows.length - 1 && (
                        <TableRow sx={{ height: "10px", backgroundColor: "transparent" }}>
                          <TableCell colSpan={optedOutYoungPersonsColumns.length} sx={{padding: 0, height: "10px"}}/>
                        </TableRow>
                      )}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </ThemeProvider>
        <Footer />
      </Container>
    );
  };

  return (
    <>
      { allPeople === 0 ? renderZeroState() : renderList(props) }
    </>
  );
}

const sorting = progress => {
  return progress && progress.sort((a, b) => order[a.status] - order[b.status]);
};

export function RenderDetails(props) {
  const {
    rowData,
    setYoungPersonData,
    reloadData,
    borderColour,
  } = props;
  console.log(borderColour);
  
  const currentDate = new Date();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(rowData);
  const graph_data = getChain(userData, 'performance_data');
  const first_graph_data = getChain(graph_data, 'first_graph');
  const recent_graph_data = getChain(graph_data, 'recent_graph');
  const { t } = useTranslation();

  useEffect(() => {
    if (!getChain(userData, 'stats')) {
      axios.get(`/cots/young_persons/${ userData.id }`)
        .then(({ data }) => {
          userData.stats = data;
          setUserData(userData);
          setYoungPersonData(userData);
        })
        .catch(error => {
          genericAlert(t(translationKey.TitleError), getChain(error, 'response', 'data', 'error'));
        });
    }

    let url = `/cots/young_persons/${ userData.id }/chart`;

    if (!graph_data) {
      axios.get(url)
        .then(({ data }) => {
          userData['performance_data'] = data;

          setUserData(userData);
          setYoungPersonData(userData);
        });
    }

  }, [userData, setYoungPersonData, graph_data, t]);

  const { tableData, ...restUserData } = userData;
  const player = userData.first_name;
  const progress = sorting(getChain(userData, 'stats', 'progress'));
  const gameTime = getChain(userData, 'stats', 'game_time');
  const lastUsedDateString = gameTime && gameTime.lastLoggedIn && new Date(gameTime.lastLoggedIn).toLocaleDateString();
  const averageTime = gameTime ? gameTime.timeAverage : '';
  const totalTime = getChain(gameTime, 'timeSpent');
  const totalDuels = getChain(gameTime, 'totalDuels') || 0;
  const totalHours = gameTime ? Math.floor(totalTime / 60) : '';
  const totalMinutes = gameTime ? Math.round(totalTime - (totalHours * 60)) : '';
  const improving = getChain(gameTime, 'improving');
  let startDate = new Date(userData.first_play);
  if (startDate.getDate() >= currentDate.getDate()) {
    startDate.setDate(currentDate.getDate() - 1);
  }

  return (
    <div style={ {
      backgroundColor: listBackgroundColor,
      position: 'relative',
      width: 'calc(100% + 1px)',
      borderLeft: `20px solid ${ borderColour }`,
      borderRight: `1px solid ${ greyBorderColor }`,
      boxSizing: 'border-box',
      overflowY: 'auto',
      overflowX: 'hidden',
      marginTop: '-18px',
    } }
         key={ userData.id }
    >
      <Grid container style={ { width: 'inherit', margin: 0, paddingLeft: '30px', paddingTop: '30px' } }
            spacing={ 3 }>
        { userData.first_play && <>
          <Grid size={{ xs: 12 }}>
            <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
              <CardContent>
                <Grid container>
                  <Grid size={{ xs: 6 }}><Typography color="textPrimary">{t(translationKey.TitlePlayerGameTime, { player })}</Typography></Grid>
                  <Grid size={{ xs: 6 }}><Typography color="textSecondary"
                                                  align="right">{(t(translationKey.TitleLastUsedDate, { lastUsedDateString : lastUsedDateString|| '' }))}</Typography></Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid size={{ xs: 3 }}>
            <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
              <CardContent>
                <Typography color="textPrimary">{t(translationKey.LabelTotalDuels)}</Typography>
                <Grid container>
                  <Grid size={{ xs: 10 }}><Typography
                    style={ { fontSize: '30px', color: numberTextColor } }>{ totalDuels }</Typography></Grid>
                  <Grid size={{ xs: 2 }}><GameAsset style={ { fontSize: '40px' } } /></Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid size={{ xs: 3 }}>
            <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
              <CardContent>
                <Typography color="textPrimary">{t(translationKey.LabelAverageSessionTime)}</Typography>
                <Grid container>
                  <Grid size={{ xs: 10 }}>
                    <TypographyAverageTime average={averageTime} />
                  </Grid>
                  <Grid size={{ xs: 2 }}><Timer style={ { fontSize: '40px' } } /></Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid size={{ xs: 3 }}>
            <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
              <CardContent>
                <Typography color="textPrimary">{t(translationKey.LabelTotalTimePlaying)}</Typography>
                <Grid container>
                  <Grid size={{ xs: 10 }}>
                    <TypographyHoursAndMinutes totalHours={totalHours} totalMinutes={totalMinutes} />
                  </Grid>
                  <Grid size={{ xs: 2 }}><Timer style={ { fontSize: '40px' } } /></Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid size={{ xs: 3 }}>
            <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
              <CardContent>
                <Typography color="textPrimary">{t(translationKey.LabelPlayerPerformance)}</Typography>
                <Grid container>
                  <Grid size={{ xs: 10 }}>
                      <Typography style={ { fontSize: '30px', color: numberTextColor } }>
                      {(() => {
                      switch (improving) {
                        case 1: return t(translationKey.LabelImproving);
                        case 0: return t(translationKey.LabelNotImproving);
                        default: return t(translationKey.LabelInsufficientData);
                      }
                    })()}</Typography>


                    </Grid>
                  <Grid size={{ xs: 2 }}><Heart style={ { fontSize: '40px' } } /></Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <UsageGraph playerName={player} userData={userData} setUserData={(userData) => {
            setUserData(userData);
            setYoungPersonData(userData);
          } } chartUrl={`/cots/young_persons/${ userData.id }/usage_chart`} />
          <Grid size={{ xs: 12 }}>
            <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
              <CardContent>
                <Grid container>
                  <Grid size={{ xs: 12 }}>
                    <Typography align='left' color="textPrimary" style={{marginRight: '5px'}}>{t(translationKey.LabelPerformanceOverFirst20)}</Typography>
                  </Grid>
                </Grid>
                <CotsYoungPersonPerformanceChart data={ first_graph_data }/>
                <Grid size={{ xs: 12 }}>
                    <Typography align='left' color="textPrimary" style={{marginRight: '5px'}}>{t(translationKey.LabelPerformanceOverLast20)}</Typography>
                  </Grid>
                <CotsYoungPersonPerformanceChart data={ recent_graph_data }/>
              </CardContent>
            </Card>
          </Grid>
          { progress && progress.length > 0 && <Grid size={{ xs: 12 }}>
            <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
              <CardContent>
                <Typography color="textSecondary">{t(translationKey.TitlePlayerGameProgress, { player })}</Typography>
                <GameProgress progress={ progress } />
              </CardContent>
            </Card>
          </Grid> }
        </> }
        <Grid size={{ xs: 12 }}>
          <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
            <CardContent>
              <Grid container>
                <Grid size={{ xs: 10 }}>
                  <Typography color="textSecondary">{t(translationKey.LabelContact)}</Typography>
                </Grid>
                <Grid size={{ xs: 2 }}>
                  <Grid container>
                    <Grid size={{ xs: 6 }} style={ { marginLeft: 'auto' } }>
                      <Button
                        onClick={ () => {
                          const { dob, ethnicity, first_name, gender, telephone, has_disability, id, last_name, post_code, service_user_reference, country } = userData;
                          navigate(`/cots/young_person/${ id }/edit`, {
                            state: {
                              youngPerson: {
                                dob,
                                ethnicity,
                                first_name,
                                gender,
                                telephone,
                                has_disability,
                                id,
                                last_name,
                                post_code,
                                country,
                                service_user_reference
                              }
                            }
                          });
                        } }
                      >
                        <Edit htmlColor={ greyBorderColor } />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <ContactDetails { ...userData } />
            </CardContent>
          </Card>
        </Grid>

        <StyledDeactivateGrid style={ { display: 'flex', justifyContent: 'space-between', width: '100%' } }>
          <Grid size={{ xs: 3 }}>
            <ThemeProvider theme={ style }>
              <Button fullWidth variant="contained" color="primary"
                      onClick={ () => {
                        navigate(
                          `/cots/young_person/${ userData.id }/report`,
                          {state: {userData: restUserData}
                        });
                      } }>
                {t(translationKey.ButtonDownloadReport)}
              </Button>
            </ThemeProvider>
          </Grid>
          { userData.deleted_at ?
            <RemovalButton title={t(translationKey.TitleDeleteYoungPerson)} action={ () => {
              genericAlert({
                title: t(translationKey.AlertTitleConfirmDeleteYoungPerson),
                message: t(translationKey.AlertBodyConfirmDeleteYoungPerson),
                negativeTitle: t(translationKey.LabelCancel),
                positiveTitle: t(translationKey.LabelDelete),
                positiveCallback: () => {
                  axios
                    .delete(`/cots/young_persons/${ userData.id }/anonymise`)
                    .then(reloadData);
                },
              });
            } } />
            : <RemovalButton title={t(translationKey.TitleDeactivateGameKey)} action={ () => {
              axios.get('/cots/young_persons/deactivation_reason')
                .then(({ data }) => {
                  let reason;
                  const setReason = (r) => {
                    reason = r;
                  };
                  genericAlert({
                    title: t(translationKey.AlertTitleDeactivationReason),
                    component: () => <ReasonSelection data={ data } setReason={ setReason } />,
                    negativeTitle: t(translationKey.ButtonCancel),
                    positiveTitle: t(translationKey.ButtonSubmit),
                    positiveCallback: () => {
                      const { id } = userData;
                      if (id) {
                        axios.delete(`/cots/young_persons/${ id }`, {
                          data: {
                            reason,
                          },
                        })
                          .then(reloadData)
                          .catch(identity);
                      }
                    },
                  });
                });
            } }
            />
          }
        </StyledDeactivateGrid>
      </Grid>
    </div>
  );
}

const RemovalButton = ({ title, action }) =>
  <StyledDeactivateButton onClick={ action }>
    { title }
  </StyledDeactivateButton>;

RemovalButton.propTypes = {
  title: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
};

RenderDetails.propTypes = {
  borderColour: PropTypes.string.isRequired,
  rowData: PropTypes.object.isRequired,
  reloadData: PropTypes.func.isRequired,
  setYoungPersonData: PropTypes.func.isRequired,
};

const ReasonSelection = ({ data, setReason }) => {
  const [internalReason, setInternalReason] = useState();
  const { t } = useTranslation();
  return (
    <form>
      <FormControl component="fieldset">
        <FormLabel component="legend">{t(translationKey.TitleReason)}</FormLabel>
        <RadioGroup aria-label={t(translationKey.LabelReason)} name='reason'
                    onChange={ event => {
                      let value = parseInt(event.target.value);
                      setReason(value);
                      setInternalReason(value);
                    } }>
          {
            data.map(each => <FormControlLabel key={ each.id } value={ each.id } control={ <Radio /> }
                                               label={ each.value } checked={ each.id === internalReason } />)
          }
        </RadioGroup>
      </FormControl>
    </form>
  );
};

ReasonSelection.propTypes = {
  data: PropTypes.array.isRequired,
  setReason: PropTypes.func.isRequired,
};
