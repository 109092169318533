import React from 'react';
import { axios, catchAxios } from '../../services/networkRequest';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import { Formik } from 'formik';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { genericAlert, getChain } from '../../services/helpers';
import Error from '../Error';
import RequiredInfoText from '../RequiredInfoText';
import { helperTextColor } from '../../constants';
import Card from '@mui/material/Card';
import ExitButton from '../ExitButton';
import { Grid2 as Grid } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/styles';
import { useViewport } from '../../utilities/viewport';
import { translationKey } from '../../utilities/localisation/translationKeys';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';


const StyledFormControl = styled(FormControlLabel)(({ theme }) => ({
  width: '100%',
  marginTop: '16px',
  textAlign: 'left',
  display: 'flex',
  justifyContent: 'space-between',
}));

function OrganisationSettings(props) {
  const location = useLocation()
  const navigate = useNavigate();
  const organisation = location.state.organisation;
  const { t } = useTranslation();

  const confirmation = callback => {
    genericAlert(t(translationKey.AlertTitleCancelOrganisationForm), t(translationKey.AlertBodyCancelOrganisationForm), callback);
  };

  return (
    <Card>
      <ExitButton style={ { float: 'right', padding: '20px' } } confirmation={ confirmation } />
      <Container component="main" maxWidth="xs" style={ { padding: '20px' } }>
        <CssBaseline />
        <Typography component="h1" variant="h5">
          {t(translationKey.LabelOrganisationSettings,
            { organisation_name: organisation.name })}
        </Typography>
        <RequiredInfoText />
        <Formik
          initialValues={ {
            odsCode: getChain(organisation, 'ods_code') || undefined,
            orgIdComm: getChain(organisation, 'org_id_comm') || undefined,
            includeForSubmission: getChain(organisation, 'include_for_submission_mhsds') ?? false,
            enableDownload: getChain(organisation, 'enable_mhsds_download') ?? false,
          } }
          onSubmit={ (values, { setSubmitting, setFieldError }) => {
            setSubmitting(true);
            const submitFunction = () => {
              axios['put'](`/organisation/${organisation.id}/settings`, values)
                .then(() => {
                  navigate('/', { replace: true});
                })
                .catch(err => {
                  catchAxios(setFieldError)(err);
                  setSubmitting(false);
                });
            };
            submitFunction();
          } }
        >
          { ({
               errors,
               values,
               handleChange,
               handleBlur,
               handleSubmit,
             }) => (
            <form onSubmit={ handleSubmit }>
              <Error message={ errors['network'] } />

              <CheckboxFieldWithTitle title={ t(translationKey.LabelOrganisationEnableMHSDSForAdminDownload) }
                                      formLabelValue="includeForSubmission"
                                      name="includeForSubmission"
                                      value={ values.includeForSubmission }
                                      checked={ values.includeForSubmission }
                                      onChange={ handleChange }
              />

              <CheckboxFieldWithTitle title={ t(translationKey.LabelOrganisationEnableMHSDS) }
                                      formLabelValue="enableDownload"
                                      name="enableDownload"
                                      value={ values.enableDownload }
                                      checked={ values.enableDownload }
                                      onChange={ handleChange }
              />

              <TextField
                type="text"
                variant="outlined"
                margin="normal"
                fullWidth
                autoFocus
                label={ t(translationKey.LabelOrganisationODSCode) }
                value={ values.odsCode }
                name="odsCode"
                onChange={ handleChange }
                onBlur={ handleBlur }
                slotProps={{ formHelperText: { style: { color: helperTextColor } } }}
              />

              <TextField
                type="text"
                variant="outlined"
                margin="normal"
                fullWidth
                value={ values.orgIdComm }
                label={ t(translationKey.LabelOrganisationOrgIdComm) }
                name="orgIdComm"
                onChange={ handleChange }
                onBlur={ handleBlur }
                slotProps={{ formHelperText: { style: { color: helperTextColor } } }}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary">{t(translationKey.ButtonSubmit)}</Button>
            </form>) }
        </Formik>
      </Container>
    </Card>
  );
}

function CheckboxFieldWithTitle({ leftColumnWidth, rightColumnWidth, title, formLabelValue, ...props }) {

  return (
    <Row>
      <Grid size={ {xs: 12} }>
        <StyledFormControl
          value={ formLabelValue }
          control={
            <Checkbox
              color="primary"
              inputProps={ { 'aria-label': 'secondary checkbox' } }
              { ...props }
            />
          }
          label={title}
          labelPlacement="start" />
      </Grid>
    </Row>
  );
}

function Row({ children }) {
  const { views: { mobile } } = useViewport();

  return (
    <Grid container direction={ mobile ? 'column' : 'row' }
          justify="space-between" alignItems={ mobile ? '' : 'center' } style={ { margin: '0.5rem 0' } }>
      { children }
    </Grid>
  )
}

export default OrganisationSettings;
