import React, { useEffect, useState } from 'react';
import { Container, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { tableThemer } from '../themes';
import { axios, catchAxios } from '../services/networkRequest';
import { genericAlert, getChain, ROLES } from '../services/helpers';
import { activeColor, greyBorderColor, listBackgroundColor } from '../Styles';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { CloudDownload, Settings } from '@mui/icons-material';
import { translationKey } from '../utilities/localisation/translationKeys';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import { helperTextColor } from '../constants';
import TextField from '@mui/material/TextField';
import Error from './Error';
import { Formik } from 'formik';
import { Modal } from './Modal';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';

const MHSDSAggregatedData = (props) => {
  const [files, setFiles] = useState();
  const [odsCode, setOdsCode] = useState();
  const [showMHSDSSettings, setShowMHSDSSettings] = useState(false);
  const url = props.role === ROLES.admin ? '/mhsds_data' : `organisation/mhsds_data`;
  const { t } = useTranslation();

  const columns = [
    {
      header: t(translationKey.TitleFilename),
      accessorKey: 'file',
      cell: rowData => <Typography color="textPrimary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.TitleLastModified),
      accessorFn: rowData =>  rowData.last_modified && new Date(rowData.last_modified).toDateString(),
      cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.TitleFileSize),
      accessorKey: 'size',
      cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>
    },
    {
      header: "Actions",
      cell: ({ row }) => <IconButton disableRipple onClick={() => downloadXLSX(row.original.file)}><CloudDownload /></IconButton>
    }
  ];

  const table = useReactTable({
    data: files ?? [],
    columns,
    getCoreRowModel: getCoreRowModel()
  })


  function downloadXLSX(filename) {
    axios.getFile(`${ url }/${ filename }`, filename);
  }

  useEffect(() => {
    axios.get(url)
      .then(({ data }) => {
        setFiles(getChain(data, 'files'));
        setOdsCode(getChain(data, 'ods_code'));
      });
  }, [url]);


  return (
    <Container component="main" maxWidth="lg">
      { props.role === ROLES.admin && 
      <Grid container justifyContent="space-between">
        <Grid />
        <Button mb={ 10 } align="right" variant="contained" color="primary"
          onClick={ () => genericAlert(t(translationKey.AlertTitleStartProcessing),
          t(translationKey.AlertBodyStartProcessingMHSDS),
          () => {
            axios.post('/mhsds_data/update');
          })}
        >
          { t(translationKey.ButtonGetUpdatedData) }
        </Button>

        <Grid onClick={ () => setShowMHSDSSettings(true) } >
          <Settings style={ {cursor: 'pointer'} }/>
        </Grid>
      </Grid>
      }
      <ThemeProvider theme={ tableThemer(files) }>
        <Paper>
          <TableContainer>
            <Table>
              <TableHead style={{ backgroundColor: listBackgroundColor }}>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <TableCell key={header.id}>
                        {flexRender(header.column.columnDef.header, header.getContext()) }
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody>
                {table.getRowModel().rows.map((row, index) => (
                  <React.Fragment key={row.id}>
                    <TableRow 
                      style={{ cursor: 'pointer', borderBottom: `1px solid ${greyBorderColor}`, borderLeft: `20px solid ${ activeColor }`, borderRight: `1px solid ${ greyBorderColor }`, borderTop: `1px solid ${ greyBorderColor }`}} 
                    >
                      {row.getVisibleCells().map((cell) => (
                        <TableCell key={cell.id} style={{ padding: '10px' }}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </TableCell>
                      ))}
                    </TableRow>
                    {/* Spacer Row */}
                    {index !== table.getRowModel().rows.length - 1 && (
                      <TableRow sx={{ height: "10px", backgroundColor: listBackgroundColor }}>
                        <TableCell colSpan={columns.length} sx={{padding: 0, height: "10px"}}/>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        {/* <MaterialTable
          isLoading={ !files }
          columns={ [
            {
              title: t(translationKey.TitleFilename),
              field: 'file',
              cellStyle: {
                borderLeft: `20px solid ${ activeColor }`,
                borderRight: 'none',
              },
            },
            {
              title: t(translationKey.TitleLastModified),
              field: 'last_modified',
              cellStyle: {
                borderRight: 'none',
              },
            },
            {
              title: t(translationKey.TitleFileSize),
              field: 'size',
              cellStyle: {
                borderRight: 'none',
              },
            },
          ] }
          data={ files }
          options={ {
            actionsColumnIndex: 3,
            paging: false,
            search: true,
            sorting: true,
            showTitle: false,
            toolbar: false,
            headerStyle: {
              backgroundColor: listBackgroundColor,
            },
          } }

          actions={ [
            {
              icon: () => <CloudDownload />,
              onClick: (_, rowData) => downloadXLSX(rowData.file),
            },
          ] }
          icons={ { DetailPanel: ChevronRight } }
          style={ { backgroundColor: listBackgroundColor } }
        /> */}
      </ThemeProvider>

      {
        showMHSDSSettings &&
        <Modal close={ () => setShowMHSDSSettings(false) }>
          <Container component="main" style={ { padding: '20px', width: '50%' } }>
            <CssBaseline />
            <h2>{ t(translationKey.LabelMHSDSSettings) } </h2>

            <Formik
              initialValues={ { odsCode, } }
              onSubmit={ (values, { setSubmitting, setFieldError }) => {
                setSubmitting(true);
                const submitFunction = () => {
                  axios['put']('/admin/ods_code', values)
                    .then(() => {
                      setShowMHSDSSettings(false);
                    })
                    .catch(err => {
                      catchAxios(setFieldError)(err);
                      setSubmitting(false);
                    });
                };

                submitFunction();
              } }
            >
              { ({
                   errors,
                   values,
                   handleChange,
                   handleSubmit,
                 }) => (
                <form onSubmit={ handleSubmit }>
                  <Error message={ errors['network'] } />

                  <TextField
                    type="text"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    autoFocus
                    label={ t(translationKey.LabelOrganisationODSCode) }
                    value={ values.odsCode }
                    name="odsCode"
                    onChange={ handleChange }
                    slotProps={{ formHelperText: { style: { color: helperTextColor } } }}
                  />

                  <Button
                    style={ {
                      marginRight: '1em',
                    } }
                    color="primary"
                    type="submit"
                    variant="contained">{ t(translationKey.ButtonSubmit) }</Button>

                </form>) }
            </Formik>
          </Container>
        </Modal>
      }

    </Container>
  );
}

export default MHSDSAggregatedData;
