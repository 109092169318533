import React from 'react';
import { styled } from '@mui/styles';
import { Card, CardContent, Container, Radio, Grid2 as Grid, Button, Typography, TextField, FormControlLabel, RadioGroup, FormLabel, FormControl, MenuItem, Select, InputLabel } from '@mui/material';
import { Formik } from 'formik';
import { axios, catchAxios } from '../../../services/networkRequest';
import {
  formatDate,
  genericAlert,
  getChain,
  disabilityParser,
  disabilityUnparser,
  genderParser,
  genderUnparser,
} from '../../../services/helpers';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import update from 'immutability-helper';
import { helperTextColor, checkPostcodeValidationRequired, countryList } from '../../../constants';
import { addYears } from 'date-fns';
import RequiredInfoText from '../../RequiredInfoText';
import ExitButton from '../../ExitButton';
import Error from '../../Error';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../../utilities/localisation/translationKeys';
import { useNavigate } from 'react-router-dom';
import { StyledDetailedSection } from '../../../StyledComponents';
import styles from './YoungPersonForm.module.css'
import { useViewport } from '../../../utilities/viewport';
import { HelperText } from '../../Title';

const phoneUtil = PhoneNumberUtil.getInstance();


const StyleClasses = {
  textField: {
    width: '100%',
  },
  formControl: {
    width: '100%',
    marginTop: '16px',
    textAlign: 'left',
  },
  bottomBorder: {
    borderBottom: '1px solid #ccc',
  },
  topBorder: {
    borderTop: '1px solid #ccc',
    marginTop: '75px',
    paddingTop: '20px',
  },
  disabilitySpace: {
    marginTop: '10px',
  },
  card: {
    minWidth: 275,
  },
};

const StyledCard = styled(Card)(({ theme }) => ({
  ...StyleClasses.card,
}));


const StyledTopBorder = styled(Grid)(({ theme }) => ({
  ...StyleClasses.topBorder,
}));

const StyledBottomBorder = styled(Grid)(({ theme }) => ({
  ...StyleClasses.bottomBorder,
}));

export default function YoungPersonForm({ youngPerson, path, title }) {
  const method = youngPerson ? 'put' : 'post';
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { views: { mobile } } = useViewport();
  const standardComponentXS = mobile ? 12 : 6;

  // Convert the date format to the one expected by the form.
  let dob = getChain(youngPerson, 'dob') || '';
  if (dob) {
    dob = formatDate(new Date(dob));
  }

  const maxDateString = formatDate(addYears(new Date(), -6));
  const id = getChain(youngPerson, 'id');

  const inputLabel = React.useRef(null);
  const confirmation = callback => {
    genericAlert(t(translationKey.AlertTitleCancelYoungForm), t(translationKey.AlertBodyCancelYoungForm), callback);
  };

  const country = getChain(youngPerson, 'country') || 'United Kingdom';
  let [postcodeValidation, postcodeValidationRequired] = checkPostcodeValidationRequired(country);

  return (
    <Container component="main" maxWidth="lg">
      <StyledCard>
        <CardContent>
          <Formik
            initialValues={ {
              child: {
                first_name: getChain(youngPerson, 'first_name') || '',
                last_name: getChain(youngPerson, 'last_name') || '',
                dob: dob,
                ethnicity: getChain(youngPerson, 'ethnicity') || '',
                disability: disabilityParser(getChain(youngPerson, 'has_disability')),
                gender: genderParser(getChain(youngPerson, 'gender')) || '',
                post_code: getChain(youngPerson, 'post_code') || '',
                telephone: getChain(youngPerson, 'telephone') || '',
                service_user_reference: getChain(youngPerson, 'service_user_reference') || '',
                country: country || 'United Kingdom',
              },
            } }

            validate={ values => {
              const errors = {};
              if (!values.child.first_name) {
                errors.childFirstName = t(translationKey.ErrorSpecifyFirstName);
              }
              if (!values.child.last_name) {
                errors.childLastName = t(translationKey.ErrorSpecifyLastName);
              }
              if (!values.child.dob) {
                errors.dob = t(translationKey.ErrorSpecifyDOB);
              }
              if (!values.child.telephone) {
                errors.number = t(translationKey.ErrorSpecifyNumber);
              } else {
                try {
                  const number = phoneUtil.parseAndKeepRawInput(values.child.telephone, 'GB');
                  if (!(phoneUtil.isPossibleNumber(number) && phoneUtil.isValidNumber(number))) {
                    errors.number = t(translationKey.ErrorValidPhoneNumber);
                  }
                } catch (err) {
                  errors.number = t(translationKey.ErrorValidPhoneNumber);
                }
              }
              if (values.child.post_code) {
                values.child.post_code = values.child.post_code.toUpperCase();
              }

              [postcodeValidation, postcodeValidationRequired] = checkPostcodeValidationRequired(values.child.country);

              return errors;
            } }
            onSubmit={ (values, { setSubmitting, setFieldError }) => {
              setSubmitting(true);
              // Reverse date format for the backend
              const reformattedDoB = formatDate(values.child.dob);
              // Convert phone number into international format
              const number = phoneUtil.parseAndKeepRawInput(values.child.telephone, 'GB');
              const internationalNumber = phoneUtil.format(number, PhoneNumberFormat.E164);
              const updatedValues = update(values, {
                child: {
                  dob: { $set: reformattedDoB },
                  disability: { $set: disabilityUnparser(values.child.disability) },
                  gender: { $set: genderUnparser(values.child.gender) },
                  telephone: { $set: internationalNumber },
                },
              });
              if (youngPerson && !id) {
                // Young Person with no ID means that it is a demo user
                navigate('/cots/young_persons');
              } else {
                axios[method](path, updatedValues)
                  .then(() => {
                    if (youngPerson) {
                      navigate('/cots/young_persons', {replace: true});
                    } else {
                      navigate('/cots/young_person/confirm', {replace: true});
                    }
                  })
                  .catch(err => {
                    if (getChain(err, 'response', 'status') === 402) {
                      const { title, body: message } = getChain(err, 'response', 'data');
                      genericAlert({
                        title,
                        message,
                        negativeTitle: t(translationKey.ButtonTitleBack),
                      });
                    } else {
                      catchAxios(setFieldError)(err);
                    }
                    setSubmitting(false);
                  });
              }
            } }
          >
            { ({
                 values,
                 errors,
                 touched,
                 handleChange,
                 handleBlur,
                 handleSubmit,
                 isSubmitting,
                 /* and other goodies */
               }) => {
              return (
                <form onSubmit={ handleSubmit } autoComplete="off">
                  <StyledBottomBorder container direction="row" justify="space-between" alignItems="center">
                    <Grid size={{ xs: 11 }}>
                      <Typography component="h6" variant="body1" align="left">{ title }</Typography>
                    </Grid>
                    <ExitButton confirmation={ confirmation } />
                  </StyledBottomBorder>
                  <RequiredInfoText align='left' />
                  <StyledDetailedSection component="h4" align="left" variant="body1">
                    {t(translationKey.TitleYoungPersonDetails)}
                  </StyledDetailedSection>
                  <Grid container spacing={ 3 }>
                    <Grid size={{ xs: standardComponentXS }}>
                      <TextField
                        label={t(translationKey.LabelYoungFirstName)}
                        className={styles.textField}
                        margin="normal"
                        fullWidth
                        variant="outlined"
                        type="text"
                        name="child.first_name"
                        autoFocus
                        onBlur={ handleBlur }
                        value={ values.child.first_name }
                        helperText={ errors.childFirstName && touched.child && touched.child.first_name && errors.childFirstName }
                        slotProps={{ formHelperText: { style: { color: helperTextColor } } }}
                        onChange={ handleChange }
                        required={ true }
                      />
                    </Grid>
                    <Grid size={{ xs: standardComponentXS }}>
                      <TextField
                        label={t(translationKey.LabelYoungLastName)}
                        className={styles.textField}
                        margin="normal"
                        variant="outlined"
                        type="text"
                        name="child.last_name"
                        onBlur={ handleBlur }
                        value={ values.child.last_name }
                        helperText={ errors.childLastName && touched.child && touched.child.last_name && errors.childLastName }
                        slotProps={{ formHelperText: { style: { color: helperTextColor } } }}
                        onChange={ handleChange }
                        required={ true }
                      />
                    </Grid>

                    <Grid size={{ xs: standardComponentXS }}>
                      <TextField
                        className={styles.textField}
                        label={t(translationKey.LabelYoungPersonDOB)}
                        margin="normal"
                        variant="outlined"
                        type="date"
                        name="child.dob"
                        onBlur={ handleBlur }
                        value={ values.child.dob }
                        helperText={ errors.dob && touched.child && touched.child.dob && errors.dob }
                        slotProps={{ 
                          formHelperText: { style: { color: helperTextColor } }, 
                          htmlInput: {max: maxDateString},
                          inputLabel: {shrink: true}
                        }}
                        onChange={ handleChange }
                        required={ true }
                        sx={{ marginTop: 0}}
                      />
                    </Grid>
                    <Grid size={{ xs: standardComponentXS }}>
                      <FormControl className={styles.formControl} variant="outlined">
                        <InputLabel ref={ inputLabel } id="gender-select-outlined-label">
                          {t(translationKey.LabelGender)}
                        </InputLabel>
                        <Select
                          fullWidth
                          className={styles.textField}
                          labelId="gender-select-outlined-label"
                          id="gender-select-outlined"
                          name="child.gender"
                          value={ values.child.gender }
                          label={ t(translationKey.LabelGender) }
                          onChange={ handleChange }
                          onBlur={ handleBlur }
                        >
                          <MenuItem value="">
                            <em>{t(translationKey.ChoiceLabelNone)}</em>
                          </MenuItem>
                          <MenuItem value={ 'Male' }>{ t(translationKey.ChoiceLabelMale) }</MenuItem>
                          <MenuItem value={ 'Female' }>{ t(translationKey.ChoiceLabelFemale) }</MenuItem>
                          <MenuItem value={ 'n/a' }>{ t(translationKey.ChoiceLabelNA) }</MenuItem>
                        </Select>
                        <HelperText>{ errors.gender && touched.child && touched.child.gender && errors.gender }</HelperText>
                      </FormControl>
                    </Grid>
                    <Grid size={{ xs: standardComponentXS }}>
                      <FormControl className={styles.formControl}  variant="outlined">
                        <InputLabel ref={ inputLabel } id="ethnicity-select-outlined-label">
                          {t(translationKey.LabelEthnicity)}
                        </InputLabel>
                        <Select
                          className={styles.textField}
                          labelId="ethnicity-select-outlined-label"
                          id="ethnicity-select-outlined"
                          name="child.ethnicity"
                          value={ values.child.ethnicity }
                          label={ t(translationKey.LabelEthnicity) }
                          onChange={ handleChange }
                          onBlur={ handleBlur }
                        >
                          <MenuItem value="">
                            <em>{t(translationKey.ChoiceLabelNone)}</em>
                          </MenuItem>
                          <MenuItem value={ 'white' }>{t(translationKey.LabelEthnicityWhite)}</MenuItem>
                          <MenuItem value={ 'mixed/multiple ethnic groups' }>{t(translationKey.LabelEthnicityMultipleEthnicGroups)}</MenuItem>
                          <MenuItem value={ 'asian/asian british' }>{t(translationKey.LabelEthnicityAsianBritish)}</MenuItem>
                          <MenuItem value={ 'black/african/caribbean/black british' }>{t(translationKey.LabelEthnicityBlackEthnicities)}</MenuItem>
                          <MenuItem value={ 'chinese' }>{t(translationKey.LabelEthnicityChinese)}</MenuItem>
                          <MenuItem value={ 'arab' }>{t(translationKey.LabelEthnicityArab)}</MenuItem>
                          <MenuItem value={ 'other ethnic group' }>{t(translationKey.LabelEthnicityOtherEthnicGroup)}</MenuItem>
                        </Select>
                        <HelperText>{ errors.ethnicity && touched.child && touched.child.ethnicity && errors.ethnicity }</HelperText>
                      </FormControl>
                    </Grid>

                    <Grid size={{ xs: mobile ? 6 : 3 }}>
                      <FormControl className={styles.formControl} variant="outlined">
                        <InputLabel ref={ inputLabel } id="country-select-outlined-label">
                          {t(translationKey.LabelCountry)}
                        </InputLabel>
                        <Select
                          className={styles.textField}
                          labelId="country-select-outlined-label"
                          id="country-select-outlined"
                          name="child.country"
                          value={ values.child.country }
                          label={t(translationKey.LabelCountry)}
                          onChange={ handleChange }
                          onBlur={ handleBlur }
                        >
                          {
                            countryList.map((country, index) =>
                              <MenuItem key = {index} value={country}>{country}</MenuItem>
                              )}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid size={{ xs: mobile ? 6 : 3 }}>
                      <TextField
                        className={styles.textField}
                        slotProps={{ 
                          htmlInput: postcodeValidation,
                        }}
                        label={t(translationKey.LabelPostcode)}
                        required={ postcodeValidationRequired }
                        // margin="normal"
                        variant="outlined"
                        type="text"
                        name="child.post_code"
                        onBlur={ handleBlur }
                        value={ values.child.post_code }
                        onChange={ handleChange }
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={ 2 }  sx={{ marginTop: '2rem' }}>
                    <Grid className={styles.disabilitySpace} size={ {xs: standardComponentXS} }>
                      <FormControl className={styles.formControl} component="fieldset">
                        <FormLabel component="legend">{t(translationKey.LabelDisabilityEnquiry)}</FormLabel>
                        <RadioGroup
                          aria-label={t(translationKey.LabelGender)}
                          name="child.disability"
                          value={ values.child.disability }
                          onChange={ handleChange }>
                          <FormControlLabel value={ 'true' } control={ <Radio sx={{'&.Mui-checked': {color: "#f50057"}}} /> } label={t(translationKey.ChoiceLabelYes)} />
                          <FormControlLabel value={ 'false' } control={ <Radio sx={{'&.Mui-checked': {color: "#f50057"}}} /> } label={t(translationKey.ChoiceLabelNo)} />
                          <FormControlLabel value={ 'n/a' } control={ <Radio sx={{'&.Mui-checked': {color: "#f50057"}}} /> } label={t(translationKey.ChoiceLabelNA)} />
                        </RadioGroup>
                      </FormControl>
                    </Grid>

                    <Grid size={{ xs: standardComponentXS }}>
                        <TextField
                          className={styles.textField}
                          label={t(translationKey.LabelServiceUserReference)}
                          margin="normal"
                          fullWidth
                          variant="outlined"
                          type="text"
                          name="child.service_user_reference"
                          autoFocus
                          onBlur={ handleBlur }
                          value={ values.child.service_user_reference }
                          onChange={ handleChange }
                        />
                      </Grid>
                  </Grid>
                  <Grid  container spacing={ 2 } direction={'column'}>
                    <Grid size={{ xs: standardComponentXS }}>
                        <TextField
                          className={styles.textField}
                          label={t(translationKey.LabelMobileNumber)}
                          margin="normal"
                          variant="outlined"
                          type="tel"
                          name="child.telephone"
                          onBlur={ handleBlur }
                          value={ values.child.telephone }
                          helperText={ errors.number && touched.child && touched.child.telephone && errors.number }
                          slotProps={{ formHelperText: { style: { color: helperTextColor } } }}
                          onChange={ handleChange }
                          required={ true }
                        />
                    </Grid>

                  </Grid>
                  <StyledTopBorder container spacing={ 2 }>
                    <Grid size={{ xs: 2 }}>
                      <Button variant="contained" color="primary" type="submit" disabled={ isSubmitting }
                              style={ { float: 'left', textTransform: 'uppercase'} }>
                        {t(translationKey.ButtonSave)}
                      </Button>
                    </Grid>
                    <Grid size={{ xs: 10 }}>
                      <Error style={ { textAlign: 'left' } } message={ errors['network'] } />
                    </Grid>
                  </StyledTopBorder>
                </form>
              );
            } }
          </Formik>
        </CardContent>
      </StyledCard>
    </Container>
  );
}
