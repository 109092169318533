import React from 'react';
import { Container, Grid2 as Grid, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { getChain } from '../../../services/helpers';
import { useViewport, Width } from '../../../utilities/viewport';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../../utilities/localisation/translationKeys';
import { StyledSubDetailSection, StyledSubGrid, StyledSubGridCard, StyledSubtitleTypographyBase, StyledContent } from '../../../StyledComponents';

const StyledDetailedSection = styled(Typography)(({ theme }) => ({
  fontSize: '1.2rem',
  fontWeight: 'bold',
}))



export function OrganisationAndPointPersonDetails({ organisation }) {
  const { t } = useTranslation();

  const lnGameKeys = getChain(organisation, 'ln_game_keys');
  const lnExpirationDate = getChain(organisation, 'ln_expiration_date');
  const cotsGameKeys = getChain(organisation, 'cots_game_keys');
  const cotsGameExpirationDate = getChain(organisation, 'cots_expiration_date');

  const { width } = useViewport();
  const tabletView = width <= Width.SmallTablet;
  const mobileView = width <= Width.Phone;

  return (
    <Grid container spacing={ 2 }>
      <Grid size={ {xs: tabletView ? 12 : 8} }>
        <StyledSubGridCard>
          <Container style={ { margin: '1rem 0' } }>
            <StyledDetailedSection component="h1" align="left" variant="body1">
              {t(translationKey.TitleOrganisation)}
            </StyledDetailedSection>
            <Grid container style={ { margin: '1rem 0' } } spacing={ 1 }>

              <Grid size={ {xs: mobileView ? 12 : 6} }>
                <Item title={t(translationKey.LabelName)}>{ getChain(organisation, 'name') }</Item>
                <Item title={t(translationKey.LabelPostcode)}>{ getChain(organisation, 'postcode') }</Item>
                <Item title={t(translationKey.LabelBuyerName)}>{ getChain(organisation, 'buyer', 'name') }</Item>
                <Item title={t(translationKey.LabelPhone)}>{ getChain(organisation, 'phone') }</Item>
              </Grid>

              <Grid size={ {xs: mobileView ? 12 : 6} }>
                {
                  lnGameKeys > 0 &&
                  <BorderContainer>
                    <StyledSubDetailSection component="h3" align="left" variant="body1">
                      {t(translationKey.TitleLumiNova)}
                    </StyledSubDetailSection>
                    <>
                      <Item title={t(translationKey.LabelAssignedKeys)}>{ lnGameKeys }</Item>
                      <Item title={t(translationKey.LabelLicenseExpiryDate)}>{ lnExpirationDate }</Item>
                    </>
                  </BorderContainer>
                }
                {
                  cotsGameKeys > 0 &&
                  <BorderContainer>
                    <StyledSubDetailSection component="h3" align="left" variant="body1">
                      {t(translationKey.TitleCotsGame)}
                    </StyledSubDetailSection>
                    <>
                      <Item title={t(translationKey.LabelAssignedKeys)}>{ cotsGameKeys }</Item>
                      <Item title={t(translationKey.LabelLicenseExpiryDate)}>{ cotsGameExpirationDate }</Item>
                    </>
                  </BorderContainer>
                }
              </Grid>
            </Grid>

          </Container>
        </StyledSubGridCard>
      </Grid>
      <StyledSubGrid size={{ xs:tabletView ? 12 : 4 }}>
        <StyledSubGridCard style={ { height: '100%' } }>
          <Container style={ { margin: '1rem 0' } }>
            <StyledDetailedSection component="h1" align="left" variant="body1">
              {t(translationKey.TitlePointPerson)}
            </StyledDetailedSection>
            <Grid container style={ { margin: '1rem 0' } }>
              <Item title={t(translationKey.LabelName)}>{ getChain(organisation, 'point_person', 'name') }</Item>
              <Item title={t(translationKey.LabelEmail)}>{ getChain(organisation, 'point_person', 'email') }</Item>
              <Item title={t(translationKey.LabelBuyerName)}>{ getChain(organisation, 'point_person', 'phone') }</Item>
            </Grid>
          </Container>
        </StyledSubGridCard>
      </StyledSubGrid>
    </Grid>
  )
}

function Item({ title, children }) {

  return (
    <Grid size={{ xs: 12 }} style={ { marginTop: '0.5rem' } }>
      <StyledSubtitleTypographyBase><strong>{ title }</strong></StyledSubtitleTypographyBase>
      <StyledContent>{ children }</StyledContent>
    </Grid>
  )
}

export function BorderContainer({ children }) {

  return (
    <Grid container spacing={ 2 }>
      <Grid size={{ xs: 12 }} style={ { margin: '1rem 0' } }>
        { children }
      </Grid>
    </Grid>
  )
}
