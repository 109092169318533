import React from 'react';
import YoungPersonForm from './YoungPersonForm';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../../utilities/localisation/translationKeys';
import { useLocation } from 'react-router-dom';

const EditYoungPersonPage = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  return (
    <YoungPersonForm
      title={ t(translationKey.LabelEditYoungProfile) }
      path={ `/cots/young_persons/${ location.state.youngPerson.id }` }
      youngPerson={ location.state.youngPerson }
      { ...props }
    />
  );
};

export default EditYoungPersonPage;
