import React from 'react';
import OrganisationForm from './OrganisationForm';
import { translationKey } from '../../utilities/localisation/translationKeys';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const OrganisationEditPage = props => {
    const { t } = useTranslation();
    const location = useLocation();
    return (<OrganisationForm
        { ...props }
        path={ `/organisation/${ location.state.organisation.id }` }
        organisation={ location.state.organisation }
        title={ t(translationKey.TitleEditOrganisationName, { name: location.state.organisation.name }) }
      />
    );
};
export default OrganisationEditPage;
