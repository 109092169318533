import React from 'react';
import { Grid2 as Grid, IconButton, Card } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const Modal = ({ children, close }) => (
  <div style={ {
    position: 'absolute',
    width: '60%',
    height: '60%',
    left: '20%',
    right: '20%',
    zIndex: '100',
    bottom: '20%',
    top: '20%',
  } }>
    <Card>
      <Grid size={{ xs: 1 }} justifySelf={"end"}>
        <IconButton  aria-label="close" onClick={ close }>
          <CloseIcon/>
        </IconButton>
      </Grid>
      { children }
    </Card>
  </div>

);
