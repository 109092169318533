import React, { useEffect, useState } from 'react';
import { styled } from '@mui/styles';
import { Card, CardContent, Container, Grid2 as Grid, ListItem, ListItemText, Typography } from '@mui/material';
import { CloudDownload } from '@mui/icons-material';
import { axios } from '../../services/networkRequest';
import * as PropTypes from 'prop-types';
import { getChain } from '../../services/helpers';
import Footer from '../content/Footer';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';

const StyledCard = styled(Card)(({ theme }) => ({
  minWidth: 275,
  marginBottom: '16px'
}))
const StyledHeading = styled(Grid)(({ theme }) => ({
  marginBottom: '12px'
}))
const StyledListItem = styled(ListItem)(({ theme }) => ({
  width: '100%',
  cursor: 'pointer'
}))
const StyledListItemGrid = styled(Grid)(({ theme }) => ({
  width: '100%'
}))

function ResourceItem(props) {

  const handleClick = () => {
    console.log('Click', props.filename);
    axios.getFile(`/${props.prefix}/training/${ props.filename }`, props.filename);
  };

  return (
    <StyledCard>
      <CardContent>
        <Grid container direction='column' alignItems='flex-start' justify='center'>
          <StyledListItemGrid size={{ xs: 12 }}>
            <StyledListItem onClick={ handleClick }>
              <ListItemText
                primary={ props.filename }
                slotProps={{primary: {style: {fontWeight: 800} }}}
              />
              <CloudDownload />
            </StyledListItem>
          </StyledListItemGrid>
        </Grid>
      </CardContent>
    </StyledCard>
  );
}

ResourceItem.propTypes = {
  filename: PropTypes.string.isRequired,
  prefix: PropTypes.string.isRequired,
};

export default function Resources({ prefix }) {
  const [files, setFiles] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    axios.get(`/${prefix}/training`, { signal })
      .then(({ data }) => {
        setFiles(getChain(data, 'files'));
      })
      .catch((error) => {
        if (error.name === 'AbortError') {
          console.log('Fetch aborted', error);
        }
        else {
          console.log('Error fetching training files', error);
        }
      });
     
    return () => {
      controller.abort();
    }
    }, [prefix]);

  return (
    <Container component="main" maxWidth="md">
      <StyledCard>
        <CardContent>
          <Grid container direction='column' alignItems='flex-start' justify='center'>
            <StyledHeading size={ {xs: 12} }>
              <Typography variant="h4" align="left">
                {t(translationKey.TitleResources)}
              </Typography>
            </StyledHeading>
            <Grid size={{ xs: 12 }}>
              <Typography component="h1" align="left" variant="body1">
                {t(translationKey.LabelClickToDownloadResources)}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </StyledCard>

      { files && files.map((entry, index) => (
        <Grid container direction="column" key={ index }>
          <ResourceItem filename={ entry.file } prefix={prefix} />
        </Grid>
      )) }
      <Footer />
    </Container>
  );
}

Resources.propTypes = {
  prefix: PropTypes.string.isRequired,
}
