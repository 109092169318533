import React, { useEffect, useState } from 'react';
import { Card, Container, Grid2 as Grid, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { axios } from '../services/networkRequest';
import { getChain } from '../services/helpers';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SelfSignUpFooter from './SelfSignUp/Footer';
import SelfSignUpHeader from './SelfSignUp/Header';
import { CustomSupportInfo } from './Error';
import { useViewport } from '../utilities/viewport';
import { Trans, useTranslation } from 'react-i18next';
import { translationKey } from '../utilities/localisation/translationKeys';
import { StyledErrorContainerBase, StyledErrorContentBase, StyledTitleTypographyBase } from '../StyledComponents';

const StyledLink = styled('a')(({ theme }) => ({
  color: '#c9218e',
  fontWeight: 'bold'
}))

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: '1rem',
  marginTop: '2rem',
  padding: '2rem 1rem',
}))

const StyledGameLogo = styled('img')(({ theme }) => ({
  width: '10vw',
  cursor: 'pointer',
}))

const StyledContainer = styled(StyledErrorContainerBase)(({ theme }) => ({
  margin: '1rem 0'
}))

const StyledContent = styled(StyledErrorContentBase)(({ theme })=> ({
  textAlign: 'left'
}))

const StyledTypography = styled(StyledTitleTypographyBase)(({ theme })=> ({
  marginBottom: "1rem"
}))

export default function SuccessPage(props) {
  const navigate = useNavigate()
  const location = useLocation()

  const { keycode } = useParams();

  const { t } = useTranslation();

  const originalEntitySettings = location.state?.entitySettings || undefined;

  const [entitySettings, setEntitySettings] = useState(originalEntitySettings);
  const [error, setError] = useState(false);

  const guid = getChain(entitySettings, 'self_sign_up_settings', 'guid');
  const customSupportInfo = getChain(entitySettings, 'custom_support_info');
  const showCustomSupportInfo = getChain(customSupportInfo, 'enable');

  useEffect(() => {
    if (!keycode) {
      setError(true);
    } else {

      if (!entitySettings) {
        axios.get(`/entity/${ keycode }`)
          .then(({ data }) => {
            setEntitySettings(data);
          })
          .catch(() => setError(true));
      }
    }

  }, [entitySettings, keycode, navigate]);

  const { views: { mobile } } = useViewport();

  return (
    <>
      <SelfSignUpHeader guid={ guid } />
      <Container component="main">
        <StyledCard>
          <Container maxWidth="md">
            <Grid container justify="center">
              <StyledGameLogo src="/ln_logo_transparent_background.png" alt="Lumi Nova logo" />
            </Grid>
            <br />
            {
              error ?
                <Grid container>
                  <Grid size={{ xs: 12 }}>
                    <StyledTypography component="h1" variant="h5">
                      {t(translationKey.ErrorApologyForProblem)}
                    </StyledTypography>
                  </Grid>
                </Grid> :
                <Grid container>
                  <Grid size={{ xs: 12 }}>
                    <StyledTypography component="h3" variant="h6">
                      {t(translationKey.TextAllDone)}
                    </StyledTypography>
                  </Grid>

                  <StyledContainer container justify="flex-start">
                    <StyledTypography component="h3" variant="h6">
                      {t(translationKey.LabelNextSteps)}
                    </StyledTypography>
                    <StyledContent component="p" variant="h6">
                      {t(translationKey.TextCheckForLumiNovaDownloadLink)}
                    </StyledContent>
                  </StyledContainer>

                  <StyledContainer container justify="flex-start">
                    <StyledTypography component="h3" variant="h6">
                      {t(translationKey.LabelSupport)}
                    </StyledTypography>
                    {
                      customSupportInfo && showCustomSupportInfo &&
                      <>
                        <StyledContent component="p" variant="h6">
                          {t(translationKey.TextLumiNovaHasBeenProvidedBy, { entity: getChain(customSupportInfo, 'entity_name') })}
                        </StyledContent>
                        <br />
                        <Grid size={{xs: mobile ? 12 : 6 }}>
                          <CustomSupportInfo customSupportInfo={ customSupportInfo } />
                        </Grid>
                      </>
                    }
                  </StyledContainer>
                </Grid>
            }
            <br />
            <Typography style={ { textAlign: 'left' } }>
              <Trans i18nKey={translationKey.LabelPleaseVisitOfficialLumiNova} components={[<StyledLink href="https://luminova.app/" target="_blank" rel="noopener noreferrer"> </StyledLink>]}/>
            </Typography>
          </Container>
        </StyledCard>
      </Container>
      <SelfSignUpFooter />
    </>
  );
}

export function IconLabel({ icon, children }) {

  const { views: { tablet } } = useViewport();

  return (
    <Grid container>
      <Grid size={ {xs: tablet ? 2 : 1} } style={ { textAlign: 'left' } }>
        { icon }
      </Grid>
      <Grid size={ {xs: tablet ? 10 : 11} }>
        <Typography component="p" varinat="p" style={ { textAlign: 'left', whiteSpace: 'nowrap' } }>
          { children }
        </Typography>
      </Grid>
    </Grid>
  )
}
