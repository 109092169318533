import React from 'react';
import './App.css';
import Content from './components/content/Content';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './Styles';
import useActivityContextState from './store/ActivityContextState';
import ActivityContext from './store/ActivityContext';
import { Provider } from 'react-redux';
import store from './store/store';

function App() {

  const activityState = useActivityContextState();

  return (
    <Router>
      <ThemeProvider theme={ theme }>
        <Provider store={store}>
          <ActivityContext.Provider value={ activityState }>
            <div className="App wrapper">
              <Content />
            </div>
          </ActivityContext.Provider>
        </Provider>
      </ThemeProvider>
    </Router>
  );
}

export default App;
