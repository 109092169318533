import React, { useState } from 'react';
import { axios, catchAxios } from '../../services/networkRequest';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import { Formik } from 'formik';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { formatDate, genericAlert, getChain } from '../../services/helpers';
import * as PropTypes from 'prop-types';
import Error from '../Error';
import RequiredInfoText from '../RequiredInfoText';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import update from 'immutability-helper';
import { gameKeyMax, helperTextColor } from '../../constants';
import Card from '@mui/material/Card';
import ExitButton from '../ExitButton';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';
import { useNavigate } from 'react-router-dom';

const phoneUtil = PhoneNumberUtil.getInstance();

function OrganisationForm({ organisation, title, path }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const method = organisation ? 'post' : 'put';
  const confirmation = callback => {
    genericAlert(t(translationKey.AlertTitleCancelOrganisationForm), t(translationKey.AlertBodyCancelOrganisationForm), callback);
  };
  const postcodePattern = '([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\\s?[0-9][A-Za-z]{2})';

  const [checkingPostcode, setCheckingPostcode] = useState(false);
  const [organisationWarning, setOrganisationWarning] = useState(false);
  const [currentPostcode, setCurrentPostcode] = useState();

  return (
    <Card>
      <ExitButton style={ { float: 'right', padding: '20px' } } confirmation={ confirmation } />
      <Container component="main" maxWidth="xs" style={ { padding: '20px' } }>
        <CssBaseline />

        <Typography component="h1" variant="h5">
          { title }
        </Typography>
        <RequiredInfoText />
        <Formik
          initialValues={ {
            name: getChain(organisation, 'name'),
            postcode: getChain(organisation, 'postcode'),
            address: getChain(organisation, 'address'),
            phone: getChain(organisation, 'phone'),
            ln_game_keys: getChain(organisation, 'ln_game_keys') || 0,
            expiration_date: formatDate(getChain(organisation, 'ln_expiration_date')),
            cots_game_keys: getChain(organisation, 'cots_game_keys') || 0,
            cots_expiration_date: formatDate(getChain(organisation, 'cots_expiration_date')),
            buyer: {
              name: getChain(organisation, 'buyer', 'name'),
              email: getChain(organisation, 'buyer', 'email'),
            },
            point_person: {
              name: getChain(organisation, 'point_person', 'name'),
              phone: getChain(organisation, 'point_person', 'phone'),
              email: getChain(organisation, 'point_person', 'email'),
            },
          } }
          validate={ values => {
            const errors = {};
            if (!values.name) {
              errors.name = t(translationKey.ErrorSpecifyOrganisationName);
            }

            if (values.ln_game_keys == null || values.cots_game_keys == null) {
              errors.ln_game_keys = t(translationKey.ErrorSpecifyOrganisationGameKeys);
              errors.cots_game_keys = t(translationKey.ErrorSpecifyOrganisationGameKeys);
            } else if (values.ln_game_keys < 0 || values.cots_game_keys < 0) {
              errors.ln_game_keys = values.ln_game_keys < 0 ? t(translationKey.ErrorGameKeysSpecifiedMustBePositive) : null;
              errors.cots_game_keys = values.cots_game_keys < 0 ? t(translationKey.ErrorGameKeysSpecifiedMustBePositive) : null;
            } else if (values.ln_game_keys > gameKeyMax || values.cots_game_keys > gameKeyMax) {
              values.ln_game_keys = values.ln_game_keys > gameKeyMax ? gameKeyMax : values.ln_game_keys;
              values.cots_game_keys = values.cots_game_keys > gameKeyMax ? gameKeyMax : values.cots_game_keys;
            }


            if (!values.postcode) {
              errors.postcode = t(translationKey.ErrorSpecifyOrganisationPostcode);
            } else {
              values.postcode = values.postcode.toUpperCase();
              if (!organisation && values.postcode !== currentPostcode && values.postcode.match(postcodePattern)) {
                setCheckingPostcode(true);
                axios.get(`/organisation/lookup/${ values.postcode }`)
                  .then(({ data }) => {
                    setOrganisationWarning(!!getChain(data, 'duplicate'));
                  })
                  .finally(() => {
                    setCurrentPostcode(values.postcode);
                    setCheckingPostcode(false);
                  });
              }
            }
            if (!values.address) {
              errors.address = t(translationKey.ErrorSpecifyOrganisationAddress);
            }
            if (!values.phone) {
              errors.phone = t(translationKey.ErrorSpecifyPhoneNumber);
            } else {
              try {
                const number = phoneUtil.parseAndKeepRawInput(values.phone, 'GB');
                if (!(phoneUtil.isPossibleNumber(number) && phoneUtil.isValidNumber(number))) {
                  errors.phone = t(translationKey.ErrorValidPhoneNumber);
                }
              } catch (err) {
                errors.phone = t(translationKey.ErrorValidPhoneNumber);
              }
            }
            if (!values.buyer.name) {
              errors.buyer = {};
              errors.buyer.name = t(translationKey.ErrorSpecifyBuyerName);
            }
            if (!values.buyer.email) {
              errors.buyer = errors.buyer || {};
              errors.buyer.email = t(translationKey.ErrorRequired);
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.buyer.email)
            ) {
              errors.buyer = errors.buyer || {};
              errors.buyer.email = t(translationKey.ErrorInvalidEmail);
            }
            if (!values.point_person.name) {
              errors.point_person = {};
              errors.point_person.name = t(translationKey.ErrorSpecifyPointPersonNameOrg);
            }
            if (!values.point_person.phone) {
              errors.point_person = errors.point_person || {};
              errors.point_person.phone = t(translationKey.ErrorSpecifyNumber);
            } else {
              try {
                const number = phoneUtil.parseAndKeepRawInput(values.point_person.phone, 'GB');
                if (!(phoneUtil.isPossibleNumber(number) && phoneUtil.isValidNumber(number))) {
                  errors.point_person = errors.point_person || {};
                  errors.point_person.phone = t(translationKey.ErrorValidPhoneNumber);
                }
              } catch (err) {
                errors.point_person = errors.point_person || {};
                errors.point_person.phone = t(translationKey.ErrorValidPhoneNumber);
              }
            }
            if (!values.point_person.email) {
              errors.point_person = errors.point_person || {};
              errors.point_person.email = t(translationKey.ErrorRequired);
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.point_person.email)
            ) {
              errors.point_person = errors.point_person || {};
              errors.point_person.email = t(translationKey.ErrorInvalidEmail);
            }
            //console.log(errors);
            return errors;
          } }
          onSubmit={ (values, { setSubmitting, setFieldError }) => {
            setSubmitting(true);
            // Convert phone number into international format
            const number = phoneUtil.parseAndKeepRawInput(values.phone, 'GB');
            const internationalNumber = phoneUtil.format(number, PhoneNumberFormat.E164);
            const number2 = phoneUtil.parseAndKeepRawInput(values.point_person.phone, 'GB');
            const internationalNumber2 = phoneUtil.format(number2, PhoneNumberFormat.E164);
            const updatedValues = update(values, {
              phone: { $set: internationalNumber },
              point_person: { phone: { $set: internationalNumber2 } },
            });
            const submitFunction = () => {
              axios[method](path, updatedValues)
                .then(() => {
                  navigate('/', {replace: true});
                })
                .catch(err => {
                  catchAxios(setFieldError)(err);
                  setSubmitting(false);
                });
            };

            if (organisationWarning) {
              genericAlert(
                {
                  title: t(translationKey.AlertTitleDuplicateOrganisation),
                  message: t(translationKey.AlertBodyDuplicateOrganisation),
                  positiveCallback: submitFunction,
                  positiveTitle: t(translationKey.TitleYesSubmit),
                  negativeTitle: t(translationKey.TitleGoBack),
                });
            } else {
              submitFunction();
            }
          } }
        >
          { ({
               errors,
               values,
               touched,
               handleChange,
               handleBlur,
               handleSubmit,
             }) => (
            <form onSubmit={ handleSubmit }>
              <Error message={ errors['network'] } />
              <TextField
                type="text"
                variant="outlined"
                margin="normal"
                fullWidth
                autoFocus
                label={t(translationKey.LabelOrganisationName)}
                value={ values.name }
                required
                name='name'
                onChange={ handleChange }
                onBlur={ handleBlur }
                helperText={ errors.name && touched.name && errors.name }
                slotProps={{ formHelperText: { style: { color: helperTextColor } } }}
              />

              <TextField
                type="number"
                variant="outlined"
                margin="normal"
                fullWidth
                label={t(translationKey.LabelLumiNovaLicenses)}
                value={ values.ln_game_keys }
                required
                name='ln_game_keys'
                slotProps={{ 
                  htmlInput: {min: 0, max: gameKeyMax},
                  formHelperText: { style: { color: helperTextColor } }
                }}
                onChange={ handleChange }
                onBlur={ handleBlur }
                helperText={ errors.ln_game_keys && touched.ln_game_keys && errors.ln_game_keys }
              />

              <TextField
                fullWidth
                label={t(translationKey.LabelLumiNovaLicensesValidUntil)}
                margin="normal"
                variant="outlined"
                type="date"
                name="expiration_date"
                onBlur={ handleBlur }
                value={ values.expiration_date }
                helperText={ errors.dob && touched.child && touched.child.dob && errors.dob }
                slotProps={{ 
                  htmlInput: { min: formatDate(new Date()) },
                  inputLabel: { shrink: true },
                  formHelperText: { style: { color: helperTextColor }} 
                }}
                onChange={ handleChange }
                required={ true }
              />


              <TextField
                type="number"
                variant="outlined"
                margin="normal"
                fullWidth
                label={t(translationKey.LabelCotsProLicenses)}
                value={ values.cots_game_keys }
                required
                name='cots_game_keys'
                onChange={ handleChange }
                onBlur={ handleBlur }
                helperText={ errors.cots_game_keys && touched.cots_game_keys && errors.cots_game_keys }
                slotProps={{ 
                  htmlInput: {min: 0, max: gameKeyMax},
                  formHelperText: { style: { color: helperTextColor } }
                }}
              />

              <TextField
                fullWidth
                label={t(translationKey.LabelCotsProLicensesValidUntil)}
                margin="normal"
                variant="outlined"
                type="date"
                name="cots_expiration_date"
                onBlur={ handleBlur }
                value={ values.cots_expiration_date }
                helperText={ errors.dob && touched.child && touched.child.dob && errors.dob }
                slotProps={{ 
                  htmlInput: { min: formatDate(new Date()) },
                  inputLabel: { shrink: true },
                  formHelperText: { style: { color: helperTextColor } } }}
                onChange={ handleChange }
                required={ true }
              />


              <TextField
                type="text"
                variant="outlined"
                margin="normal"
                fullWidth
                value={ values.address }
                label={t(translationKey.LabelOrganisationAddress)}
                required
                name='address'
                onChange={ handleChange }
                onBlur={ handleBlur }
                helperText={ errors.address && touched.address && errors.address }
                slotProps={{ formHelperText: { style: { color: helperTextColor } } }}
              />

              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name='postcode'
                type="text"
                value={ values.postcode }
                label={t(translationKey.LabelOrganisationPostcode)}
                required
                onChange={ handleChange }
                onBlur={ handleBlur }
                helperText={ errors.postcode && touched.postcode && errors.postcode }
                slotProps={{ 
                  htmlInput: {title: 'Valid Postcode', pattern: postcodePattern},
                  formHelperText: { style: { color: helperTextColor } }
                }}
              />

              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                type="tel"
                value={ values.phone }
                label={t(translationKey.LabelOrganisationPhoneNumber)}
                required
                name='phone'
                onChange={ handleChange }
                onBlur={ handleBlur }
                helperText={ errors.phone }
                slotProps={{ formHelperText: { style: { color: helperTextColor } } }}
              />

              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                type="text"
                label={t(translationKey.LabelBuyerName)}
                value={ values.buyer.name }
                required
                name='buyer.name'
                onChange={ handleChange }
                onBlur={ handleBlur }
                helperText={ errors.buyer && errors.buyer.name && touched.buyer && touched.buyer.name && errors.buyer.name }
                slotProps={{ formHelperText: { style: { color: helperTextColor } } }}
              />

              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                type="email"
                label={t(translationKey.LabelBuyerEmail)}
                value={ values.buyer.email }
                required
                name='buyer.email'
                onChange={ handleChange }
                onBlur={ handleBlur }
                helperText={ errors.buyer && errors.buyer.email && touched.buyer && touched.buyer.email && errors.buyer.email }
                slotProps={{ formHelperText: { style: { color: helperTextColor } } }}
              />

              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                type="text"
                label={t(translationKey.LabelPointPersonName)}
                value={ values.point_person.name }
                required
                name='point_person.name'
                onChange={ handleChange }
                onBlur={ handleBlur }
                helperText={ errors.point_person && errors.point_person.name && touched.point_person && touched.point_person.name && errors.point_person.name }
                slotProps={{ formHelperText: { style: { color: helperTextColor } } }}
              />
              <br />

              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                type="tel"
                label={t(translationKey.LabelPointPersonPhone)}
                value={ values.point_person.phone }
                required
                name='point_person.phone'
                onChange={ handleChange }
                onBlur={ handleBlur }
                helperText={ errors.point_person && errors.point_person.phone && touched.point_person && touched.point_person.phone && errors.point_person.phone }
                slotProps={{ formHelperText: { style: { color: helperTextColor } } }}
              />

              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                type="email"
                label={t(translationKey.LabelPointPersonEmail)}
                value={ values.point_person.email }
                name='point_person.email'
                onChange={ handleChange }
                onBlur={ handleBlur }
                required
                helperText={ errors.point_person && errors.point_person.email && touched.point_person && touched.point_person.email && errors.point_person.email }
                slotProps={{ formHelperText: { style: { color: helperTextColor } } }}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={ checkingPostcode }
                color="primary">{t(translationKey.ButtonSubmit)}</Button>
            </form>) }
        </Formik>
      </Container>
    </Card>
  );
}

OrganisationForm.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  organisation: PropTypes.object,
};

export default OrganisationForm;
