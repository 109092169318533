import React from 'react';
import YoungPersonForm from './YoungPersonForm';
import { axios } from '../../../services/networkRequest';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../../utilities/localisation/translationKeys';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const EditYoungPersonPage = ({ ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const user = useSelector((state) => state.user.user);

  const { t } = useTranslation();
  const [entitySettings, setEntitySettings] = React.useState(undefined);
  React.useEffect(() => {
    axios.get(`/entity/${user.entity_id}/settings`).then(({data}) => {
      data && data.self_sign_up_settings.distributor_id === user.id && setEntitySettings(data);
    })
  }, [user])

  const onSubmitForm = async (values) => {
    return await axios.put(`/ln/young_persons/${ location.state.youngPerson.id }`, values)
      .then(() => {
        navigate('/ln/young_persons', { replace: true });
      });
  }

  return (
    <YoungPersonForm
      title={t(translationKey.LabelEditYoungProfile)}
      path={ `/ln/young_persons/${ location.state.youngPerson.id }` }
      youngPerson={ location.state.youngPerson }
      entitySettings={entitySettings}
      onSubmitForm={ onSubmitForm }
      { ...props }
    />
  );
};

export default EditYoungPersonPage;
