import React, { useEffect, useState, useCallback } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/styles';
import { tableThemer } from '../../../themes';
import { Card, Container, CardContent, CardMedia, Button, Typography, Grid2 as Grid, FormControl, FormControlLabel, RadioGroup, FormLabel, Radio, IconButton, Stepper, Step, StepButton, StepLabel, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TableFooter  } from '@mui/material';
import { ChevronRight, Warning, Edit, Visibility, VisibilityOff, ArrowDownwardSharp, VideogameAssetOutlined as GameAsset, ArrowBackIosNew, ArrowForwardIos, FirstPage, LastPage, TimerOutlined } from '@mui/icons-material';
import { axios, CancelToken } from '../../../services/networkRequest';
import GameProgress, { ChallengeConnector, ChallengeIcon } from './YoungPersonComponents/GameProgress';
import ContactDetails from './YoungPersonComponents/ContactDetails';
import style, {
  activeColor,
  greyBorderColor,
  listBackgroundColor,
  notActiveColor,
  numberTextColor,
} from '../../../Styles';
import { genericAlert, getChain, identity, GetAllGoalsCompletedValues } from '../../../services/helpers';
import * as PropTypes from 'prop-types';
import Footer from '../../content/Footer';
import UsageGraph from '../UsageGraph';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../../utilities/localisation/translationKeys';
import { TypographyAverageTime, TypographyHoursAndMinutes } from '../../Typography/TypographyNumberSpan';
import dayjs from 'dayjs';
import fileDownload from 'js-file-download';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useSelector } from 'react-redux';

const GET_YOUNG_PERSONS_URL = '/ln/young_persons';
const INITIAL_PAGINATOR_STATE = {currentPage: 0, currentPageSize: 25, perPage: 25, pages: 1, totalCount: 0};
const dataViews = {
  active: "active",
  inactive: "inactive",
  notYetActive: "not_yet_active"
}

const HIDDEN_GAME_KEY_STRING = "****-****-****";

const StyledCard = styled(Card)({
  minWidth: 275,
  marginBottom: '4px',
});

const StyledLeftTypography = styled(Typography)({
  display: 'inline-block',
  textAlign: 'left',
  float: 'left',
  marginTop: '8px',
});

const StyledButton = styled(Button)({
  display: 'inline-block',
  textAlign: 'right',
  float: 'right',
  // marginBottom: '16px',
  '&:hover': {
    backgroundColor: '#0079C6',
  },
  '&:active': {
    backgroundColor: 'white',
    borderColor: 'white',
    color: '#0079C6',
  },
});

const StyledBlock = styled('div')({
  display: 'block',
  width: '100%'
});

const StyledFlex = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: "center",
  width: '100%'


});

const StyledText = styled(Grid)({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  paddingTop: '50px',
});

const StyledButtonSpace = styled(Grid)({
  paddingTop: '40px',
});

const StyledDeactivateButton = styled(Button)({
  marginLeft: 'auto',
  color: '#3B3B3B',
  textDecoration: 'underline',
});

const StyledDeactivateGrid = styled(Grid)({
  marginLeft: 'auto',
  color: '#3B3B3B',
  textDecoration: 'underline',
});

const StyledTabs = styled(Card)({
  minWidth: 275,
  marginBottom: '4px',
  backgroundColor: '#FAFAFA',
  borderBottom: '2px solid #E6E6E6'
});

const StyledTab = styled(Typography)({
  display: 'inline-block',
  textAlign: 'left',
  float: 'left',
  marginTop: '8px',
  cursor: 'pointer',
  padding: '1em',
});

const StyledActiveTab = styled(Typography)({
  display: 'inline-block',
  textAlign: 'left',
  float: 'left',
  marginTop: '8px',
  fontWeight: 'bold',
  cursor: 'pointer',
  backgroundColor: '#E6E6E6',
  padding: '1em',
  borderBottom: '2px solid #113982'
});

const order = new Proxy({
  'complete': 1,
  'started': 2,
  'incomplete': 3,
}, {
  get: (target, key) => target[key] || 4,
});

export const setter = (list, updater) => (rowData) => {
  const index = list.findIndex(yp => yp.id === rowData.id);
  if (index > -1) {
    // shallow copy is need for react to know we changed the state or a re-render doesn't trigger
    const newList = [...list];
    newList[index] = rowData;
    updater(newList);
  }
};
function downloadCSV(yp_status) {
  axios.get(`/ln/young_persons/download/${yp_status}`, {
    headers: {
      'Accept': 'text/csv',
      responseType: 'arraybuffer',
    },
  })
    .then(res => {
      let now = new Date()

      fileDownload(res.data, `${ now.getDate() }_${ now.getMonth() + 1 }_${ now.getFullYear() }_luminova_yp_${ yp_status }.csv`, res.headers['content-type']);
    })
}

export default function GamersPage(props) {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.user);
  const [youngPersons, setYoungPersons] = useState([]);
  const [notStartedYoungPersons, setNotStartedYoungPersons] = useState([]);
  const [optedOutYoungPersons, setOptedOutYoungPersons] = useState([]);
  const [gameKeys, setGameKeys] = useState({ alert: false, totalKeys: 0, usedKeys: 0 });
  const [allPeople, setAllPeople] = useState(undefined);
  const [dataState, setDataState] = useState(false);
  const [isSelfSignUpDist, setIsSelfSignUpDist] = useState(false);
  const [youngPersonsExpandedRow, setYoungPersonsExpandedRow] = useState(null);
  const [notStartedExpandedRow, setNotStartedExpandedRow] = useState(null);
  const [optedOutExpandedRow, setOptedOutExpandedRow] = useState(null);
  const [activePaginator, updateActivePaginator] = useState({
    ...INITIAL_PAGINATOR_STATE
  })
  const [notYetActivePaginator, updateNotYetActivePaginator] = useState({
    ...INITIAL_PAGINATOR_STATE
  })
  const [inactivePaginator, updateInactivePaginator] = useState({
    ...INITIAL_PAGINATOR_STATE
  })
  const [showGameKeys, toggleShowGameKeys] = useState(true);

  const toggleColumnVisibility = () => toggleShowGameKeys(!showGameKeys)

  const renderGameKeyVisibilityFunctionality = () => <div style={{display: "flex", alignItems: "center", justifyContent: "space-around"}}>
    {t(translationKey.TitleGameKey)}
    <IconButton onClick={toggleColumnVisibility} size='small'>
      {showGameKeys ? (<Visibility fontSize='small'/>) : (<VisibilityOff fontSize='small'/>)}
    </IconButton>
  </div>

  const toggleRowExpansion = (rowId, setExpandedRow) => {
    setExpandedRow((prevRowId) => (prevRowId === rowId ? null : rowId));
  };
  
  const youngPersonsColumns = [
    {
      id: t(translationKey.TitleYoungPerson),
      header: t(translationKey.TitleYoungPerson),
      accessorFn: rowData => `${ rowData.first_name } ${ rowData.last_name }`,
      cell: rowData => <Typography color="textPrimary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.TitleStartedGame),
      accessorFn: rowData =>  rowData.first_play && new Date(rowData.first_play).toDateString(),
      cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.TitleLastSeen),
      accessorFn: rowData =>  rowData.last_seen && new Date(rowData.last_seen).toDateString(),
      cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.TitleChallenges),
      accessorKey: 'counts.challenges',
      cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.TitleGoals),
      accessorFn: rowData => GetAllGoalsCompletedValues(rowData),
      cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.TitleMissions),
      accessorKey: 'counts.long_term_goals_completed',
      cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      id: t(translationKey.TitleGameKey),
      header:  renderGameKeyVisibilityFunctionality, // t(translationKey.TitleGameKey),
      accessorKey: 'game_key',
      cell: ({row}) => {
      return (<div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
        <Typography color="textSecondary" align={"left"}>
          {`${ showGameKeys ? row.original.game_key : HIDDEN_GAME_KEY_STRING }`}
        </Typography>
        <IconButton disableRipple>
          {youngPersonsExpandedRow === row.id ? <ChevronRight style={{ transform: 'rotate(90deg)' }} /> : <ChevronRight />}
        </IconButton>
      </div>)
      }
    }
  ];
  
  const notStartedYoungPersonsColumns = [
    {
      id: t(translationKey.TitleYoungPerson),
      header: t(translationKey.TitleYoungPerson),
      accessorFn: rowData => `${ rowData.first_name } ${ rowData.last_name }`,
      cell: rowData => <Typography color="textPrimary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.TitleInvited),
      accessorFn: rowData =>  rowData.created_at && new Date(rowData.created_at).toDateString(),
      cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.TitleStartedGame),
      accessorFn: rowData =>  rowData.first_play && new Date(rowData.first_play).toDateString(),
      cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      id: t(translationKey.TitleGameKey),
      header:  renderGameKeyVisibilityFunctionality, // t(translationKey.TitleGameKey),
      accessorKey: 'game_key',
      cell: ({row}) => {
      return (<div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
        <Typography color="textSecondary" align={"left"}>
          {`${ showGameKeys ? row.original.game_key : HIDDEN_GAME_KEY_STRING }`}
        </Typography>
        <IconButton disableRipple>
          {youngPersonsExpandedRow === row.id ? <ChevronRight style={{ transform: 'rotate(90deg)' }} /> : <ChevronRight />}
        </IconButton>
      </div>)
      }
    }
  ];

  const optedOutYoungPersonsColumns = [
    {
      id: t(translationKey.TitleYoungPerson),
      header: t(translationKey.TitleYoungPerson),
      accessorFn: rowData => `${ rowData.first_name } ${ rowData.last_name }`,
      cell: rowData => <Typography color="textPrimary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.TitleInvited),
      accessorFn: rowData =>  rowData.created_at && new Date(rowData.created_at).toDateString(),
      cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.TitleStartedGame),
      accessorFn: rowData =>  rowData.first_play && new Date(rowData.first_play).toDateString(),
      cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      id: t(translationKey.TitleGameKey),
      header:  renderGameKeyVisibilityFunctionality, // t(translationKey.TitleGameKey),
      accessorKey: 'game_key',
      cell: ({row}) => {
      return (<div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
        <Typography color="textSecondary" align={"left"}>
          {`${ showGameKeys ? row.original.game_key : HIDDEN_GAME_KEY_STRING }`}
        </Typography>
        <IconButton disableRipple>
          {youngPersonsExpandedRow === row.id ? <ChevronRight style={{ transform: 'rotate(90deg)' }} /> : <ChevronRight />}
        </IconButton>
      </div>)
      }
    }
  ];

  const youngPersonsTable = useReactTable({
    data: youngPersons ?? [],
    columns: youngPersonsColumns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount: activePaginator.pages,
  });

  const notStartedYoungPersonsTable = useReactTable({
    data: notStartedYoungPersons ?? [],
    columns: notStartedYoungPersonsColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  const optedOutYoungPersonsTable = useReactTable({
    data: optedOutYoungPersons ?? [],
    columns: optedOutYoungPersonsColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  const fetchYoungPersons = useCallback(async (page = 0, perPage = 25, dataView, updatePaginator, updateYoungPersons) => {
    try {
      const response = await axios.get(GET_YOUNG_PERSONS_URL, {
        params: { per_page: perPage, page, view: dataView },
      });
      const {alert, total_keys, used_keys} = response.data
      const { data, current_page, per_page, pages, total_count } = response.data[dataView];
      if(allPeople === 0)
        setAllPeople(data.length)
      setGameKeys({ alert: alert, totalKeys: total_keys, usedKeys: used_keys });
      updateYoungPersons(data);
      updatePaginator({
        currentPage: current_page,
        currentPageSize: data.length,
        perPage: per_page,
        pages: pages,
        totalCount: total_count,
      });
    } 
    catch (error) {
      console.error("Error fetching data", error);
    }
  }, [allPeople]);

  useEffect(() => {
    fetchYoungPersons(activePaginator.currentPage, activePaginator.perPage, dataViews.active, updateActivePaginator, setYoungPersons);
  }, [activePaginator.currentPage, activePaginator.perPage, fetchYoungPersons]);

  useEffect(() => {
    fetchYoungPersons(inactivePaginator.currentPage, inactivePaginator.perPage, dataViews.inactive, updateInactivePaginator, setOptedOutYoungPersons);
  }, [inactivePaginator.currentPage, inactivePaginator.perPage, fetchYoungPersons]);

  useEffect(() => {
    fetchYoungPersons(notYetActivePaginator.currentPage, notYetActivePaginator.perPage, dataViews.notYetActive, updateNotYetActivePaginator, setNotStartedYoungPersons);
  }, [notYetActivePaginator.currentPage, notYetActivePaginator.perPage, fetchYoungPersons]);


  useEffect(() => {
    axios.get(`/entity/${user.entity_id}/settings`).then(({data}) => {
      data && data.self_sign_up_settings.distributor_id === user.id && setIsSelfSignUpDist(true);
    })
  }, [user])

  const tabs = {
    Active: "active",
    NotStarted: "not started",
    Deactivated: "deactivated"
  }

  const location = useLocation();
  const navigate = useNavigate();

  const currentTab = (location.state && location.state.newYoungPerson) ? tabs.NotStarted : tabs.Active;
  const [activeTab, setActiveTab] = useState(currentTab);

  const reloadData = () => {
    setDataState(!dataState);
    setAllPeople(undefined);
    setYoungPersons([]);
    setNotStartedYoungPersons([]);
    setOptedOutYoungPersons([]);
    toggleRowExpansion(null, setYoungPersonsExpandedRow);
    toggleRowExpansion(null, setNotStartedExpandedRow);
    toggleRowExpansion(null, setOptedOutExpandedRow);
    // Checks the active tab and reloads the day by switching tabs. The data is also refreshed for cases of deactivation and deletion
    if(activeTab === tabs.Active)
    {
      setActiveTab(tabs.Deactivated)
      setActiveTab(tabs.Active)

    }
    else if(activeTab === tabs.Deactivated)
    {
      setActiveTab(tabs.Active)
      setActiveTab(tabs.Deactivated)
    }
    else if(activeTab === tabs.NotStarted)
    {
      setActiveTab(tabs.Deactivated)
      setActiveTab(tabs.NotStarted)
    }
  };

  useEffect(() => {
    let cancel;
    const cancelToken = new CancelToken(c => {
      cancel = c;
    });


    const populateAllTables = async () => {
      const per_page = 25;
      const page = 0

      try {
        // Collective API calls. 
        const [data_active, data_inactive, data_not_yet_active] = await Promise.all([
          axios.get(GET_YOUNG_PERSONS_URL, { cancelToken, params: { per_page, page, view: dataViews.active }}).catch(identity),
          axios.get(GET_YOUNG_PERSONS_URL, { cancelToken, params: { per_page, page, view: dataViews.inactive }}).catch(identity),
          axios.get(GET_YOUNG_PERSONS_URL, { cancelToken, params: { per_page, page, view: dataViews.notYetActive }}).catch(identity)
        ])

        const { alert, total_keys, used_keys, active } = data_active.data
        const { inactive } = data_inactive.data
        const { not_yet_active } = data_not_yet_active.data

        // State Updates
        setGameKeys({ alert: alert, totalKeys: total_keys, usedKeys: used_keys });
        setAllPeople(active.data.length + inactive.data.length + not_yet_active.data.length)
        // const sorted = active.data.sort(function(a, b) {
        //   return new Date(b.last_seen) - new Date(a.last_seen)
        // });
        
        setYoungPersons(active.data);
        setNotStartedYoungPersons(not_yet_active.data);
        setOptedOutYoungPersons(inactive.data);

        // Paginator States
        updateActivePaginator({
          currentPage: active.current_page, 
          currentPageSize: active.data.length,
          perPage: active.per_page, 
          pages: active.pages, 
          totalCount: active.total_count
        })
        updateInactivePaginator({
          currentPage: inactive.current_page, 
          currentPageSize: inactive.data.length,
          perPage: inactive.per_page, 
          pages: inactive.pages, 
          totalCount: inactive.total_count
        })
        updateNotYetActivePaginator({
          currentPage: not_yet_active.current_page,
          currentPageSize: not_yet_active.data.length,
          perPage: not_yet_active.per_page, 
          pages: not_yet_active.pages, 
          totalCount: not_yet_active.total_count
        })
      }
      catch(err) {}
    }
    populateAllTables();

    return () => {
      cancel();
    };
  }, [dataState]);

  const renderZeroState = () => {
    return (
      <Container component="main" maxWidth="lg">
        <StyledCard>
          <CardContent>
            <StyledText container direction='column' alignItems='center' justify='center'>
              <Grid size={{ xs: 6 }}>
                <Typography component="h5" align="center" variant="h5" style={ { fontWeight: 800 } }>
                  {t(translationKey.LabelInviteYoungPerson)}
                </Typography>
              </Grid>
            </StyledText>
            <StyledButtonSpace container spacing={ 2 } justify='center'>
              <div>
                <StyledButton variant="contained" color="primary"
                        onClick={ () => {
                          navigate('/ln/young_person/create');
                        } }>
                  {t(translationKey.ButtonInviteYoungPerson)}
                </StyledButton>
              </div>
            </StyledButtonSpace>
            <div style={ { margin: '25px -50px', borderTop: `${ numberTextColor } solid 4px` } } />
            <CardMedia image="/GettingStartedLn.jpg" title="" style={ { height: 700 } } />
          </CardContent>
        </StyledCard>
        <Footer />
      </Container>
    );
  };

  const RenderDetailFactory = ({borderColour = "#FFF", setter, rowData}) => <RenderDetails
    setYoungPersonData={ setter }
    borderColour={ borderColour }
    reloadData={ reloadData }
    isSelfSignUpDist={isSelfSignUpDist}
    rowData={ rowData } { ...props } />;

  const renderList = props => {
    return (
      <Container component="main" maxWidth="lg" style={ { marginTop: '10px' } }>
        <StyledCard>
          <CardContent style={{ padding: "16px" }}>
            <StyledFlex>
              <StyledLeftTypography color="textSecondary">
                <strong>{t(translationKey.TitleLicensesUsed)}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>{ gameKeys.alert ?
                <Warning htmlColor='black' style={ {
                  height: '0.7em',
                  marginBottom: '-0.12em',
                } } /> : null }{t(translationKey.LabelLicensesUsed,{ used: gameKeys.usedKeys, total: gameKeys.totalKeys})}
              </StyledLeftTypography>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={ () => navigate('/ln/young_person/create') }>
                {t(translationKey.ButtonInviteYoungPerson)}
              </StyledButton>

            </StyledFlex>
          </CardContent>
        </StyledCard>

        <StyledTabs elevation={0}>
            <StyledBlock>
              {
                activeTab === tabs.NotStarted ? 
                <StyledActiveTab onClick={() => {
                  setActiveTab(tabs.NotStarted);
                  setYoungPersonsExpandedRow(null);
                  setOptedOutExpandedRow(null);
                }}>
                  {t(translationKey.LabelYoungPeopleNotYetStartedTab, { count: notYetActivePaginator.totalCount })}
                </StyledActiveTab>
                :
                <StyledTab onClick={() => {
                  setActiveTab(tabs.NotStarted);
                  setYoungPersonsExpandedRow(null);
                  setOptedOutExpandedRow(null);
                }}>
                  {t(translationKey.LabelYoungPeopleNotYetStartedTab, { count: notYetActivePaginator.totalCount })}
                </StyledTab>
              }
              {
                activeTab === tabs.Active ? 
                <StyledActiveTab onClick={() => {
                  setActiveTab(tabs.Active);
                  setNotStartedExpandedRow(null);
                  setOptedOutExpandedRow(null);
                }}>
                  {t(translationKey.LabelYoungPeoplePlayingTheGame, { count: activePaginator.totalCount })}
                </StyledActiveTab>
                :
                <StyledTab onClick={() => {
                  setActiveTab(tabs.Active);
                  setNotStartedExpandedRow(null);
                  setOptedOutExpandedRow(null);
                }}>
                  {t(translationKey.LabelYoungPeoplePlayingTheGame, { count: activePaginator.totalCount })}
                </StyledTab>
              }
              {
                activeTab === tabs.Deactivated ? 
                <StyledActiveTab onClick={() => {
                  setActiveTab(tabs.Deactivated);
                  setNotStartedExpandedRow(null);
                  setYoungPersonsExpandedRow(null);
                }}>
                  {t(translationKey.LabelYoungPeopleDeactivatedTab, { count: inactivePaginator.totalCount })}
                </StyledActiveTab>
                :
                <StyledTab onClick={() => {
                  setActiveTab(tabs.Deactivated);
                  setNotStartedExpandedRow(null);
                  setYoungPersonsExpandedRow(null);
                }}>
                  {t(translationKey.LabelYoungPeopleDeactivatedTab, { count: inactivePaginator.totalCount })}
                </StyledTab>
              }
              <YoungPersonDownloadButton filtering={'all'} />

            </StyledBlock>
        </StyledTabs>

        { activeTab === tabs.Active &&
          <Grid container direction={"column"}>
            <Grid sx={{mb:1}}>
              <YoungPersonDownloadButton filtering={ 'startedplaying' } />
            </Grid>
            <ThemeProvider theme={ tableThemer(youngPersons) }>
              <Paper>
                <TableContainer>
                  <Table>
                    {/* Table Head */}
                    <TableHead style={{ backgroundColor: listBackgroundColor }}>
                      {youngPersonsTable.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                          {headerGroup.headers.map((header) => (
                            <TableCell key={header.id}>
                              {flexRender(header.column.columnDef.header, header.getContext()) }
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableHead>

                    {/* Table Body */}
                    <TableBody>
                      {!youngPersons ? (
                        <TableRow>
                          <TableCell colSpan={youngPersonsColumns.length} align="center">
                            Loading...
                          </TableCell>
                        </TableRow>
                      ) : (
                        youngPersonsTable.getRowModel().rows.map((row, index) => (
                          <React.Fragment key={row.id}>
                            <TableRow 
                              style={{ cursor: 'pointer', borderBottom: `1px solid ${greyBorderColor}`, borderLeft: `20px solid ${ activeColor }`, borderRight: `1px solid ${ greyBorderColor }`, borderTop: `1px solid ${ greyBorderColor }`}} 
                              onClick={() => toggleRowExpansion(row.id, setYoungPersonsExpandedRow)}
                            >
                              {row.getVisibleCells().map((cell) => (
                                <TableCell key={cell.id} style={{ padding: '10px' }}>
                                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </TableCell>
                              ))}
                            </TableRow>
                            {youngPersonsExpandedRow === row.id && (
                              <TableRow style={{ cursor: 'pointer', borderBottom: `1px solid ${greyBorderColor}`, borderLeft: `20px solid ${ activeColor }`, borderRight: `1px solid ${ greyBorderColor }`, borderTop: `1px solid ${ greyBorderColor }`}}>
                                <TableCell colSpan={youngPersonsColumns.length} style={{ backgroundColor: listBackgroundColor }}>
                                  <RenderDetailFactory activeColor={activeColor} setter={ setter(youngPersons, setYoungPersons)} rowData={row.original} />
                                </TableCell>
                              </TableRow>
                            )}
                            {/* Spacer Row */}
                            {index !== youngPersonsTable.getRowModel().rows.length - 1 && (
                              <TableRow sx={{ height: "10px", backgroundColor: "transparent" }}>
                                <TableCell colSpan={youngPersonsColumns.length} sx={{padding: 0, height: "10px"}}/>
                              </TableRow>
                            )}
                          </React.Fragment>
                        ))
                      )}
                    </TableBody>

                    {/* Pagination Controls */}
                    <TableFooter>
                      <TableRow>
                        <TableCell colSpan={youngPersonsColumns.length} align="right">
                          <Button
                            disabled={activePaginator.currentPage === 0}
                            onClick={() => {setYoungPersonsExpandedRow(null); updateActivePaginator((prev) => ({
                              ...prev,
                              currentPage: 0
                            }))}}
                          >
                            <FirstPage />
                          </Button>
                          <Button
                            disabled={activePaginator.currentPage === 0}
                            onClick={() => {setYoungPersonsExpandedRow(null); updateActivePaginator((prev) => ({
                              ...prev,
                              currentPage: prev.currentPage - 1
                            }))}}
                          >
                            <ArrowBackIosNew />
                          </Button>
                          <span>
                            Page {activePaginator.currentPage + 1} of {activePaginator.pages}
                          </span>
                          <Button
                            disabled={activePaginator.currentPage + 1 >= activePaginator.pages}
                            onClick={() => {setYoungPersonsExpandedRow(null); updateActivePaginator((prev) => ({
                              ...prev,
                              currentPage: prev.currentPage + 1
                            }))}}
                          >
                            <ArrowForwardIos />
                          </Button>
                          <Button
                            disabled={activePaginator.currentPage + 1 >= activePaginator.pages}
                            onClick={() => {setYoungPersonsExpandedRow(null); updateActivePaginator((prev) => ({
                              ...prev,
                              currentPage: prev.pages - 1
                            }))}}
                          >
                            <LastPage />
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Paper>
            </ThemeProvider>
          </Grid>

        }

        { activeTab === tabs.NotStarted &&
          <Grid container direction={"column"}>
            <Grid  sx={{mb:1}}>
              <YoungPersonDownloadButton filtering={ 'notstarted' } />
            </Grid>
            <ThemeProvider theme={ tableThemer(notStartedYoungPersons) }>
              <Paper>
                <TableContainer>
                  <Table>
                    {/* Table Head */}
                    <TableHead  style={{ backgroundColor: listBackgroundColor }}>
                      {notStartedYoungPersonsTable.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                          {headerGroup.headers.map((header) => (
                            <TableCell key={header.id}>
                              {flexRender(header.column.columnDef.header, header.getContext()) }
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableHead>

                    {/* Table Body */}
                    <TableBody>
                      {!notStartedYoungPersons ? (
                        <TableRow>
                          <TableCell colSpan={notStartedYoungPersonsColumns.length} align="center">
                            Loading...
                          </TableCell>
                        </TableRow>
                      ) : (
                        notStartedYoungPersonsTable.getRowModel().rows.map((row, index) => (
                          <React.Fragment key={row.id}>
                            <TableRow 
                              style={{ cursor: 'pointer', borderBottom: `1px solid ${greyBorderColor}`, borderLeft: `20px solid ${ notActiveColor }`, borderRight: `1px solid ${ greyBorderColor }`, borderTop: `1px solid ${ greyBorderColor }`}} 
                              onClick={() => toggleRowExpansion(row.id, setNotStartedExpandedRow)}
                            >
                              {row.getVisibleCells().map((cell) => (
                                <TableCell key={cell.id} style={{ padding: '10px' }}>
                                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </TableCell>
                              ))}
                            </TableRow>
                            {notStartedExpandedRow === row.id && (
                              <TableRow style={{ cursor: 'pointer', borderBottom: `1px solid ${greyBorderColor}`, borderLeft: `20px solid ${ notActiveColor }`, borderRight: `1px solid ${ greyBorderColor }`, borderTop: `1px solid ${ greyBorderColor }`}}>
                                <TableCell colSpan={notStartedYoungPersonsColumns.length} style={{ backgroundColor: listBackgroundColor }}>
                                  <RenderDetailFactory activeColor={notActiveColor} setter={ setter(notStartedYoungPersons, setNotStartedYoungPersons)} rowData={row.original} />
                                </TableCell>
                              </TableRow>
                            )}
                            {/* Spacer Row */}
                            {index !== notStartedYoungPersonsTable.getRowModel().rows.length - 1 && (
                              <TableRow sx={{ height: "10px", backgroundColor: "transparent" }}>
                                <TableCell colSpan={notStartedYoungPersonsColumns.length} sx={{padding: 0, height: "10px"}}/>
                              </TableRow>
                            )}
                          </React.Fragment>
                        ))
                      )}
                    </TableBody>

                    {/* Pagination Controls */}
                    <TableFooter>
                      <TableRow>
                        <TableCell colSpan={notStartedYoungPersonsColumns.length} align="right">
                          <Button
                            disabled={notYetActivePaginator.currentPage === 0}
                            onClick={() => {setNotStartedExpandedRow(null); updateNotYetActivePaginator((prev) => ({
                              ...prev,
                              currentPage: 0
                            }))}}
                          >
                            <FirstPage />
                          </Button>
                          <Button
                            disabled={notYetActivePaginator.currentPage === 0}
                            onClick={() => {setNotStartedExpandedRow(null); updateNotYetActivePaginator((prev) => ({
                              ...prev,
                              currentPage: prev.currentPage - 1
                            }))}}
                          >
                            <ArrowBackIosNew />
                          </Button>
                          <span>
                            Page {notYetActivePaginator.currentPage + 1} of {notYetActivePaginator.pages}
                          </span>
                          <Button
                            disabled={notYetActivePaginator.currentPage + 1 >= notYetActivePaginator.pages}
                            onClick={() => {setNotStartedExpandedRow(null); updateNotYetActivePaginator((prev) => ({
                              ...prev,
                              currentPage: prev.currentPage + 1
                            }))}}
                          >
                            <ArrowForwardIos />
                          </Button>
                          <Button
                            disabled={notYetActivePaginator.currentPage + 1 >= notYetActivePaginator.pages}
                            onClick={() => {setNotStartedExpandedRow(null); updateNotYetActivePaginator((prev) => ({
                              ...prev,
                              currentPage: prev.pages - 1
                            }))}}
                          >
                            <LastPage />
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Paper>
            </ThemeProvider>
          </Grid>
        }

        { activeTab === tabs.Deactivated &&
          <Grid container direction={"column"}>
            <Grid  sx={{mb:1}}>
              <YoungPersonDownloadButton filtering={ 'deactivated' } />
            </Grid>
            <ThemeProvider theme={ tableThemer(optedOutYoungPersons) }>
              <Paper>
                <TableContainer>
                  <Table>
                    {/* Table Head */}
                    <TableHead  style={{ backgroundColor: listBackgroundColor }}>
                      {optedOutYoungPersonsTable.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                          {headerGroup.headers.map((header) => (
                            <TableCell key={header.id}>
                              {flexRender(header.column.columnDef.header, header.getContext()) }
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableHead>

                    {/* Table Body */}
                    <TableBody>
                      {!optedOutYoungPersons ? (
                        <TableRow>
                          <TableCell colSpan={optedOutYoungPersonsColumns.length} align="center">
                            Loading...
                          </TableCell>
                        </TableRow>
                      ) : (
                        optedOutYoungPersonsTable.getRowModel().rows.map((row, index) => (
                          <React.Fragment key={row.id}>
                            <TableRow 
                              style={{ cursor: 'pointer', borderBottom: `1px solid ${greyBorderColor}`, borderLeft: `20px solid ${ greyBorderColor }`, borderRight: `1px solid ${ greyBorderColor }`, borderTop: `1px solid ${ greyBorderColor }`}} 
                              onClick={() => toggleRowExpansion(row.id, setOptedOutExpandedRow)}
                            >
                              {row.getVisibleCells().map((cell) => (
                                <TableCell key={cell.id} style={{ padding: '10px' }}>
                                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </TableCell>
                              ))}
                            </TableRow>
                            {optedOutExpandedRow === row.id && (
                              <TableRow style={{ cursor: 'pointer', borderBottom: `1px solid ${greyBorderColor}`, borderLeft: `20px solid ${ greyBorderColor }`, borderRight: `1px solid ${ greyBorderColor }`, borderTop: `1px solid ${ greyBorderColor }`}}>
                                <TableCell colSpan={optedOutYoungPersonsColumns.length} style={{ backgroundColor: listBackgroundColor }}>
                                  <RenderDetailFactory activeColor={greyBorderColor} setter={ setter(optedOutYoungPersons, setOptedOutYoungPersons)} rowData={row.original} />
                                </TableCell>
                              </TableRow>
                            )}
                            {/* Spacer Row */}
                            {index !== optedOutYoungPersonsTable.getRowModel().rows.length - 1 && (
                              <TableRow sx={{ height: "10px", backgroundColor: "transparent" }}>
                                <TableCell colSpan={optedOutYoungPersonsColumns.length} sx={{padding: 0, height: "10px"}}/>
                              </TableRow>
                            )}
                          </React.Fragment>
                        ))
                      )}
                    </TableBody>

                    {/* Pagination Controls */}
                    <TableFooter>
                      <TableRow>
                        <TableCell colSpan={optedOutYoungPersonsColumns.length} align="right">
                          <Button
                            disabled={inactivePaginator.currentPage === 0}
                            onClick={() => {setOptedOutExpandedRow(null); updateInactivePaginator((prev) => ({
                              ...prev,
                              currentPage: 0
                            }))}}
                          >
                            <FirstPage />
                          </Button>
                          <Button
                            disabled={inactivePaginator.currentPage === 0}
                            onClick={() => {setOptedOutExpandedRow(null); updateInactivePaginator((prev) => ({
                              ...prev,
                              currentPage: prev.currentPage - 1
                            }))}}
                          >
                            <ArrowBackIosNew />
                          </Button>
                          <span>
                            Page {inactivePaginator.currentPage + 1} of {inactivePaginator.pages}
                          </span>
                          <Button
                            disabled={inactivePaginator.currentPage + 1 >= inactivePaginator.pages}
                            onClick={() => {setOptedOutExpandedRow(null); updateInactivePaginator((prev) => ({
                              ...prev,
                              currentPage: prev.currentPage + 1
                            }))}}
                          >
                            <ArrowForwardIos />
                          </Button>
                          <Button
                            disabled={inactivePaginator.currentPage + 1 >= inactivePaginator.pages}
                            onClick={() => {setOptedOutExpandedRow(null); updateInactivePaginator((prev) => ({
                              ...prev,
                              currentPage: prev.pages - 1
                            }))}}
                          >
                            <LastPage />
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Paper>
            </ThemeProvider>
          </Grid>
        }
        <Footer />
      </Container>
    );
  };

  return (
    <>
      { allPeople === 0 ? renderZeroState() : renderList(props) }
    </>
  );
}

const sorting = progress => {
  return progress && progress.sort((a, b) => order[a.status] - order[b.status]);
};

export function RenderDetails(props) {
  const {
    rowData,
    setYoungPersonData,
    reloadData,
    isSelfSignUpDist
  } = props;
  const [userData, setUserData] = useState(rowData);
  const { t } = useTranslation();
  const navigate = useNavigate();


  useEffect(() => {
    if (!getChain(userData, 'stats')) {
      axios.get(`/ln/young_persons/${ userData.id }`)
        .then(({ data }) => {
          userData.stats = data;
          setUserData(userData);
          setYoungPersonData(userData);
        })
        .catch(error => {
          genericAlert(t(translationKey.TitleError), getChain(error, 'response', 'data', 'error'));
        });
    }

  }, [userData, setYoungPersonData, t]);


  const { tableData, ...restUserData } = userData;
  const player = userData.first_name;
  const progress = sorting(getChain(userData, 'stats', 'progress'));
  const parentOnboardingSteps = getChain(userData, 'stats', 'parent_onboarding_steps');
  const gameTime = getChain(userData, 'stats', 'game_time');
  const invitedDate = rowData && rowData.created_at && new Date(rowData.created_at);
  const localeInvitedDate = dayjs(invitedDate).format(t(translationKey.DateFormat));
  const averageTime = gameTime ? gameTime.timeAverage : '';
  const totalTime = gameTime && gameTime.timeSpent;
  const totalSessions = gameTime ? gameTime.totalSessions : '';
  const totalHours = gameTime ? Math.floor(totalTime / 60) : '';
  const totalMinutes = gameTime ? Math.round(totalTime - (totalHours * 60)) : '';

  return (
    <div style={ {
      position: 'relative',
      boxSizing: 'border-box',
      overflowY: 'auto',
      overflowX: 'hidden',
    } }
         key={ userData.id }
    >
      <Grid container style={ { width: 'inherit', margin: 0, paddingLeft: '30px', paddingTop: '30px' } }
            spacing={ 3 }>
        { userData.first_play && <>
          <Grid size={{ xs: 12 }}>
            <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
              <CardContent>
                <Grid container>
                  <Grid size={{ xs: 6 }}><Typography color="textPrimary">{t(translationKey.TitlePlayerGameTime, { player })}</Typography></Grid>
                  <Grid size={{ xs: 6 }}><Typography color="textSecondary"
                                                  align="right">{(t(translationKey.TitleInvitedOn, { invitedOnDateString : localeInvitedDate|| '' }))}</Typography></Grid>
                </Grid>
              </CardContent>
              {/* <WeeklyGameTime gameTime={ gameTime } firstName={ userData.first_name } /> */ }
            </Card>
          </Grid>
          <Grid size={{ xs: 4 }}>
            <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
              <CardContent>
                <Typography color="textPrimary">{t(translationKey.TitleTotalGameSessions)} </Typography>
                <Grid container>
                  <Grid size={{ xs: 10 }}><Typography
                    style={ { fontSize: '30px', color: numberTextColor } }>{ totalSessions }</Typography></Grid>
                  <Grid size={{ xs: 2 }}><GameAsset style={ { fontSize: '40px' } } /></Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid size={{ xs: 4 }}>
            <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
              <CardContent>
                <Typography color="textPrimary">{t(translationKey.LabelAverageSessionTime)}</Typography>
                <Grid container>
                  <Grid size={{ xs: 10 }}>
                    <TypographyAverageTime average={averageTime} />
                  </Grid>
                  <Grid size={{ xs: 2 }}><TimerOutlined style={ { fontSize: '40px' } } /></Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid size={{ xs: 4 }}>
            <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
              <CardContent>
                <Typography color="textPrimary">{t(translationKey.LabelTotalTimePlaying)}</Typography>
                <Grid container>
                  <Grid size={{ xs: 10 }}>
                    <TypographyHoursAndMinutes totalHours={totalHours} totalMinutes={totalMinutes} />
                  </Grid>
                  <Grid size={{ xs: 2 }}><TimerOutlined style={ { fontSize: '40px' } } /></Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <UsageGraph playerName={player} userData={userData} setUserData={(userData) => {
            setUserData(userData);
            setYoungPersonData(userData);
          } } chartUrl={`/ln/young_persons/${ userData.id }/chart`} />
          <Grid size={{ xs: 12 }}>
            <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
              <CardContent>
                <Grid container>
                  <Grid size={{ xs: 12 }} justifyItems={"left"} sx={{ mb: 2 }}>
                    <Typography color="textSecondary">{t(translationKey.TitleParentOnboarding)}</Typography>
                  </Grid>
                  <Grid size={{ xs: 12 }}>
                  <Stepper nonLinear alternativeLabel connector={ <ChallengeConnector /> }>
                    { parentOnboardingSteps && parentOnboardingSteps.length > 0 && parentOnboardingSteps.map((item, index) => {
                      return (
                        <Step key={ item.title } completed={ item.complete }>
                          <StepButton
                            icon={ <ChallengeIcon { ...item } normalRender={true}/> } 
                          >
                          <StepLabel slotProps={{stepIcon: {icon: null}}} sx={{color: "text.secondary", py: -1, my: -1}}>{item.title}</StepLabel>
                          <StepLabel slotProps={{stepIcon: {icon: null}}} sx={{color: "text.secondary", py: -1, my: -1}}>{item.value}</StepLabel>
                          </StepButton>
                        </Step>
                      )
                    }) }
                  </Stepper>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          { progress && progress.length > 0 && <Grid size={{ xs: 12 }}>
            <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
              <CardContent>
                <Typography color="textSecondary">{t(translationKey.TitlePlayerGameProgress, { player })}</Typography>
                <GameProgress progress={ progress } />
              </CardContent>
            </Card>
          </Grid> }
        </> }
        {!userData.first_play && <Grid size={{ xs: 12 }}>
            <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
              <CardContent>
                <Grid container>
                  <Grid size={{ xs: 10 }}>
                    <Typography color="textSecondary">{t(translationKey.TitleParentOnboarding)}</Typography>
                  </Grid>
                  <Grid size={{ xs: 2 }} />
                  <Grid size={{ xs: 12 }}>
                  <Stepper nonLinear alternativeLabel connector={ <ChallengeConnector /> }>
                    {
                      parentOnboardingSteps && parentOnboardingSteps.length > 0 && parentOnboardingSteps.map((item, index) => {
                        return (
                          <Step key={ item.title } completed={ item?.complete }>
                            <StepButton
                              // completed={ item?.complete }
                              icon={ <ChallengeIcon { ...item } normalRender={true}/> } 
                            >
                              <StepLabel slotProps={{stepIcon: {icon: null}}}>{item.title}</StepLabel>
                              <StepLabel slotProps={{stepIcon: {icon: null}}}>{item.value}</StepLabel>
                            </StepButton>
                        </Step>
                        )
                      }) 
                    }
                  </Stepper>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>}
        <Grid size={{ xs: 12 }}>
          <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
            <CardContent>
              <Grid container>
                <Grid size={{ xs: 10 }}>
                  <Typography color="textSecondary">{t(translationKey.LabelContact)}</Typography>
                </Grid>
                <Grid size={{ xs: 2 }}>
                  <Grid container>
                    <Grid size={{ xs: 6 }} style={ { marginLeft: 'auto' } }>
                      <Button
                        onClick={ () => {
                          const { dob, ethnicity, first_name, gender, guardians, has_disability, disability_types, id, last_name, post_code, service_user_reference, country, test_account, custom_entry_one_response, custom_entry_two_response, custom_entry_three_response } = userData;
                          navigate(`/ln/young_person/${ id }/edit`, {
                            state: {
                              youngPerson: {
                                dob,
                                ethnicity,
                                first_name,
                                gender,
                                guardians,
                                has_disability,
                                disability_types,
                                id,
                                last_name,
                                post_code,
                                country,
                                service_user_reference,
                                test_account,
                                custom_entry_one_response,
                                custom_entry_two_response,
                                custom_entry_three_response,
                              }
                            },
                          });
                        } }
                      >
                        <Edit htmlColor={ greyBorderColor } />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <ContactDetails { ...userData } isSelfSignUpDist={isSelfSignUpDist} started={progress && progress.length > 0} />
            </CardContent>
          </Card>
        </Grid>
        { userData.id &&
        <Grid size={{ xs: 12 }}>
          <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
            <CardContent>
              <Link to={ `/ln/survey_results/${ userData.id }` }>
                <Typography color="textSecondary" style={ { textDecoration: 'underline' } }>{t(translationKey.ButtonGoalBasedOutcomes)}</Typography>
              </Link>
            </CardContent>
          </Card>
        </Grid>
        }
        <StyledDeactivateGrid style={ { display: 'flex', justifyContent: 'space-between', width: '100%' } }>
          <Grid size={{ xs: 3 }}>
            <ThemeProvider theme={ style }>
              <Button fullWidth variant="contained" color="primary"
                      onClick={ () => {
                        navigate(`/ln/young_person/${ userData.id }/report`, {state: restUserData});
                      } }>
                {t(translationKey.ButtonDownloadReport)}
              </Button>
            </ThemeProvider>
          </Grid>
          { userData.deleted_at ?
            <RemovalButton title={t(translationKey.TitleDeleteYoungPerson)} action={ () => {
              genericAlert({
                title: t(translationKey.AlertTitleConfirmDeleteYoungPerson),
                message: t(translationKey.AlertBodyConfirmDeleteYoungPerson),
                negativeTitle: t(translationKey.LabelCancel),
                positiveTitle: t(translationKey.LabelDelete),
                positiveCallback: () => {
                  axios
                    .delete(`/ln/young_persons/${ userData.id }/anonymise`)
                    .then(reloadData);
                },
              });
            } } />
            : <RemovalButton title={t(translationKey.TitleDeactivateGameKey)} action={ () => {
              axios.get('/ln/young_persons/deactivation_reason')
                .then(({ data }) => {
                  let reason;
                  const setReason = (r) => {
                    reason = r;
                  };
                  genericAlert({
                    title: t(translationKey.AlertTitleDeactivationReason),
                    component: () => <ReasonSelection data={ data } setReason={ setReason } />,
                    negativeTitle: t(translationKey.LabelCancel),
                    positiveTitle: t(translationKey.ButtonSubmit),
                    positiveCallback: () => {
                      const { id } = userData;
                      if (id) {
                        axios.delete(`/ln/young_persons/${ id }`, {
                          data: {
                            reason,
                          },
                        })
                          .then(reloadData)
                          .catch(identity);
                      }
                    },
                  });
                });
            } }
            />
          }
        </StyledDeactivateGrid>
      </Grid>
    </div>
  );
}

const RemovalButton = ({ title, action }) =>
  <StyledDeactivateButton onClick={ action }>
    { title }
  </StyledDeactivateButton>;

RemovalButton.propTypes = {
  title: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
};

RenderDetails.propTypes = {
  borderColour: PropTypes.string.isRequired,
  rowData: PropTypes.object.isRequired,
  reloadData: PropTypes.func.isRequired,
  setYoungPersonData: PropTypes.func.isRequired,
};

const ReasonSelection = ({ data, setReason }) => {
  const [internalReason, setInternalReason] = useState();
  const { t } = useTranslation();
  return (
    <form>
      <FormControl component="fieldset">
        <FormLabel component="legend">Reason</FormLabel>
        <RadioGroup aria-label={t(translationKey.LabelReason)} name='reason'
                    onChange={ event => {
                      let value = parseInt(event.target.value);
                      setReason(value);
                      setInternalReason(value);
                    } }>
          {
            data.map(each => <FormControlLabel key={ each.id } value={ each.id } control={ <Radio /> }
                                               label={ each.value } checked={ each.id === internalReason } />)
          }
        </RadioGroup>
      </FormControl>
    </form>
  );
};

const YoungPersonDownloadButton = ({ filtering }) => {
  const { t } = useTranslation();
  return(
    <div
      style={ {
        width: 'fit-content',
        cursor: 'pointer',
        marginRight: '1em',
        textAlign: 'right',
        float: 'right',
        marginTop: '8px',
        backgroundColor: '#E6E6E6',
        padding: '0.5em',
      } }
      onClick={ () => {
        downloadCSV(filtering);
      } }>

      <Grid container>
        <ArrowDownwardSharp />
        <Typography> {filtering === 'all' ? t(translationKey.LabelDownloadAllYoungPeople) : t(translationKey.LabelDownloadYoungPeople)} </Typography>
      </Grid>
    </div>
  );
};

ReasonSelection.propTypes = {
  data: PropTypes.array.isRequired,
  setReason: PropTypes.func.isRequired,
};
