import React from 'react';
import { Grid2 as Grid } from '@mui/material';
import { styled } from '@mui/styles';

const StyledGrid = styled(Grid)(({ theme }) => ({
  borderBottom: '0.3rem solid #063494',
  background: '#fff',
  boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
  height: '6rem',
  display: 'grid',
  placeItems: 'center start'
}))

const StyledLogo = styled('img')(({ theme }) => ({
  height: '5rem',
  marginTop: 'auto',
  marginBottom: 'auto',
  padding: '0.5rem',
}))



export default function SelfSignUpHeader({ guid }) {

  return (
    <StyledGrid container justify="flex-start" >
      <Grid size={{xs:12}} style={ { display: 'grid', placeItems: 'start' } }>
        {
          guid &&
          <StyledLogo src={ `${ process.env.REACT_APP_BASE_URL }/entity/logo/${ guid }` }
               alt="Entity Logo" />
        }
      </Grid>
    </StyledGrid>
  )
}