import React from 'react';
import { Grid2 as Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';

const StyledGrid = styled(Grid)(({ theme }) => ({
  background: '#063494',
  marginTop: '2rem',
  padding: '1rem',
}))

const StyledLogo = styled('img')(({ theme }) => ({
  width: '100%',
  maxWidth: '6rem',
  margin: '1rem 0',
}))

const StyledDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#ffffff',
}))

export default function SelfSignUpFooter() {
  const { t } = useTranslation();

  return (
    <StyledGrid container justify="center">
      <Grid size={{xs:12}}>
        <StyledLogo src="../../BfB_logo_white_powered_by.png" alt="BfB Logo" />
      </Grid>
      <Grid size={{xs:12}}>
        <StyledDiv>
          <a rel="noopener noreferrer"
             target="_blank"
             href="https://www.bfb-labs.com/lumi-nova-privacy-policy ">{t(translationKey.TitlePrivacyPolicy)}</a>
          &nbsp;|&nbsp;
          <a rel="noopener noreferrer"
             target="_blank"
             href="https://bfb-labs.com">BfB Labs © { new Date().getFullYear() }</a>
        </StyledDiv>
      </Grid>
    </StyledGrid>
  )
}
