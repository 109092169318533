import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/styles';
import Container from '@mui/material/Container';
import { axios } from '../../services/networkRequest';
import { Formik } from 'formik';
import { emailRegex } from '../../services/helpers';
import { confirmAlert } from 'react-confirm-alert';
import Error from '../Error';
import RequiredInfoText from '../RequiredInfoText';
import { helperTextColor } from '../../constants';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';
import { useNavigate } from 'react-router-dom';
import { StyledSubmitButton } from '../../StyledComponents';

const StyledLogo = styled('img')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  width: '100px',
  height: 'auto',
}));

const StyledPaper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const GlobalStyles = styled('div')(({ theme }) => ({
  body: {
    backgroundColor: theme.palette.common.white,
  },
}));

function ForgotPassword(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
      <Container component="main" maxWidth="s" sx={{ position: 'relative', width: '100%' }}>
        <CssBaseline />
        <GlobalStyles />
        <StyledLogo src='/BfB_logo_blue.png' alt='logo'/>
        <StyledPaper>
          <Typography component="h1" variant="h5">
            {t(translationKey.TextEnterEmailForPasswordReset)}
          </Typography>
          <RequiredInfoText />
          <Formik
            initialValues={ { email: '' } }
            validate={ values => {
              const errors = {};
              if (!values.email) {
                errors.email = t(translationKey.ErrorRequired);
              } else if (!emailRegex.test(values.email)) {
                errors.email = t(translationKey.ErrorInvalidEmail);
              }
              return errors;
            } }
            onSubmit={ ({ email }, { setSubmitting }) => {
              setSubmitting(true);
              axios.post(props.url, { email }).finally(() => {
                setSubmitting(false);
                confirmAlert({
                  title: t(translationKey.AlertTitleSuccess),
                  message: t(translationKey.AlertBodyResetLinkSent),
                  buttons: [
                    {
                      label: t(translationKey.ConfirmationOk),
                      onClick: () => {
                        navigate('/');
                      },
                    },
                  ],
                });
              })
            } }
          >
            { ({
                 values,
                 errors,
                 touched,
                 handleChange,
                 handleBlur,
                 handleSubmit,
                 isSubmitting,
               }) => (
              <form onSubmit={ handleSubmit }>
                <Error message={ errors['network'] } />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  type="email"
                  fullWidth
                  id="email"
                  label={t(translationKey.LabelEmailAddress)}
                  name="email"
                  autoComplete="email"
                  onChange={ handleChange }
                  onBlur={ handleBlur }
                  value={ values.email }
                  autoFocus
                  helperText={ errors.email && touched.email && errors.email }
                  slotProps={{ formHelperText: { style: { color: helperTextColor } } }}
                />
                <StyledSubmitButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={ isSubmitting }
                >
                  {t(translationKey.ButtonSubmit)}
                </StyledSubmitButton>
              </form>
            ) }
          </Formik>
        </StyledPaper>
      </Container>
  );
}

export default ForgotPassword;
