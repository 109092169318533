import React from 'react';
import { styled } from '@mui/styles';
import { Button, Card, CardContent, Container, Grid2 as Grid, Typography } from '@mui/material';
import ExitButton from '../../ExitButton';
import Footer from '../../content/Footer';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../../utilities/localisation/translationKeys';
import { useNavigate } from 'react-router-dom';

const StyledBottomBorder = styled(Grid)(({ theme }) => ({
  borderBottom: '1px solid #ccc',
}));

const StyledTopBorder = styled(Grid)(({ theme }) => ({
  borderTop: '1px solid #ccc',
  marginTop: '75px',
  paddingTop: '20px',
}));

const StyledCard = styled(Card)(({ theme }) => ({
  minWidth: 275,
}));

const StyledText = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  paddingTop: '75px',
}));

const StyledTextSpace = styled(Typography)(({ theme }) => ({
  paddingTop: '20px',
}));

export default function CotsConfirmationScreen(props) {
  const { t } = useTranslation();
  const navigate = useNavigate()

  return(
    <Container component="main" maxWidth="lg">
      <StyledCard>
        <CardContent>
          <StyledBottomBorder container direction="row" justify="space-between" alignItems="center">
            <Grid size={{ xs: 3 }}>
              <Typography component="h6" variant="body1" align="left">{t(translationKey.LabelCreateYoungProfile)}</Typography>
            </Grid>
            <ExitButton />
          </StyledBottomBorder>
          <StyledText container direction='column' alignItems='center' justify='center'>
            <Grid size={{ xs: 6 }}>
              <Typography component="h1" align="center" variant="body1">
                {t(translationKey.MessageProfileCreated)}
              </Typography>
            </Grid>
            <Grid size={{ xs: 6 }}>
              <StyledTextSpace component="h1" align="center" variant="body1">
                {t(translationKey.MessageWithDownloadLinkSent)}
              </StyledTextSpace>
            </Grid>
          </StyledText>
          <StyledTopBorder container spacing={ 2 } justify='center'>
            <Grid size={{ xs: 2 }}>
              <Button fullWidth variant="contained" color="primary" onClick={ () => {
                navigate('/cots/young_persons', {replace: true});
              } }>
                {t(translationKey.LabelOk)}
              </Button>
            </Grid>
          </StyledTopBorder>
        </CardContent>
      </StyledCard>
      <Footer />
    </Container>
  );
}
