import React, {useState, useEffect} from 'react';
import { Button, Grid2 as Grid } from '@mui/material';
import { axios } from '../../services/networkRequest';
import DistributorList from '../Distributors/DistributorList';
import { Delete, Edit, Settings } from '@mui/icons-material';
import { deleteAlert, deleteDistributorAlert, restoreAlert } from '../../services/helpers';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { EntityDetails } from './InfoDisplay/EntityDetails';

import { useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { StyledSubGridCard } from '../../StyledComponents';

const EntityDetailsPage = (props) => {
  const { t } = useTranslation();
  
  const navigate = useNavigate()
  const location = useLocation();
  const { ent_id, org_id } = useParams();

  const [state, setState] = useState(location.state || {});
  const {entity, organisation} = state;
  const { invitees = [], distributors = [] } = entity || {};

  useEffect(() => {
    const populateEntity = async () => {
      try {
        const { data } = await axios.get(`/entity/${ent_id}`);
        setState((prevState) => ({ ...prevState, entity: data }));
      }
      catch (error) {
        console.error('Error fetching entity data', error);
      }
    };
    populateEntity();
  }, [ent_id]);

  const deleteInvitee = ({ first_name, last_name, id }) => {
    if (typeof (id) === 'string') {
      id = id.startsWith('i') ? id.substring(1) : id;
    }

    deleteAlert(`${ first_name } ${ last_name }`, `/invite/distributor/${ id }`, () => setState((prevState) => ({ ...prevState, entity })));
  }

  const deleteDistributor = ({ name, id, young_persons }) => {
    if (typeof (id) === 'string') {
      id = id.startsWith('d') ? id.substring(1) : id;
    }

    const sorted = young_persons.sorted;
    const activeYps = sorted.active.length;
    const notStartedYps = sorted.not_yet_active.length;
    const inactiveYps = sorted.inactive.length;

    deleteDistributorAlert(id, name, `/distributor/${ id }`, () => setState((prevState) => ({ ...prevState, entity })), activeYps, notStartedYps, inactiveYps);
  }

  const restoreDistributor = ({ name, id }) => {
    if (typeof (id) === 'string') {
      id = id.startsWith('d') ? id.substring(1) : id;
    }

    restoreAlert(name, `/distributor/restore/${ id }`, () => setState((prevState) => ({ ...prevState, entity })));
  }

  const deleteEntity = () => {
    axios.get(`/entity/${ entity.id }/delete_check`)
      .then(({ data }) => {
        const { distributors, active_users } = data;
        let message;
        if (active_users) {
          message = t(translationKey.MessageConfirmDeleteEntity);
        }
        else if (distributors) {
          message = t(translationKey.MessageDistributorsWillAlsoBeRemoved, { list: distributors});
        }

        deleteAlert(entity.name, `/entity/${ entity.id }`, () => {
          navigate(-1);
        }, message);
      });
  }

  const editEntity = () => {
    navigate(`/organisation/${ org_id }/entity/${ ent_id }/edit`, {state:{ entity, organisation }});
  }

  const entitySettings = () => {
    navigate(`/organisation/${ org_id }/entity/${ ent_id }/settings`, {state: { entity }});
  }

  return (
    <div>
      <Grid container style={ { margin: '1rem 0' } }>
        <Grid size={{ xs: 12 }}>
          <StyledSubGridCard>
            <Button onClick={ editEntity } color='default'>
              <Edit />
            </Button>
            <Button onClick={ deleteEntity } color='default'>
              <Delete />
            </Button>
            <Button onClick={ entitySettings } color='default'>
              <Settings />
            </Button>
          </StyledSubGridCard>
        </Grid>
      </Grid>
      <EntityDetails entity={ entity } />
      <br />
      <Button
        variant="contained"
        color="primary"
        onClick={ () => navigate(`/organisation/${ org_id }/entity/${ ent_id }/distributor/invite`) }>
        {t(translationKey.ButtonCreateDistributor)}
      </Button>
      <br />
      <br />
      <DistributorList
        distributors={ distributors }
        invitees={ invitees }
        deleteDistributor={ deleteDistributor }
        deleteInvitee={ deleteInvitee }
        restoreDistributor={ restoreDistributor }
        clickDistributor={ (event, rowData) => navigate(`/distributor/${ rowData.id }`, {state:{ distributor: rowData, allDistributors:  organisation.distributors}}) }
      />
    </div>
  );
}

export default EntityDetailsPage;
