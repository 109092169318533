import React, { useEffect, useState } from 'react';
import { styled } from '@mui/styles';
import { helperTextColor } from '../../constants';
import { Button, Card, CardContent, Container, FormControl, Grid2 as Grid, TextField } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { axios } from '../../services/networkRequest';
import { useParams } from 'react-router-dom';
import { translationKey } from '../../utilities/localisation/translationKeys';
import Typography from '@mui/material/Typography';

const StyledTextField = styled(TextField)(({ theme }) => ({
  margin: 'auto',
  maxWidth: '50%',
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: 'auto',
  width: '100%',
  marginTop: '16px',
  textAlign: 'center',
}));

const StyledCard = styled(Card)(({ theme }) => ({
  minWidth: 275,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  float: 'center',
  padding: '0.75rem 3rem',
  background: '#CA218C',
  '&:hover': {
    background: '#9c1169',
  },
  textTransform: 'none',
}));

const StyledGameLogo = styled('img')({
  width: '10rem',
  cursor: 'pointer',
});

const StyledBanner = styled('div')(({ theme }) => ({
  marginBlockStart: '2rem',
  background: 'rgb(234, 242, 249)',
  paddingBlockEnd: '2rem',
  paddingInline: '2rem',
  fontSize: '1rem',
}));

const StyledErrorBox = styled('div')({
  padding: '1rem',
  marginTop: '2rem',
  border: '3px solid red',
  width: 'fit-content',
  justifySelf: 'center',
});

const StyledCustomSupportText = styled('div')(({ theme }) => ({
  textAlign: 'left',
  marginTop: '50px',
  "@media (min-width: 1025px)": {
  // [theme.breakpoints.up('lg')]: {
    maxWidth: '35vw',
  },
}));

function PostCodeValidationScreen({ entity }) {
  const navigate = useNavigate();
  const { keycode } = useParams();
  const {t} = useTranslation();
  const [postcodeRestrictionSettings, setPostcodeRestrictionSettings] = useState(undefined );
  const [customErrorMessage, setCustomErrorMessage] = useState(undefined);
  const [entityId, setEntityId] = useState(undefined);
  const [showError, setShowError] = useState(false);
  const [selfSignupEnabled, setSelfSignupEnabled] = useState(false);

  const [showInvalidPostcodeError, setShowInvalidPostcodeError] = useState(false);
  useEffect(() => {
    if(entity) {
      setSelfSignupEnabled(entity.self_sign_up_settings.enable);
      setPostcodeRestrictionSettings(entity.postcode_restriction_settings);
      setCustomErrorMessage(entity.postcode_restriction_settings?.custom_error_message);
      setEntityId(entity.entity_id);
    }
  }, [entity]);

  return (
    <Container component="main" maxWidth="lg">
      {
        selfSignupEnabled ?
      <StyledCard>
        <CardContent>
          <Grid container justifyContent="center">
            <StyledGameLogo src="/ln_logo_transparent_background.png" alt="Lumi Nova logo"/>
          </Grid>

          { postcodeRestrictionSettings && postcodeRestrictionSettings.availability_text &&
            <Grid container justifyContent="center">
              <StyledBanner>
                <Typography component="h1" align="center" variant="body1" style={{ fontWeight: 'bold', fontSize: '1rem', marginTop: '2rem' }}>
                  { postcodeRestrictionSettings.availability_text }
                </Typography>
              </StyledBanner>
            </Grid> }

          <Formik initialValues={{
            postcode: ""
          }}
          onSubmit={(values) =>{
            setShowError(false);
            setShowInvalidPostcodeError(false);
            axios.post(`/entity/${ entityId }/check_postcode`, { ...values })
              .then(({ data }) => {
                setPostcodeRestrictionSettings(data.postcode_restriction_settings)
              })
              .then(response => {
                navigate(`/signup/${keycode}/create_account`, {state: {
                  child_postcode: values.postcode
                }})
              })
              .catch((err) => {
                if(err.response.status === 400) {
                  setShowInvalidPostcodeError(true);
                } else {
                  if(!customErrorMessage) {
                    setCustomErrorMessage(err.response.data.error);
                  }
                  setShowError(true);
                }

              })
          }}>
            {({values, handleSubmit, handleBlur, handleChange}) => {
              return (
                <form onSubmit={handleSubmit} autoComplete="off">
                  <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid size={{xs:12}}>
                      <Typography style={{ fontSize: '20px', marginTop: '2rem', fontWeight: 'bold' }}>{t(translationKey.LabelCheckYourPostcode)}</Typography>
                      <p>{t(translationKey.LabelEnterYourPostcode)}</p>
                    </Grid>

                    <Grid size={{xs:12}}>
                      <StyledFormControl variant="outlined">
                        <StyledTextField
                          label="Postcode"
                          margin="normal"
                          variant="outlined"
                          type="text"
                          name="postcode"
                          onBlur={handleBlur}
                          value={values.postcode}
                          slotProps={{
                            inputLabel: { shrink: true },
                            formHelperText: { style: { color: helperTextColor },
                          }}}
                          onChange={handleChange}
                          required={true}
                        />
                      </StyledFormControl>
                    </Grid>

                    { customErrorMessage && showError && 
                    <Grid size={{xs:12}}>
                      <StyledErrorBox>
                        <Typography align="center">
                          { customErrorMessage }
                        </Typography>
                        { postcodeRestrictionSettings && postcodeRestrictionSettings.custom_error_cta_url && postcodeRestrictionSettings.custom_error_cta_message &&
                          <Typography align="center" style={ { fontWeight: 'bold' } }>
                            <a href={ postcodeRestrictionSettings.custom_error_cta_url } target="_blank"
                               rel="noopener noreferrer">{ postcodeRestrictionSettings.custom_error_cta_message } </a>
                          </Typography> }
                      </StyledErrorBox>
                    </Grid> }

                    { showInvalidPostcodeError && 
                    <Grid size={{xs:12}}>
                      <StyledErrorBox>
                          <Typography align="center">
                            {t(translationKey.InvalidPostcodeError)}
                          </Typography>
                      </StyledErrorBox>
                    </Grid> }

                    <Grid container spacing={2} style={{marginTop: '2rem'}}>
                      <Grid size={{xs:12}}>
                        <StyledButton variant="contained" color="primary" type="primary"
                                style={{textTransform: 'none'}}>
                          { t(translationKey.ButtonCheckMyEligibility) }
                        </StyledButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              )
            }}
          </Formik>
          { postcodeRestrictionSettings && <Grid container direction="row" justifyContent="center">
            <Grid>
              <StyledCustomSupportText dangerouslySetInnerHTML={{ __html: postcodeRestrictionSettings.custom_support_text }} />
            </Grid>
          </Grid> }
        </CardContent>
      </StyledCard>
          :
          <Grid container justifyContent={"center"}>
            <StyledErrorBox >
              <Typography align="center">
                { t(translationKey.LabelSelfSignupDisabled) }
              </Typography>

            </StyledErrorBox>
          </Grid>
      }
    </Container>
  )
}

export default PostCodeValidationScreen;