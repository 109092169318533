import React, {useState, useEffect} from 'react';
import { Button, Card, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { deleteDistributorAlert } from '../../services/helpers';
import { translationKey } from '../../utilities/localisation/translationKeys';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const DistributorDetailsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const [distributor, setDistributor] = useState(location.state.distributor);
  // const [allDistributors, setAllDistributors] = useState(location.state.allDistributors);

  useEffect(() => {
    setDistributor(location.state.distributor);
    // setAllDistributors(location.state.allDistributors);
  }, [location.state.distributor]);


  const deleteDistributor = () => {
    let { name, id, young_persons } = distributor;
    if (typeof (id) === 'string') {
      id = id.startsWith('d') ? id.substring(1) : id;
    }

    const sorted = young_persons.sorted;
    const activeYps = sorted.active.length;
    const notStartedYps = sorted.not_yet_active.length;
    const inactiveYps = sorted.inactive.length;

    deleteDistributorAlert(id, name, `/distributor/${ id }`, navigate(-1), activeYps, notStartedYps, inactiveYps);
  }

  const editDistributor = () => {
    navigate(`/distributor/${ distributor.id }/edit`, {state:{ distributor: distributor }});
  }

    return (
      <div>
        <Button onClick={editDistributor}>
          <EditIcon />
        </Button>
        <Button onClick={deleteDistributor}>
          <DeleteIcon />
        </Button>
        <Card>
          <Typography><strong>{t(translationKey.TitleDistributor)}</strong></Typography>
          <Typography><strong>{t(translationKey.LabelColonName)}</strong> { distributor.name }</Typography>
          <Typography><strong>{t(translationKey.LabelColonAddress)}</strong> { distributor.address }</Typography>
          <Typography><strong>{t(translationKey.LabelColonEmail)}</strong> { distributor.email }</Typography>
          <Typography><strong>{t(translationKey.LabelColonPhone)}</strong> { distributor.phone }</Typography>
        </Card>
      </div>
    );
}

export default DistributorDetailsPage;
