import React, { useContext, useEffect, useState } from 'react';
import YoungPersonForm from './YoungPersonForm';
import { useParams } from 'react-router-dom';
import ActivityContext, { Activities } from '../../../store/ActivityContext';
import { axios } from '../../../services/networkRequest';
import { LoadingSpinnerFullScreen } from '../../Loading';
import { ErrorPage } from '../../Error';
import ErrorModal from '../../ErrorModal';
import { getChain } from '../../../services/helpers';
import SelfSignUpFooter from '../../SelfSignUp/Footer';
import SelfSignUpHeader from '../../SelfSignUp/Header';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../../utilities/localisation/translationKeys';
import { useNavigate, useLocation } from "react-router-dom";

export default function SelfSignUpPage(props) {

  const { keycode } = useParams();
  const { startActivity, completeActivity, activities } = useContext(ActivityContext);
  const [entitySettings, setEntitySettings] = useState(undefined);

  const selfSignUpEnabled = getChain(entitySettings, 'self_sign_up_settings', 'enable');
  const guid = getChain(entitySettings, 'self_sign_up_settings', 'guid');

  const [signUpUrlError, setSignUpUrlError] = useState(undefined);
  const [errorResponse, setErrorResponse] = useState(undefined);

  const { t } = useTranslation();
  const location = useLocation()
  const childPostcode = location.state?.child_postcode || undefined;
  const navigate = useNavigate();

  useEffect(() => {
    startActivity(Activities.SelfSignUpDetails);
    axios.get(`/entity/${ keycode }`)
      .then(({ data }) => {
        setEntitySettings(data);
        if(data.postcode_restriction_settings && data.postcode_restriction_settings.enable && !childPostcode) {
          navigate(`/signup/${keycode}`);
        }
      })
      .catch(err => {
        setSignUpUrlError(err);
      })
      .finally(() => completeActivity(Activities.SelfSignUpDetails));
          // eslint-disable-next-line
  }, [startActivity, completeActivity, keycode, childPostcode]);

  const onSubmitForm = (values) => {
    return axios.post(`/signup/${ keycode }`, values)
      .then((data) => {
        
        if(data.data.hasOwnProperty("redirect_url"))
        {
          window.location.replace(data.data.redirect_url)
        }
        else
        {
          navigate(`/signup/${ keycode }/success`, { entitySettings: entitySettings });
        }
      });
  }

  const onError = (errorResponse) => {
    setErrorResponse(errorResponse);
  }

  return (
    activities[Activities.SelfSignUpDetails] ?
      <LoadingSpinnerFullScreen /> :
      <>
        <SelfSignUpHeader guid={ guid } />
        {
          selfSignUpEnabled ?
            <YoungPersonForm
              title={t(translationKey.TitleLumiNovaAccessFormGreatNews)}
              subTitle={t(translationKey.TitleLumiNovaAccessForm)}
              entitySettings={ entitySettings }
              onSubmitForm={ onSubmitForm }
              onError={ onError }
              childPostcode={ childPostcode }
              keycode={ keycode }
              { ...props }
            /> :
            <ErrorPage entitySettings={ entitySettings } signUpUrlError={ signUpUrlError } />
        }
        { errorResponse && <ErrorModal open={ true }
                                       errorResponse={ errorResponse }
                                       customSupportInfo={ getChain(entitySettings, 'custom_support_info') }
                                       handleClose={ () => setErrorResponse(undefined) }
        />
        }
        <SelfSignUpFooter />
      </>
  );
}
