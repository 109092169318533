import React from 'react';
import EntityForm from './EntityForm';
import { getChain } from '../../services/helpers';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';
import { useLocation } from 'react-router-dom';


function EntityEditPage(props){
  const { t } = useTranslation();
  const location = useLocation();

  return (
  <EntityForm
    { ...props }
    path={ `/entity/${ getChain(location.state.entity, 'id') }` }
    entity={ location.state.entity }
    title={t(translationKey.TitleEditEntity, { name: getChain(location.state.entity, 'name')})}
    organisation={ location.state.organisation }
  />);
}
export default EntityEditPage;
