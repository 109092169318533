import React from 'react';
import { Button, Container, TextField } from '@mui/material';
import { styled } from '@mui/styles';
import { axios, catchAxios } from '../../services/networkRequest';
import { Formik } from 'formik';
import { emailRegex, genericAlert } from '../../services/helpers';
import Error from '../Error';
import RequiredInfoText from '../RequiredInfoText';
import { helperTextColor } from '../../constants';
import ExitButton from '../ExitButton';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';
import { useNavigate } from 'react-router-dom';

const StyledSubmitButton = styled(Button)(({ theme }) => ({
  display: 'block',
  margin: '16px auto',
}))

function AdminInvite(props){
  const { t } = useTranslation();
  const navigate = useNavigate();

    return (
      <>
        <ExitButton style={ { float: 'right', padding: '20px' } }
                    confirmation={ props.confirmation } />
        <Container component="main" maxWidth="lg">
          <RequiredInfoText />
          <Formik
            initialValues={ { email: '' } }
            onSubmit={ ({ email }, { setSubmitting, setFieldError }) => {
              setSubmitting(true);
              axios.post('/invite/admin', { email }).then(() => {
                genericAlert({
                  title: t(translationKey.AlertTitleAdminInvite),
                  message: t(translationKey.AlertBodyInviteSent),
                  positiveTitle: t(translationKey.ConfirmationOk),
                  positiveCallback: () => navigate('/admins'),
                })
              })
                .catch(catchAxios(setFieldError))
                .finally(() => setSubmitting(false));
            } }
            validate={ values => {
              const errors = {};
              if (!values.email) {
                errors.email = t(translationKey.ErrorRequired);
              } else if (!emailRegex.test(values.email)) {
                errors.email = t(translationKey.ErrorInvalidEmail);
              }
              return errors;
            } }
          >
            { ({
                 errors,
                 values,
                 touched,
                 handleChange,
                 handleBlur,
                 handleSubmit,
                 isSubmitting,
               }) => (
              <form onSubmit={ handleSubmit }>
                <Error message={ errors['network'] } />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  type="email"
                  id="email"
                  label={t(translationKey.LabelEmailAddress)}
                  name="email"
                  autoComplete="email"
                  onChange={ handleChange }
                  onBlur={ handleBlur }
                  value={ values.email }
                  helperText={ errors.email && touched.email && errors.email }
                  slotProps={{ formHelperText: { style: { color: helperTextColor } } }}
                  autoFocus
                />
                <StyledSubmitButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={ isSubmitting }
                >
                  {t(translationKey.ButtonSubmit)}
                </StyledSubmitButton>
              </form>
            ) }
          </Formik>
        </Container>
      </>
    );

}

export default AdminInvite;
