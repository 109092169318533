import React from 'react';
import { Container } from '@mui/material';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Organisations from '../Organisations/Organisations';
import OrganisationsDetailsPage from '../Organisations/Details';
import OrganisationsCreatePage from '../Organisations/Create';
import LoginPage from '../Auth/Login';
import ForgotPassword from '../Auth/ForgotPassword';
import ResetPassword from '../Auth/ResetPassword';
import AdminsInvite from '../Admins/Invite';
import DistributorsInvite from '../Distributors/Invite';
import MenuAppBar from './Navbar';
import InvitationCreationPage from '../Invitation/Create';
import LnYoungPeoplePage from '../Child/LN/YoungPeople';
import LnEditYoungPerson from '../Child/LN/EditYoungPerson';
import LnCreateYoungPerson from '../Child/LN/CreateYoungPerson';
import LnSelfSignUpYoungPerson from '../Child/LN/SelfSignUp';
import LnConfirmationScreen from '../Child/LN/ConfirmationScreen';
import CotsConfirmationScreen from '../Child/COTS/ConfirmationScreen';
import CotsYoungPeoplePage from '../Child/COTS/YoungPeople';
import CotsCreateYoungPerson from '../Child/COTS/CreateYoungPerson';
import CotsEditYoungPerson from '../Child/COTS/EditYoungPerson';
import OrganisationEditPage from '../Organisations/Edit';
import { ROLES } from '../../services/helpers';
import Admins from '../Admins/Admins';
import EntityDetailsPage from '../Entities/Details';
import EntityCreatePage from '../Entities/Create';
import DistributorDetailsPage from '../Distributors/Details';
import DistributorEditPage from '../Distributors/Edit';
import DistributorsCreatePage from '../Distributors/Create';
import EntityEditPage from '../Entities/Edit';
import EntitySettingsPage from '../Entities/Settings';
import FAQs from '../FAQs/FAQs';
import CotsFAQs from '../FAQs/CotsFAQs';
import GuardianSurvey from '../Distributors/GuardianSurvey';
import { loginPath, maintenancePath } from '../../services/networkRequest';
import AggregatedData from '../AggregatedData/AggregatedData';
import Resources from '../Resources/Resources';
import Dashboard from '../Dashboard';
import CotsDashboard from '../CotsDashboard';
import MaintenancePage from '../MaintenancePage';
import YoungPersonReport from '../Child/LN/YoungPersonReport';
import GameChoice from '../Distributors/GameChoice';
import PPDashboardLN from '../PointPerson/PPDashboardLN';
import MonthlyReports from '../PointPerson/MonthlyReports';
import { CotsYoungPersonReport } from '../Child/COTS/YoungPersonReport';
import { Games } from '../../constants';
import SuccessPage from '../SuccessPage';
import OrganisationSettings from '../Organisations/OrganisationSettings';
import MHSDSAggregatedData from '../MHSDSAggregatedData';
import PostCodeValidation from '../PostcodeValidation';
import { useSelector } from 'react-redux';

const LoginRedirect = () => <Navigate to={ loginPath } replace/>;

// const PrivateRoute = ({ element, role,  ...props }) => {
//   console.log(props.path);
  
//   const { user } = useUserContext();

//   const active = role && user && role === user.role;
//   return (<Route { ...props } element={ active ? element : <Navigate to={loginPath} replace /> } />);
// }

const PrivateRoute = ({role, element: Element, ...rest}) => {
  const user = useSelector((state) => state.user.user);

  const active = role && user && role === user.role;

  return active ? <Element {...rest}/> : <Navigate to={loginPath} replace />;
}

const Menu = ({ user }) => {
  return (user && <MenuAppBar name={ user.name } role={ user.role } version={ user.version } games={ user.games } currentGame={ user.currentGame } />) || null;
}

const HomeRoute = ({ components, user, default: DefaultComponent, ...props }) => {
  const role = user?.role;
  const Component = components[role] || DefaultComponent;

  return (<Component />);
}

// const GamePicker = ({ components, user, ...props }) => {
//   const game = user?.currentGame;
//   const Component = components[game] || components.default;

//   return <Component {...props}/>;
// }

const  DistributorRedirector = ({user}) => {
  const game = user?.currentGame;
  const Component = {
    ln: LnYoungPeoplePage,
    cots: CotsYoungPeoplePage,
    // default: GameChoice,
  }[game] || GameChoice;
  return  <Component />;
}


 const Content = () => {
  const user = useSelector((state) => state.user.user);
  
  const location = useLocation()


  const matchLNReport = location.pathname.startsWith('/ln/young_person/') && location.pathname.endsWith('/report');
  const matchCotsReport = location.pathname.startsWith('/cots/young_person/') && location.pathname.endsWith('/report');
  const matchGameChoice = location.pathname === '/game_choice';
  const selfSignUp = location.pathname.startsWith("/signup");

  return (
    <Container maxWidth="xl">
      { (!matchLNReport && !matchGameChoice && !matchCotsReport) && !selfSignUp && <Menu user={user} /> }
      <Routes>
        <Route path="/organisations" element={<PrivateRoute role={ ROLES.admin } element={ Organisations } />} />
        
        <Route path='/' element={<HomeRoute 
                   components={ {
                     admin: Organisations, point_person: PPDashboardLN, distributor: DistributorRedirector
                   } }
                   user={user}
                   default={ LoginRedirect } />} />
        {/* Organisation Private Routes */}
        <Route path="/organisation/create" element={<PrivateRoute role={ ROLES.admin } element={ OrganisationsCreatePage } />} />
        <Route path="/organisation/:org_id" element={<PrivateRoute role={ ROLES.admin } element={ OrganisationsDetailsPage } />} />
        <Route path="/organisation/:org_id/edit" element={<PrivateRoute role={ ROLES.admin } element={ OrganisationEditPage } />} />
        <Route path="/organisation/:org_id/settings" element={<PrivateRoute role={ROLES.admin} element={ OrganisationSettings} />} />
        
        <Route path="/organisation/:org_id/entity/create" element={<PrivateRoute role={ ROLES.admin } element={ EntityCreatePage } />} /> 
        <Route path="/organisation/:org_id/entity/:ent_id" element={<PrivateRoute role={ ROLES.admin } element={ EntityDetailsPage } />} /> 
        <Route path="/organisation/:org_id/entity/:ent_id/edit" element={<PrivateRoute role={ ROLES.admin } element={ EntityEditPage } />} /> 
        <Route path="/organisation/:org_id/entity/:ent_id/settings" element={<PrivateRoute role={ ROLES.admin } element={ EntitySettingsPage } />} /> 
        <Route path="/organisation/:org_id/entity/:ent_id/distributor/invite" element={<PrivateRoute role={ ROLES.admin } element={ DistributorsInvite } />} /> 
        
        {/* Distributor Private ROutes */}
        <Route path="/distributor/create" element={<PrivateRoute role={ ROLES.admin } element={ DistributorsCreatePage } />} />
        <Route path="/distributor/invite" element={<PrivateRoute role={ ROLES.admin } element={ DistributorsInvite } />} />
        <Route path="/distributor/:id" element={<PrivateRoute role={ ROLES.admin } element={ DistributorDetailsPage } />} />
        <Route path="/distributor/:id/edit" element={<PrivateRoute role={ ROLES.admin } element={ DistributorEditPage } />} />
        <Route path="/admin/invite" element={<PrivateRoute role={ ROLES.admin } element={ AdminsInvite } />} />
        <Route path="/admins" element={<PrivateRoute role={ ROLES.admin } element={ Admins } />} />
        <Route path="/aggregated_data" element={<PrivateRoute role={ ROLES.admin } element={ () => <AggregatedData game={Games.LN.shortName} /> } />} />
        <Route path="/cots/aggregated_data" element={<PrivateRoute role={ ROLES.admin } element={ () => <AggregatedData  game={Games.COTS.shortName} /> } />} />
        <Route path="/mhsds_data" element={<PrivateRoute role={ ROLES.admin } element={ () => <MHSDSAggregatedData role={ROLES.admin}/> } />} />

        
        <Route path="/game_choice" element={<PrivateRoute role={ ROLES.distributor } element={ GameChoice } />} />
        <Route path="/ln" element={<PrivateRoute role={ ROLES.distributor } element={ LnYoungPeoplePage } />} />
        <Route path="/ln/dashboard" element={<PrivateRoute role={ ROLES.distributor } element={ Dashboard } />} />
        <Route path="/ln/young_persons" element={<PrivateRoute role={ ROLES.distributor } element={ LnYoungPeoplePage } />} />
        <Route path="/ln/young_person/create" element={<PrivateRoute role={ ROLES.distributor } element={ LnCreateYoungPerson } />} />
        <Route path="/ln/young_person/confirm" element={<PrivateRoute role={ ROLES.distributor } element={ LnConfirmationScreen } />} />
        <Route path="/ln/young_person/:id/edit" element={<PrivateRoute role={ ROLES.distributor } element={ LnEditYoungPerson } />} />
        <Route path="/ln/young_person/:id/report" element={<PrivateRoute role={ ROLES.distributor } element={ YoungPersonReport } />} />
        <Route path="/ln/faqs" element={<PrivateRoute role={ ROLES.distributor } element={ FAQs } />} />
        <Route path="/ln/resources" element={<PrivateRoute role={ ROLES.distributor } element={ () => <Resources  prefix={'ln'} />  } />} />
        <Route path="/ln/survey_results/:id" element={<PrivateRoute role={ ROLES.distributor } element={ GuardianSurvey } />} />

        <Route path="/cots" element={<PrivateRoute role={ ROLES.distributor } element={ CotsYoungPeoplePage } />} />
        <Route path="/cots/dashboard" element={<PrivateRoute role={ ROLES.distributor } element={ CotsDashboard } />} />
        <Route path="/cots/young_persons" element={<PrivateRoute role={ ROLES.distributor } element={ CotsYoungPeoplePage } />} />
        <Route path="/cots/young_person/create" element={<PrivateRoute role={ ROLES.distributor } element={ CotsCreateYoungPerson } />} />
        <Route path="/cots/young_person/confirm" element={<PrivateRoute role={ ROLES.distributor } element={ CotsConfirmationScreen } />} />
        <Route path="/cots/young_person/:id/edit" element={<PrivateRoute role={ ROLES.distributor } element={ CotsEditYoungPerson } />} />
        <Route path="/cots/young_person/:id/report" element={<PrivateRoute role={ ROLES.distributor } element={ CotsYoungPersonReport } />} />
        <Route path="/cots/faqs" element={<PrivateRoute role={ ROLES.distributor } element={ CotsFAQs } />} />
        <Route path="/cots/resources" element={<PrivateRoute role={ ROLES.distributor } element={ () => <Resources prefix={'cots'} /> } />} />

        <Route path="/point_person/dashboard" element={<PrivateRoute role={ ROLES.point_person } element={ PPDashboardLN } />} />
        <Route path="/point_person/reports" element={<PrivateRoute role={ ROLES.point_person } element={ MonthlyReports } />} />
        <Route path="/point_person/mhsds_data" element={<PrivateRoute role={ ROLES.point_person } element={ () => <MHSDSAggregatedData role={ROLES.point_person} /> } />} />

        {/* Sign up routes */}
        <Route path="/signup/:keycode" element={ <PostCodeValidation /> }/>
        <Route path="/signup/:keycode/create_account" element={ <LnSelfSignUpYoungPerson /> } />

        <Route path="/signup/:keycode/success" element={ <SuccessPage /> } />

        <Route path="/create_account" element={ <InvitationCreationPage /> } />
        
        <Route path="/login" element={<LoginPage isPointPersonLogin={false} /> }/>
        <Route path="/reports" element={ <LoginPage isPointPersonLogin={true} /> }/>

        <Route path='/reset_password' element={ <ResetPassword /> } />
        
        <Route path="/forgot_password" element={ <ForgotPassword url={'/forgotten_password'}/> } />
        <Route path="/point_person/forgot_password" element={ <ForgotPassword url={'/point_person/forgotten_password'}/> } />

        {/*  Replace with 404 page  */ }
        <Route path={ maintenancePath } element={ <MaintenancePage /> } />
        <Route path="*" element={ <LoginRedirect /> } />
      </Routes>
    </Container>
  );
}

export default Content;
