import React from 'react';
import * as PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { Grid2 as Grid } from '@mui/material';

import { Email, Language, Phone } from '@mui/icons-material'
import { getChain } from '../services/helpers';
import { IconLabel } from './SuccessPage';
import { Trans, useTranslation } from 'react-i18next';
import { translationKey } from '../utilities/localisation/translationKeys';
import { StyledErrorContainerBase, StyledErrorContentBase, StyledErrorLinkBase, StyledTitleTypographyBase } from '../StyledComponents';

export default function Error({ message, style }) {
  if (!message) {
    return null;
  } else if (!Array.isArray(message)) {
    message = [message];
  }

  return message && (
    <div style={ style }>
      { message.map((each, index) => <Typography key={ index } style={ { color: 'red' } }>{ each }</Typography>) }
    </div>
  );
}

Error.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

export function ErrorPage({ entitySettings, signUpUrlError }) {
  const { t } = useTranslation();

  const customSupportInfo = getChain(entitySettings, 'custom_support_info');

  return (
    <Grid style={ { display: 'grid', placeItems: 'center', margin: '3rem 0', minHeight: 'calc(100vh - 23em)' } }>
      <div>
          {
            !signUpUrlError &&
        <Grid container justify="center">
            <StyledTitleTypographyBase component="h1" variant="h5">
              {t(translationKey.TitleSorryProblem)}
            </StyledTitleTypographyBase>
        </Grid>
          }
        { customSupportInfo && <CustomSupportInfo customSupportInfo={ customSupportInfo } /> }

        { signUpUrlError && <SignUpError signUpUrlError={ signUpUrlError } /> }

        <br/>
        <Typography style={ { textAlign: 'left' } }>
          <Trans i18nKey={translationKey.LabelPleaseVisitOfficialLumiNova}
                 // eslint-disable-next-line
                 components={[<StyledErrorLinkBase href="https://luminova.app/" target="_blank" rel="noopener noreferrer"/> ]} />
        </Typography>
      </div>
    </Grid>
  );
}

export function CustomSupportInfo({ customSupportInfo }) {
  const { t } = useTranslation();

  const email = getChain(customSupportInfo, 'email');
  const phone = getChain(customSupportInfo, 'phone');
  const website = getChain(customSupportInfo, 'website');
  return (
    <StyledErrorContainerBase container justify="center">
      <Typography component="p" variant="h6" style={ { textAlign: 'left', fontSize: '1rem' } }>
        {t(translationKey.LabelForMoreInformationContact)}
      </Typography>
      <br />
      { email &&
      <IconLabel icon={ <Email /> }>
        { email }
      </IconLabel>
      }
      { phone &&
      <IconLabel icon={ <Phone /> }>
        { phone }
      </IconLabel>
      }
      { website &&
      <IconLabel icon={ <Language /> }>
        { website }
      </IconLabel>
      }
    </StyledErrorContainerBase>
  );
}

export function SignUpError({ signUpUrlError }) {

  const errorResponse = getChain(signUpUrlError, 'response', 'data');

  return (
    <Grid container style={ { display: 'block', textAlign: 'center' } }>
      <StyledTitleTypographyBase component="h3" variant="h6">
        { errorResponse.title }
      </StyledTitleTypographyBase>
      <StyledErrorContentBase component="p" variant="h6" >
        { errorResponse.body }
      </StyledErrorContentBase>
    </Grid>
  )
}
