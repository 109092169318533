import Typography from '@mui/material/Typography';
import React from 'react';
import { styled } from '@mui/styles';
import PropTypes from 'prop-types';
import { StyledHelperText } from '../StyledComponents';

const StyledDetailedSection = styled(Typography)(({ theme }) => ({
  marginTop: '2rem',
  fontWeight: 'bold',
}))

export function Title({ children }) {

  return (
    <StyledDetailedSection component="h1" align="center" variant="body1">
      { children }
    </StyledDetailedSection>
  )
}

Title.propTypes = {
  children: PropTypes.string,
};

export function HelperText({ children }) {

  return (
    <StyledHelperText>
      { children }
    </StyledHelperText>
  )
}

export function InstructionTitle({ children, part }) {

  return (
    <StyledDetailedSection component="h4" align="left" variant="body1">
      Part { part }: { children }
    </StyledDetailedSection>
  )
}

InstructionTitle.propTypes = {
  children: PropTypes.string,
  part: PropTypes.number,
};