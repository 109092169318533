import React, { useEffect, useState } from 'react';
import { Button, Container, IconButton, Paper, Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { tableThemer } from '../../themes';
import { axios } from '../../services/networkRequest';
import { genericAlert, getChain } from '../../services/helpers';
import { activeColor, greyBorderColor, listBackgroundColor } from '../../Styles';

import 'react-confirm-alert/src/react-confirm-alert.css';
import { CloudDownload } from '@mui/icons-material';
import { Games } from '../../constants';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';
import fileDownload from 'js-file-download';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';


function AggregatedData(props) {
  const [files, setFiles] = useState();
  const { t } = useTranslation();
  const url = props.game === Games.LN.shortName ? '/historic_data' : '/cots/historic_data';
  const postUrl = props.game === Games.LN.shortName ? '/aggregate_data' : '/cots/aggregate_data';
  const hubUsageUrl = '/aggregate_data/hub_usage';

  function downloadXLSX(filename) {
    axios.getFile(`${url}/${ filename }`, filename);
  }

  const columns = [
    {
      header: t(translationKey.TitleFilename),
      accessorKey: 'file',
      cell: rowData => <Typography color="textPrimary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.TitleLastModified),
      accessorFn: rowData =>  rowData.last_modified && new Date(rowData.last_modified).toDateString(),
      cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.TitleFileSize),
      accessorKey: 'size',
      cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>
    },
    {

      header: "Actions",
      cell: ({ row }) => (
        <IconButton onClick={() => downloadXLSX(row.original.file)}>
          <CloudDownload />
        </IconButton>
      ),
    },
  ];

  const table = useReactTable({
    data: files ?? [],
    columns,
    getCoreRowModel: getCoreRowModel()
  })

  useEffect(() => {
    axios.get(url)
      .then(({ data }) => {
        setFiles(getChain(data, 'files'));
      });
  }, [url]);

  const downloadhubUsageCSV = () => {
    // This function downloads Hub Usage Data in CSV.
    axios.get(hubUsageUrl, {
      headers: {
        'Accept': 'text/csv',
        responseType: 'arraybuffer',
      },
    })
      .then(res => {
        let now = new Date()
  
        fileDownload(res.data, `${ now.getDate() }_${ now.getMonth() + 1 }_${ now.getFullYear() }_hub_usage_data.csv`, res.headers['content-type']);
      })
  }

  return (
    <Container component="main" maxWidth="lg">
      <Button mb={ 10 } align="right" variant="contained" color="primary"
              onClick={ () => genericAlert( t(translationKey.AlertTitleStartProcessing),
                t(translationKey.AlertBodyStartProcessing),
                () => {
                  axios.post(postUrl);
                })
              }>
        {t(translationKey.ButtonGetUpdatedData)}
      </Button>
      {
        props.game === Games.LN.shortName && 
        <Button mb={ 10 } align="right" variant="contained" color="primary"
          onClick={ () => {downloadhubUsageCSV()}} style={{marginLeft: '20px'}}>
          {t(translationKey.ButtonGetHubUsageData)}
        </Button>
      }
      <br />
      <br />
      <ThemeProvider theme={ tableThemer(files) }>
        <Paper>
          <TableContainer>
            <Table>
              <TableHead style={{ backgroundColor: listBackgroundColor }}>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <TableCell key={header.id}>
                        {flexRender(header.column.columnDef.header, header.getContext()) }
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody>
                {table.getRowModel().rows.map((row, index) => (
                  <React.Fragment key={row.id}>
                    <TableRow
                      style={{ cursor: 'pointer', borderBottom: `1px solid ${greyBorderColor}`, borderLeft: `20px solid ${ activeColor }`, borderRight: `1px solid ${ greyBorderColor }`, borderTop: `1px solid ${ greyBorderColor }`, background: "#FFF"}} 
                    >
                      {row.getVisibleCells().map((cell) => (
                        <TableCell key={cell.id} style={{ padding: '10px' }}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </TableCell>
                      ))}
                    </TableRow>
                    {/* Spacer Row */}
                    {index !== table.getRowModel().rows.length - 1 && (
                      <TableRow sx={{ height: "10px", backgroundColor: listBackgroundColor }}>
                        <TableCell colSpan={columns.length} sx={{padding: 0, height: "10px"}}/>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </ThemeProvider>
    </Container>
  );
}

export default AggregatedData;
