import React, { useCallback, useEffect } from 'react';
import { Container, Grid2 as Grid, Typography, CssBaseline } from '@mui/material';
import { styled } from '@mui/styles';
import { Games } from '../../constants';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { pickGame } from '../../store/UserSlice';

const GlobalStyles = styled('div')(({ theme }) => ({
  body: {
    backgroundColor: theme.palette.common.white,
  },
}));

const StyledGameLogo = styled('img')(({ theme }) => ({
  marginTop: '5vh',
  width: '15vw',
  cursor: 'pointer',
}))

const StyledLogo = styled('img')(({ theme }) => ({
  position: 'absolute',
  left: 32,
  width: '100px',
  height: 'auto',
}));

const GameChoice = (props) => {

  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const [hasRedirected, setHasRedirected] = useState(false);

  const redirect = useCallback((game) => {
    dispatch(pickGame(game.shortName))
    navigate("/"+game.shortName)
  }, [dispatch, navigate])
  

  useEffect(() => {
    if(user && user.games && user.games.length === 1) {
      const selectedGame = user.games[0];
      
      if(selectedGame === 'cots') {
        redirect(Games.COTS);
      } 
      else if(selectedGame === 'ln') {
        redirect(Games.LN);
      }
    }
  }, [user, redirect])

  return (
    <Container component="main" maxWidth="lg">
      <CssBaseline />
      <GlobalStyles />
      <StyledLogo src='/BfB_logo_blue.png' alt='logo' />

      <Grid container spacing={ 3 }>
        <Grid size={{ xs: 12 }}><Typography color="textPrimary" align="center" variant='h5' style={ {
          fontWeight: 600,
          marginTop: '100px',
          color: '#043494',
          fontSize: '32px',
        } }>
          {t(translationKey.TitleWelcomePlayer, { player: user.name})}</Typography></Grid>
        <Grid size={{ xs: 12 }}><Typography align="center" variant='h5' style={ { fontWeight: 400 } }>
          {t(translationKey.TitleChooseAnIntervention)}</Typography></Grid>

        <Grid size={{ xs: 2 }} />
        <Grid size={{ xs: 4 }}>
          <StyledGameLogo src={ Games.COTS.logo }
               alt={t(translationKey.AltGameLogo, { game: Games.COTS.name})}
               onClick={ () => redirect(Games.COTS) } />
        </Grid>
        <Grid size={{ xs: 4 }}>
          <StyledGameLogo src={ Games.LN.logo }
               alt={t(translationKey.AltGameLogo, { game: Games.LN.name})}
               onClick={ () => {redirect(Games.LN)} } />
        </Grid>
        <Grid size={{ xs: 2 }} />

        <Grid size={{ xs: 2 }} />
        <Grid size={{ xs: 4 }} onClick={ () => redirect(Games.COTS) }><Typography color="textPrimary" align="center"
                                                                               variant='h5'
                                                                               style={ { cursor: 'pointer' } }>
          {t(translationKey.LabelChampionsOfTheShengha)}</Typography>
          <Typography color="textSecondary" align="center" variant='h5'
                      style={ { fontSize: '18px', cursor: 'pointer' } }>
            {t(translationKey.TitleForMindfulBreathing)}</Typography></Grid>
        <Grid size={{ xs: 4 }} onClick={ () => redirect(Games.LN) }><Typography color="textPrimary" align="center"
                                                                             variant='h5'
                                                                             style={ { cursor: 'pointer' } }>
          {t(translationKey.TitleLumiNovaTalesOfCourage)}</Typography>
          <Typography color="textSecondary" align="center" variant='h5'
                      style={ { fontSize: '18px', cursor: 'pointer' } }>
            {t(translationKey.TitleToHelpWorries)}</Typography></Grid>
        <Grid size={{ xs: 2 }} />
      </Grid>
    </Container>
  );
}

export default GameChoice;