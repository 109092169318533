import React from 'react';
import { styled } from '@mui/styles';
import { VictoryAxis, VictoryLine, VictoryScatter, VictoryChart, VictoryTheme } from 'victory';
import { withSize } from 'react-sizeme';
import { barColor } from '../../Styles';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';
import { StyledCentreCircularProgress, StyledDivRoot } from '../../StyledComponents';

const StyledDiv = styled(StyledDivRoot)(({ theme }) => ({
  height: 230,
  width: 600,
  paddingBottom: 30
}))

const GBOResults = (props) => {
  const { t } = useTranslation();

  if (!props.responses) {
    return <StyledDiv>
      <StyledCentreCircularProgress />
    </StyledDiv>;
  }

  const chartData = props.responses.map((item, index) => {
    // Axis label hackery from first item date to avoid keeping lookup tables
    // This comes in UK display format, but that's not how we want it any more.
    const tempParts = item.date.split('/');
    const tempString = tempParts[1] + '/' + tempParts[0] + '/' + tempParts[2];
    const date = new Date(tempString);
    const dateString = date.toDateString();
    const parts = dateString.split(' ');
    const axisLabel = parts[2] + ' ' + parts[1];
    return {
        day: index + 1,
        axisLabel,
        response: item.response
    }
  });

  const dayValues = chartData.map(item => item.day);
  const labels = chartData.map(item => item.axisLabel);
  let tickValues = [0, 2, 4, 6, 8, 10];

  return (
    <StyledDiv>
      <VictoryChart
        height={ 270 }
        width={ props.size.width }
        domainPadding={ 16 }
        theme={ VictoryTheme.material }
      >
        <VictoryAxis crossAxis
                     tickValues={ dayValues }
                     tickFormat={ labels }
                     label={t(translationKey.LabelDate)}
                     style={ { axisLabel: { padding: 35 }, grid: { stroke: 'none' } } }
        />
        <VictoryAxis dependentAxis crossAxis
                     tickValues={tickValues}
                     label={t(translationKey.LabelGboScore)}
                     style={ { axisLabel: { padding: 35 } } }
        />
        <VictoryLine
          tickValues={ dayValues }
          style={ { data: { stroke: barColor } } }
          data={ chartData }
          x={t(translationKey.LabelDay)}
          y={t(translationKey.LabelResponse)}
        />
        <VictoryScatter
          tickValues={ dayValues }
          style={ { data: { fill: barColor } } }
          data={ chartData }
          x={t(translationKey.LabelDay)}
          y={t(translationKey.LabelResponse)}
        />
      </VictoryChart>
    </StyledDiv>
  );
};

export default withSize()(GBOResults);
