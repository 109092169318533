import React from 'react';
import YoungPersonForm from './YoungPersonForm';
import { getChain } from '../../../services/helpers';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../../utilities/localisation/translationKeys';
import { axios } from '../../../services/networkRequest';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const CreateYoungPersonPage = ({ youngPerson, ...props }) => {
  const user = useSelector((state) => state.user.user);
  const navigate = useNavigate();
  const [entitySettings, setEntitySettings] = React.useState(undefined);
  React.useEffect(() => {
    axios.get(`/entity/${user.entity_id}/settings`).then(({data}) => {
      data && data && data.self_sign_up_settings.distributor_id === user.id && setEntitySettings(data);
    })
  }, [user])
  const id = getChain(youngPerson, 'id');
  const { t } = useTranslation();

  const onSubmitForm = (values) => {
    if (youngPerson && !id) {
      navigate('/ln/young_persons', {replace: true});
    } else {
      navigate('/ln/young_person/confirm', {state: values});
    }

    return Promise.resolve();
  }

  return (
    <YoungPersonForm
      title={t(translationKey.LabelCreateYoungProfile)}
      onSubmitForm={ onSubmitForm }
      youngPerson={ youngPerson }
      entitySettings={entitySettings}
      { ...props }
    />
  )
};

export default CreateYoungPersonPage;
