import React, { useState } from 'react';
import { styled } from '@mui/styles';
import { Button, Card, CardContent, Container, Grid2 as Grid, Typography } from '@mui/material';
import ExitButton from '../../ExitButton';
import Footer from '../../content/Footer';
import { axios } from '../../../services/networkRequest';
import {
  genericAlert,
  getChain,
} from '../../../services/helpers';
import Error from '../../Error';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../../utilities/localisation/translationKeys';
import { useLocation, useNavigate } from 'react-router-dom';

const StyledBottomBorder = styled(Grid)(({ theme }) => ({
  borderBottom: '1px solid #ccc',
  justifyContent: 'space-between',
}));

const StyledTopBorder = styled(Grid)(({ theme }) => ({
  borderTop: '1px solid #ccc',
  marginTop: '75px',
  paddingTop: '20px',
  justifyContent: 'center',
}));

const StyledCard = styled(Card)(({ theme }) => ({
  minWidth: 275,
}));

const StyledText = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  paddingTop: '75px',
}));

const StyledTextSpace = styled(Typography)(({ theme }) => ({
  paddingTop: '20px',
}));

const StyledBackButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#818181',
  color: 'white',
  '&:hover': {
    backgroundColor: '#676767  '
  }
}));

export default function ConfirmationScreen(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const values = location.state;
  const [error, setError] = useState();
  const { t } = useTranslation();

  return (
    <Container component="main" maxWidth="lg">
      <StyledCard>
        <CardContent>
          <StyledBottomBorder container direction="row" justify="space-between" alignItems="center">
            <Grid size={{ xs: 3 }}>
              <Typography component="h6" variant="body1" align="left">{t(translationKey.LabelCreateYoungProfile)}</Typography>
            </Grid>
            <ExitButton />
          </StyledBottomBorder>
          <StyledText container direction='column' alignItems='center' justify='center'>
            <Grid size={{ xs: 6 }}>
              <Typography component="h1" align="center" variant="body1">
                {t(translationKey.TitleConfirmYoungProfile)}
              </Typography>
            </Grid>
            <Grid size={{ xs: 6 }}>
              <StyledTextSpace component="h4" align="center" variant="body1"  style={{ fontWeight: "normal" }}>
                {t(translationKey.LabelSmsToGuardian, { number : values.guardian.telephone })}
              </StyledTextSpace>
            </Grid>
            <Grid size={{ xs: 7 }}>
              <StyledTextSpace component="h4" align="center" variant="body1" style={{ fontWeight: "normal" }}>
                {t(translationKey.LabelConfirmSendSms)}
              </StyledTextSpace>
            </Grid>

            <Error style={ { marginTop: '32px' } } message={error}></Error>

          </StyledText>


          <StyledTopBorder container spacing={ 2 } justify='center'>
            <Grid size={{ xs: 2 }}>
              <Button fullWidth variant="contained" color="primary" onClick={ () => {
                axios.post('/ln/invite/guardian', values)
                    .then(() => {
                      navigate('/ln/young_persons', {
                        state: {newYoungPerson: true},
                        replace: true
                      });
                    })
                    .catch(err => {
                    if (getChain(err, 'response', 'status') === 402) {
                      const { title, body: message } = getChain(err, 'response', 'data');
                      genericAlert({
                        title,
                        message,
                        negativeTitle: t(translationKey.TitleBack),
                      });
                    } else {
                      setError(`Error: ${ err.response.data['error'] }`);
                    }
                  });
              } }>
                {t(translationKey.ButtonTitleSendInvite)}
              </Button>
            </Grid>

            <Grid size={{ xs: 2 }}>
              <StyledBackButton fullWidth variant="contained" onClick={ () => {
                navigate('/ln/young_person/create', {state: values});
              } }>
                { t(translationKey.ButtonTitleBack).toUpperCase()}
              </StyledBackButton>
            </Grid>

          </StyledTopBorder>
        </CardContent>
      </StyledCard>
      <Footer />
    </Container>
  );
}
