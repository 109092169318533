import React from 'react';
import { styled } from '@mui/styles';
import { CircularProgress, Grid2 } from '@mui/material';


const StyledLoadingSpinnerContainer = styled(Grid2)(({ theme }) => ({
  display: 'grid',
  placeItems: 'center',
  width: '100%',
  height: '100vh',
  background: '#fff'
}))

export function LoadingSpinnerFullScreen() {

  return (
    <StyledLoadingSpinnerContainer>
      <CircularProgress />
    </StyledLoadingSpinnerContainer>
  )
}