import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/styles';
import Container from '@mui/material/Container';
import { axios, catchAxios } from '../../services/networkRequest';
import { Formik } from 'formik';
import { confirmAlert } from 'react-confirm-alert';
import queryString from 'query-string';
import Error from '../Error';
import RequiredInfoText from '../RequiredInfoText';
import { helperTextColor } from '../../constants';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';
import { useLocation, useNavigate } from 'react-router-dom';
import { StyledSubmitButton } from '../../StyledComponents';

const StyledBackground = styled(Container)(({ theme }) => ({
  width: '100vw',
}));

const StyledLogo = styled('img')(({ theme }) => ({
  position: 'absolute',
  left: 32,
  width: '100px',
  height: 'auto',
}));

const StyledPaper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const GlobalStyles = styled('div')(({ theme }) => ({
  body: {
    backgroundColor: theme.palette.common.white,
  },
}));

function ResetPassword(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <StyledBackground component="main" maxWidth="xs">
      <StyledLogo src='/BfB_logo_blue.png' alt='logo'/>
      <CssBaseline />
      <GlobalStyles />
      <StyledPaper>
        <Typography component="h1" variant="h5">
          {t(translationKey.TitleResetPassword)}
        </Typography>
        <RequiredInfoText />
        <Formik
          initialValues={ { password: '', confirmation: '' } }
          validate={ values => {
            const errors = {};
            const min_length = 10;
            if (!values.password) {
              errors.password = t(translationKey.ErrorEnterPassword);
            } else if (values.password.length < min_length) {
              errors.password = (t(translationKey.ErrorMinimumPassword, { min_length: min_length }));
            }
            if (!values.confirmation) {
              errors.confirmation = t(translationKey.ErrorConfirmPassword);
            } else if (values.password !== values.confirmation) {
              errors.confirmation = t(translationKey.ErrorPasswordMatch);
            }
            return errors;
          } }
          onSubmit={ ({ password, confirmation }, { setSubmitting, setFieldError }) => {
            setSubmitting(true);
            const values = queryString.parse(location.search);
            const secret = values.s;
            axios.post('/reset_password', { password, confirmation, secret }).then(() => {
              confirmAlert({
                title: t(translationKey.AlertTitleSuccess),
                message: t(translationKey.AlertBodyPasswordReset),
                buttons: [
                  {
                    label: t(translationKey.LabelOk),
                    onClick: () => {
                      navigate('/');
                    },
                  },
                ],
              });
            })
              .catch(err => {
                catchAxios(setFieldError)(err);
                setSubmitting(false);
              });
          } }
        >
          { ({
               values,
               errors,
               touched,
               handleChange,
               handleBlur,
               handleSubmit,
               isSubmitting,
             }) => (
            <form onSubmit={ handleSubmit }>
              <Error message={ errors['network'] } />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label={t(translationKey.LabelPassword)}
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={ handleChange }
                onBlur={ handleBlur }
                value={ values.password }
                helperText={ errors.password && touched.password && errors.password }
                slotProps={{ formHelperText: { style: { color: helperTextColor } } }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="confirmation"
                label={t(translationKey.LabelConfirmPassword)}
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={ handleChange }
                onBlur={ handleBlur }
                value={ values.confirmation }
                helperText={ errors.confirmation && touched.confirmation && errors.confirmation }
                slotProps={{ formHelperText: { style: { color: helperTextColor } } }}
              />
              <StyledSubmitButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={ isSubmitting }
              >
                {t(translationKey.ButtonSubmit)}
              </StyledSubmitButton>
            </form>
          ) }
        </Formik>
      </StyledPaper>
    </StyledBackground>
  );
}

export default ResetPassword;
