import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { axios } from '../../services/networkRequest';
import { ThemeProvider } from '@mui/material/styles';
import { tableThemer } from '../../themes';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { activeColor, greyBorderColor, listBackgroundColor } from '../../Styles';
import { translationKey } from '../../utilities/localisation/translationKeys';
import { useTranslation } from 'react-i18next';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';

const MonthlyReports = (props) => {
  const [reports, setReports] = useState([]);
  const { t } = useTranslation();

  const columns = [
    {
      id: t(translationKey.TitleFilename),
      header: t(translationKey.TitleFilename),
      accessorFn: rowData => rowData.file_name && rowData.file_name.split('/').pop(),
      cell: rowData => <Typography color="textPrimary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.TitleCreated),
      accessorFn: rowData =>  rowData.created_at && new Date(rowData.created_at).toDateString(),
      cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.TitleFileSize),
      accessorKey: "file_size",
      cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>
    },
    {
      header: "Actions",
      cell: ({ row }) => <IconButton disableRipple onClick={() => downloadReport(row.original)}><CloudDownloadIcon /></IconButton>
    }
  ]

  const table = useReactTable({
    data: reports ?? [],
    columns,
    getCoreRowModel: getCoreRowModel()
  });

  const loadData = () => {
    axios.get('point_person/reports')
      .then(reports => {
          setReports(reports.data)
      })
      .catch(error => {
        console.log(error);
      });
  }

  useEffect(() => {
    loadData();
  }, []);

  const downloadReport = (report) => {
    axios.getFile(`point_person/report/${ report.file_name }`, report.file_name);
  }

  return (
    <Container component="main" maxWidth="lg">
          <Typography color="textPrimary" align="left" variant='h5'
                                        style={ { fontWeight: 600, marginLeft: '10px' } }>{t(translationKey.TitleMonthlyReport)}</Typography>
        <ThemeProvider theme={ tableThemer(reports) }>
          <Paper>
            <TableContainer>
              <Table>
                <TableHead style={{ backgroundColor: listBackgroundColor }}>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <TableRow key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <TableCell key={header.id}>
                          {flexRender(header.column.columnDef.header, header.getContext()) }
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableHead>
                <TableBody>
                  {table.getRowModel().rows.map((row, index) => (
                    <React.Fragment key={row.id}>
                      <TableRow 
                        style={{ cursor: 'pointer', borderBottom: `1px solid ${greyBorderColor}`, borderLeft: `20px solid ${ activeColor }`, borderRight: `1px solid ${ greyBorderColor }`, borderTop: `1px solid ${ greyBorderColor }`}} 
                      >
                        {row.getVisibleCells().map((cell) => (
                          <TableCell key={cell.id} style={{ padding: '10px' }}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </TableCell>
                        ))}
                      </TableRow>
                      {/* Spacer Row */}
                      {index !== table.getRowModel().rows.length - 1 && (
                        <TableRow sx={{ height: "10px", backgroundColor: listBackgroundColor }}>
                          <TableCell colSpan={columns.length} sx={{padding: 0, height: "10px"}}/>
                        </TableRow>
                      )}
                    </React.Fragment>
                  ))
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </ThemeProvider>
      </Container>
  )
}

export default MonthlyReports;