import React, {useState, useEffect} from 'react';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import Button from '@mui/material/Button';
import { axios, catchAxios } from '../../services/networkRequest';
import queryString from 'query-string';
import { getChain, ROLES } from '../../services/helpers';
import { Formik } from 'formik';
import Error from '../Error';
import RequiredInfoText from '../RequiredInfoText';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { helperTextColor } from '../../constants';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';

const phoneUtil = PhoneNumberUtil.getInstance();

const InvitationCreationPage = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  // const [address, setAddress] = useState('');
  // const [phone, setPhone] = useState('');
  // const [password, setPassword] = useState('');
  const [role, setRole] = useState('');
  const [network, setNetwork] = useState(null);
  
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const searchObj = queryString.parse(location.search);
    axios.post('invite/validate', { code: searchObj.invite })
      .then(({ data }) => {
        const { first_name, last_name, email, role } = data;

        const fullName = [first_name, last_name].reduce((acc, each) => {
          if (each) acc.push(each);
          return acc;
        }, []).join(' ');

        setEmail(email);
        setRole(role);
        setName(fullName);
      })
      .catch(error => {
        const networkError = getChain(error, 'response', 'data', 'error') || error.message;
        setNetwork(networkError);
      });
  }, [location.search]);


  return (
    <Container 
      style={{ width: '100vw', position: 'relative', }} 
      component="main" 
      maxWidth="xs"
    >
      <img 
        src='/BfB_logo_blue.png' 
        alt='logo' 
        style={ {
          position: 'absolute',
          left: 0,
          width: '100px',
          height: 'auto',
      } } />
      <CssBaseline />
      <Typography component="h1" variant="h5">
        {t(translationKey.TitleCreateYourAccount)}
      </Typography>
      { email ? (<>
        <RequiredInfoText />
        <Formik
          initialValues={ {
            name: name,
            password: '',
            address: '',
            telephone: '',
          } }
          validate={ (values) => {
            const errors = {};
            const min_length = 10;
            if (!values.name) {
              errors.name = t(translationKey.ErrorSpecifyDistributorName);
            }
            if (!values.password) {
              errors.password = t(translationKey.ErrorPasswordRequired);
            } 
            else if (values.password.length < min_length) {
              errors.password = t(translationKey.ErrorMinimumPassword, { min_length });
            }
            if (role === ROLES.distributor) {
              if (!values.telephone) {
                errors.telephone = t(translationKey.ErrorSpecifyPhoneNumber);
              } 
              else {
                try {
                  const number = phoneUtil.parseAndKeepRawInput(values.telephone, 'GB');
                  if (!(phoneUtil.isPossibleNumber(number) && phoneUtil.isValidNumber(number))) {
                    errors.telephone = t(translationKey.ErrorValidPhoneNumber);
                  }
                }
                catch (err) {
                  errors.telephone = t(translationKey.ErrorValidPhoneNumber);
                }
              }
              if (!values.address) {
                errors.address = t(translationKey.ErrorSpecifyAddress);
              }
            }
            return errors;
          } }
          onSubmit={ (values , { setSubmitting, setFieldError }) => {
            setSubmitting(true);
            let searchObj = queryString.parse(location.search);
            if (role === ROLES.distributor) {
              // Convert phone number into international format
              const number = phoneUtil.parseAndKeepRawInput(values.telephone, 'GB');
              values.telephone = phoneUtil.format(number, PhoneNumberFormat.E164);
            }
            axios.post(`${ role }/create`, {
              ...values,
              code: searchObj.invite,
            })
              .then(({ data }) => {
                localStorage.setItem('user', JSON.stringify(data));
                navigate('/');
              })
              .catch(err => {
                catchAxios(setFieldError)(err);
                setSubmitting(false);
              });
          } }
        >
          { ({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
            <form>
              <Error message={ network || errors['network'] } />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                autoFocus={ !!role }
                type="text"
                name="name"
                id="name"
                onBlur={ handleBlur }
                value={ values.name }
                onChange={ handleChange }
                label={t(translationKey.LabelName)}
                placeholder={t(translationKey.LabelName)}
                required
                helperText={ errors.name && touched.name && errors.name }
                slotProps={{ formHelperText: { style: { color: helperTextColor } } }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                disabled={ true }
                type="email"
                name="email"
                id="email"
                onBlur={ handleBlur }
                value={ email }
                onChange={ handleChange }
                label={t(translationKey.LabelEmail)}
                placeholder={t(translationKey.LabelEmail)}
                required
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                type="password"
                name="password"
                id="password"
                onBlur={ handleBlur }
                value={ values.password }
                onChange={ handleChange }
                label={t(translationKey.LabelPassword)}
                placeholder={t(translationKey.LabelPassword)}
                required
                helperText={ errors.password && touched.password && errors.password }
                slotProps={{ formHelperText: { style: { color: helperTextColor } } }}
              />
              { role === ROLES.distributor &&
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                type="text"
                name="address"
                id="address"
                onBlur={ handleBlur }
                value={ values.address }
                onChange={ handleChange }
                label={t(translationKey.LabelWorkAddress)}
                placeholder={t(translationKey.LabelAddress)}
                required
                helperText={ errors.address && touched.address && errors.address }
                slotProps={{ formHelperText: { style: { color: helperTextColor } } }}
              /> }
              { role === ROLES.distributor &&
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                type="tel"
                name="telephone"
                id="telephone"
                onBlur={ handleBlur }
                value={ values.telephone }
                onChange={ handleChange }
                label={t(translationKey.LabelWorkPhone)}
                placeholder={t(translationKey.LabelTelephone)}
                required
                helperText={ errors.telephone && touched.telephone && errors.telephone }
                slotProps={{ formHelperText: { style: { color: helperTextColor } } }}
              /> }
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disabled={ isSubmitting }
                onClick={ handleSubmit }
              >
                {t(translationKey.ButtonSubmit)}
              </Button>
            </form>
          ) }
        </Formik>
      </>) : (
        <CircularProgress style={ { marginTop: '46px', marginBottom: '20px' } } /> 
      )}
      <Typography style={ { marginTop: '16px' } } color="textPrimary">
        {t(translationKey.LabelAlreadyHaveAnAccount)}
        <Link style={ { textDecoration: 'underline' } } to='/login'>
          {t(translationKey.LabelLogInHere)}
        </Link>
      </Typography>
    </Container>
  );
};

export default InvitationCreationPage;
