import React from 'react';
import * as PropTypes from 'prop-types';
import { Grid2 as Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';

export default function ExitButton({ path, confirmation, ...props }) {
  const navigate = useNavigate()
  const handleConfirmation = confirmation || ((callback) => callback());

  // if (!confirmation) {
  //   confirmation = callback => callback();
  // }

  const handleClick = () => {
    handleConfirmation(() => {
      if (path) {
        navigate(path);
      } else {
        navigate(-1);
      }
    });
  }
  return (
    <Grid { ...props } size={ {xs: 1} }>
      <IconButton aria-label="close" onClick={ handleClick }>
        <CloseIcon />
      </IconButton>
    </Grid>
  );
}

ExitButton.propTypes = {
  path: PropTypes.string,
  confirmation: PropTypes.func,
};