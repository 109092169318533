import React, { useState, useEffect, useCallback } from 'react';
import { Button, Grid2 as Grid, Typography, CssBaseline, TextField, Container, IconButton, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { axios } from '../../services/networkRequest';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { deleteAlert, deleteDistributorAlert, genericAlert, getChain, restoreAlert } from '../../services/helpers';
import { Delete, Settings } from '@mui/icons-material';
import { ThemeProvider } from '@mui/material/styles';

import { tableThemer } from '../../themes';
import DistributorList from '../Distributors/DistributorList';
import { activeColor, greyBorderColor, listBackgroundColor } from '../../Styles';
import fileDownload from 'js-file-download';
import { OrganisationAndPointPersonDetails } from './InfoDisplay/OrganisationAndPointPersonDetails';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { StyledSubGridCard } from '../../StyledComponents';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';

const ButtonState = ({ children }) => {
  const [file, setFile] = useState(undefined);

  return children(file, setFile);
}

const OrganisationsDetailsPage = (props) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const { org_id } = useParams();

  const [organisation, setOrganisation] = useState(
    getChain(location.state, 'organisation') || null
  );
  const [invitees, setInvitees] = useState([]);
  const [distributors, setDistributors] = useState([]);
  const [uploading, setUploading] = useState(false);

  const columns = [
      {
        accessorKey: "id",
        header: "#",
        cell: rowData => <Typography color="textPrimary" align={"left"}>{rowData.getValue()}</Typography>,
      },
      {
        header: t(translationKey.LabelEntityName),
        accessorKey: "name",
        cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
      },
      {
        header: t(translationKey.LabelEntityAddress),
        accessorKey: "address",
        cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
      },
      {
        header: t(translationKey.TitleCreatedAt),
        accessorFn: rowData =>  rowData.created_at && new Date(rowData.created_at).toDateString(),
        cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
      },
      {
        header: "Actions",
        cell: ({ row }) => (
          <IconButton onClick={() => deleteEntity(row.original)}>
            <Delete />
          </IconButton>
        ),
      },
    ];
  
  const table = useReactTable({
    data: organisation.entities || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });


  const selectEntity = ({ id }) => {
    axios.get(`/entity/${ id }`)
      .then(({ data }) => {
        navigate(`/organisation/${ org_id }/entity/${ id }`,
          {state:{data: { entity: data, organisation: organisation }}});
      });
  }

  const populateOrganisation = useCallback(() => {
    axios.get(`/organisation/${ org_id }/distributors`)
      .then(({ data }) => {
        setOrganisation((prevOrg) => ({
          ...prevOrg,
          distributors: data.distributors || [],
          invitees: data.invitees || []
        }));

        setDistributors(data.distributors || []);
        setInvitees(data.invitees || []);
      });

    axios.get(`/organisation/${ org_id }/entities`)
      .then(({ data }) => {
        setOrganisation(prevOrg => ({
          ...prevOrg,
          entities: data || []
        }))
      });
  }, [org_id]);

  useEffect(() => {
    if(!organisation) {
      if(!org_id) {
        navigate('/', {replace: true});
        return;
      }

      axios.get(`/organisation/${ org_id }`)
        .then(({ data }) => {
          setOrganisation(prevState => ({
            ...data,
            distributors: getChain(prevState, 'distributors'),
            invitees: getChain(prevState, 'invitees')
          }));
        })
        .then(populateOrganisation);
    }
    else {
      populateOrganisation()
    }
  }, [navigate, org_id, organisation, populateOrganisation])

  const deleteOrganisation = () => {
    deleteAlert(organisation.name, `/organisation/${ organisation.id }`, () => {
      navigate('/organisations');
    });
  }

  const organisationSettings = () => {
    navigate(`/organisation/${ organisation.id }/settings`, {state: { organisation: organisation }});
  }

  const deleteEntity = ({ id, name }) => {
    axios.get(`/entity/${ id }/delete_check`)
      .then(({ data }) => {
        const { distributors, active_users } = data;
        const message = active_users ? t(translationKey.MessageConfirmDeleteEntity) : distributors  ? t(translationKey.MessageDistributorsWillAlsoBeRemoved, { list: distributors }) : null;

        deleteAlert(name, `/entity/${ id }`, populateOrganisation, message);
      });
  }

  const deleteInvitee = ({ first_name, last_name, id }) => {

    const cleanedId = typeof id === 'string' && id.startsWith('i') 
      ? id.substring(1) 
      : id;

    deleteAlert(`${ first_name } ${ last_name }`, `/invite/distributor/${ cleanedId }`, populateOrganisation);
  }

  const deleteDistributor = ({ id, name, young_persons }) => {
    const cleanedId = typeof id === 'string' && id.startsWith('d') 
      ? id.substring(1) 
      : id;

    const sorted = young_persons.sorted;
    const activeYps = sorted.active.length;
    const notStartedYps = sorted.not_yet_active.length;
    const inactiveYps = sorted.inactive.length;
    deleteDistributorAlert(cleanedId, name, `/distributor/${ cleanedId }`, populateOrganisation, activeYps, notStartedYps, inactiveYps);
  }

  const restoreDistributor = ({ id, name }) => {
    const cleanedId = id.startsWith('d') ? id.substring(1) : id;

    restoreAlert(name, `/distributor/restore/${ cleanedId }`, populateOrganisation);
  }

  const editOrganisation = () => {
    navigate(`/organisation/${ organisation.id }/edit`, {state: { organisation: organisation }});
  }

  const handleInvite = (event) => {
    event.preventDefault()

    axios.post(`/organisation/${ organisation.id }/email_distributors`)
      .then(() => {
        confirmAlert({
          title: t(translationKey.AlertTitleInvitesSent),
          message: t(translationKey.AlertBodyInvitesSent),
          buttons: [
            {
              label: t(translationKey.LabelThanks),
            },
          ],
        });
      });
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!uploading) {
      setUploading(true)
      let formData = new FormData();
      let csvFile = document.querySelector('#csv');

      if (csvFile.files[0]) {
        formData.append('distributors', csvFile.files[0]);
        axios.put(`/organisation/${ organisation.id }/distributors`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then(() => {
            genericAlert(t(translationKey.AlertTitleDistributorsUploaded), t(translationKey.AlertBodyDistributorsUploaded, { file: csvFile.files[0].name }));
          })
          .catch(error => {
            let errorMessage = getChain(error, 'response', 'data', 'error') || t(translationKey.ErrorUnknown);
            genericAlert(t(translationKey.AlertTitleDistributorUploadFail), t(translationKey.AlertBodyDistributorUploadFail, { error: errorMessage }));
          })
          .finally(() => {
            setUploading(false);
          });
      }
      else {
        alert(t(translationKey.AlertNoFile));
      }
    }
  }

  const downloadCSV = () => {
    axios.get(`/organisation/${ organisation.id }/distributors`, {
      headers: {
        'Accept': 'text/csv',
        responseType: 'arraybuffer',
      },
    })
      .then(res => {
        fileDownload(res.data, 'distributors.csv', res.headers['content-type']);
      });
  }

  const { entities = [], invitees: orgInvitees = [] } = organisation || {};


  return (
    <div>
      <Grid container style={ { margin: '1rem 0' } }>
        <Grid size={{ xs: 12 }}>
          <StyledSubGridCard>
            <Button onClick={ editOrganisation } color='default'>
              <EditIcon />
            </Button>
            <Button onClick={ deleteOrganisation } color='default'>
              <DeleteIcon />
            </Button>
            <Button onClick={ organisationSettings } color='default'>
              <Settings />
            </Button>
          </StyledSubGridCard>
        </Grid>
      </Grid>
      <OrganisationAndPointPersonDetails organisation={ organisation } />
      <br />
      <br />
      <div>
        <br />
        { entities && entities.length > 0 && (<Container component="main" maxWidth="xs">
          <CssBaseline />
          <Button disabled={ !orgInvitees.length } variant="contained" onClick={ handleInvite }>{t(translationKey.ButtonSendInvites)}</Button>
          <br /><br /><br />
          <Typography component="h1" variant="h5">
            {t(translationKey.LabelUploadDistributors)}
          </Typography>
          <form>
            <ButtonState>
              {
                (file, setFile) => <>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    type="file"
                    fullWidth
                    name="csv"
                    id="csv"
                    onChange={ event => setFile(event.target.value) }
                    required />
                  <Button
                    disabled={ !file || uploading }
                    fullWidth
                    variant="contained"
                    color="primary"
                    type='submit'
                    onClick={ handleSubmit }>
                    {t(translationKey.ButtonSubmit)}
                  </Button>
                </>
              }
            </ButtonState>
          </form>
          <br />
          <Typography component="h1" variant="h5">
            {t(translationKey.LabelDownloadDistributors)}
          </Typography>
          <Button 
            fullWidth
            variant="contained"
            color="primary"
            onClick={ downloadCSV }
          >
            {t(translationKey.ButtonDownloadFile)}
          </Button>
        </Container>) }

        <br />
        <Button
          variant="contained"
          color="primary"
          onClick={ () => navigate(`/organisation/${ organisation.id }/entity/create`,
            {state: { organisation: organisation }}) }>
          {t(translationKey.ButtonCreateEntity)}
        </Button>
        <br /><br />

        <ThemeProvider theme={ tableThemer(entities) }>
          <Paper>
            <TableContainer>
              <Table>
                <TableHead style={{ backgroundColor: listBackgroundColor }}>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <TableRow key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <TableCell key={header.id}>
                          {flexRender(header.column.columnDef.header, header.getContext()) }
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableHead>
                <TableBody>
                  {table.getRowModel().rows.map((row, index) => (
                    <React.Fragment key={row.id}>
                      <TableRow 
                        onClick={() => selectEntity(row.original)}
                        style={{ cursor: 'pointer', borderBottom: `1px solid ${greyBorderColor}`, borderLeft: `20px solid ${ activeColor }`, borderRight: `1px solid ${ greyBorderColor }`, borderTop: `1px solid ${ greyBorderColor }`}} 
                      >
                        {row.getVisibleCells().map((cell) => (
                          <TableCell key={cell.id} style={{ padding: '10px' }}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </TableCell>
                        ))}
                      </TableRow>
                      {/* Spacer Row */}
                      {index !== table.getRowModel().rows.length - 1 && (
                        <TableRow sx={{ height: "10px", backgroundColor: listBackgroundColor }}>
                          <TableCell colSpan={columns.length} sx={{padding: 0, height: "10px"}}/>
                        </TableRow>
                      )}
                    </React.Fragment>
                  ))}
                  {table.getRowModel().rows.length === 0 && (
                    <TableRow
                      style={{ border: `1px solid ${greyBorderColor}`}} 
                    >
                      <TableCell colSpan={columns.length} >
                        <Typography color="textSecondary" align={"center"}>No records to display</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </ThemeProvider>
        <DistributorList
          distributors={ distributors || [] }
          invitees={ invitees || [] }
          deleteDistributor={ deleteDistributor }
          restoreDistributor={ restoreDistributor }
          deleteInvitee={ deleteInvitee }
          clickDistributor={ (event, rowData) => navigate(`/distributor/${ rowData.id.substring(1) }`, {state: {
            distributor: {
              ...rowData,
              id: rowData.id.substring(1),
            },
            allDistributors: distributors
          }}) }
        />
      </div>
    </div>
  );
}

export default OrganisationsDetailsPage;
