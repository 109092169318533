import React, { useState } from 'react';
import { styled } from '@mui/styles';
import {
  Card,
  CardContent,
  Collapse,
  Container,
  Grid2 as Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Footer from '../content/Footer';
import { Trans, useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';
import { StyledTitleFAQ } from '../../StyledComponents';

const StyledCard = styled(Card)(({ theme }) => ({
  minWidth: 275,
  marginBottom: '16px',
}));

const StyledHeading = styled(Grid)(({ theme }) => ({
  marginBottom: '12px',
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  width: '100%',
}));

const StyledListItemGrid = styled(Grid)(({ theme }) => ({
  width: '100%',
}));

function QuestionItem(props) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <StyledCard>
      <CardContent>
        <Grid container direction='column' alignItems='flex-start' justify='center'>
          <StyledListItemGrid size={{ xs: 12 }}>
            <StyledListItem button onClick={ handleClick }>
              <ListItemText
                primary={ props.question }
                slotProps={{primary: {style: {fontWeight: 800} }}}
              />
              { open ? <ExpandLess /> : <ExpandMore /> }
            </StyledListItem>
            <Collapse in={ open } timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button>
                  <ListItemText primary={ typeof props.answer === 'function' ? props.answer() : props.answer } />
                </ListItem>

              </List>
            </Collapse>
          </StyledListItemGrid>
        </Grid>
      </CardContent>
    </StyledCard>
  );
}


export default function FAQs() {
  const { t } = useTranslation()
  const components = { ul: <ul />, li: <li />, br: <br />, span: <span />, lnk: <Link />, }
  const sections = [
    {
      title: t(translationKey.TitleFaqTraining),
      faqs: [
        {
          question: t(translationKey.FaqQuestionMissedTraining),
          answer: () => (
            <div>
            <Trans i18nKey={translationKey.FaqAnswerMissedTrainingCots} components={components}/>
            </div>
          ),
        },
        {
          question: t(translationKey.FaqQuestionReviewTraining),
          answer: () => <span> <Trans i18nKey={translationKey.FaqAnswerReviewTrainingCots} components={components} /> </span>
        },
      ],
    },
    {
      title: t(translationKey.TitleFaqChoosingGamePlayers),
      faqs: [
        {
          question: t(translationKey.FaqQuestionHowDoIChooseGamePlayers),
          answer: () => (
            <div>
            <Trans i18nKey={translationKey.FaqAnswerHowDoIChooseGamePlayersCots} components={components}/>
            </div>
          ),
        },
      ],
    },
    {
      title: t(translationKey.TitleFaqSupportingGamePlayers),
      faqs: [
        {
          question: t(translationKey.FaqQuestionIntroduceGameToAYoungPerson),
          answer: () => (
            <div>
            <span>{t(translationKey.FaqAnswerIntroduceGameToAYoungPersonCots)}</span>
            </div>
          ),
        },
        {
          question: t(translationKey.FaqQuestionHowToSendGameInfoToYoungPerson),
          answer: () => (
            <Trans i18nKey={translationKey.FaqAnswerHowToSendGameInfoToYoungPerson} components={components} />
          ),
        },
      ],
    },
    {
      title: t(translationKey.TitleFaqGameMaintenance),
      faqs: [
        {
          question: t(translationKey.FaqQuestionRunOutOfLicenses),
          answer: t(translationKey.FaqAnswerRunOutOfLicenses),
        },
        {
          question: t(translationKey.FaqQuestionForgotGameKey),
          answer: t(translationKey.FaqAnswerForgotGameKey),
        },
        {
          question: t(translationKey.FaqQuestionWhenCanISeeResults),
          answer: t(translationKey.FaqAnswerWhenCanISeeResults),
        },
      ],
    },
    {
      title: t(translationKey.TitleFaqCannotFindWhatYouAreLookingFor),
      faqs: [
        {
          question: t(translationKey.FaqQuestionContact),
          answer: t(translationKey.FaqAnswerContact),
        },
      ],
    },
  ];


  return (
    <Container component="main" maxWidth="md">
      <StyledCard>
        <CardContent>
          <Grid container direction='column' alignItems='flex-start' justify='center'>
            <StyledHeading size={ {xs: 12} }>
              <Typography variant="h4" align="left">
                {t(translationKey.TitleFaq)}
              </Typography>
            </StyledHeading>
            <Grid size={{ xs: 12 }}>
              <Typography component="h1" align="left" variant="body1">
                {t(translationKey.LabelFaqFindEverythingYouNeedCots)}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </StyledCard>

      { sections.map((title, index) => (
        <Grid key={ index } container direction="column">
          <StyledTitleFAQ align='left' key={ index } color='textSecondary'>
            { title.title }
          </StyledTitleFAQ>
          { title.faqs.map((question, index) => (
              <QuestionItem key={ index } question={ question.question } answer={ question.answer } />
            ),
          ) }

        </Grid>
      )) }
      <Footer />
    </Container>
  );
}
