import React, { useEffect, useState } from 'react';
import { styled } from '@mui/styles';
import { Button, Card, CardContent, Container, Grid2 as Grid, Typography } from '@mui/material';
import { axios } from '../../services/networkRequest';
import ExitButton from '../ExitButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import GBOResults from './GBOResults';
import GuardianAnxietySurveyResults from './GuardianAnxietySurveyResults';
import Footer from '../content/Footer';
import { GBOInformation, CORSInformationCard } from './GraphInformationCards';
import CORSSurveyResults from './CORSSurveyResults';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';

const StyledClass = {
  card: {
    minWidth: 275,
    marginBottom: '16px',
  },
  gboContainer: {
    paddingBottom: '60px',
  },
  heading: {
    marginBottom: '12px',
  },
  question: {
    paddingRight: '25px',
  },
  underline: {
    textDecoration: 'underline',
  },
  noUnderline: {
    textDecoration: 'none',
  },
  goalSpace: {
    paddingTop: '45px',
  },
  tableCell: {
    border: '1px solid grey',
  },
  button: {
    marginTop: '30px',
    backgroundColor: '#4F9AE4',
    color: '#EEFFFF',
    marginBottom: '30px'
  },
  showHideButton: {
    display: 'inline-block',
    textAlign: 'right',
    float: 'right',
    marginBottom: '16px',
    marginTop: '16px',
    '&:hover': {
      backgroundColor: '#0079C6',
    },
    '&:active': {
      backgroundColor: 'white',
      borderColor: 'white',
      color: '#0079C6',
    },
  },
  italic: 
  {
    fontStyle: 'italic',
  },
  bottomBorder: {
    borderBottom: '1px solid #ccc',
  },
};

const StyledShowHideButton = styled(Button)({
  ...StyledClass.showHideButton,
});

const StyledButton = styled(Button)({
  ...StyledClass.button,
});

const StyledTableCell = styled(TableCell)({
  ...StyledClass.tableCell,
});

const StyledGoalSpace = styled(Typography)({
  ...StyledClass.goalSpace,
});

const StyledNoUnderlineTypography = styled(Typography)({
  ...StyledClass.noUnderline,
});

const StyledUnderlineTypography = styled(Typography)({
  ...StyledClass.underline,
});

const StyledQuestionTypography = styled(Typography)({
  ...StyledClass.question,
});

const StyledQuestionGrid = styled(Grid)({
  ...StyledClass.question,
});
const StyledHeadingGrid = styled(Grid)({
  ...StyledClass.heading,
});

const StyledGBOContainerGrid = styled(Grid)({
  ...StyledClass.gboContainer,
});

const StyledCard = styled(Card)({
  ...StyledClass.card,
});

const StyledItalicGrid = styled(Grid)({
  ...StyledClass.italic,
});

const StyledBottomBorder = styled(Grid)(({ theme }) => ({
  ...StyledClass.bottomBorder,
}));

export default function GuardianSurvey(props) {
  const params = useParams();
  const young_person_id = params.id;
  const { t } = useTranslation();
  const [goalResults, setGoalResults] = useState(undefined);
  const [gboResponses, setGBOResponses] = useState(undefined);
  const [corsSurveys, setCorsSurveys] = useState(undefined)
  const [showResults, setShowResults] = useState(true);
  const [name, setName] = useState(undefined);
  const [cors, setCors] = useState(undefined);
  const [surveyResultsVisible, setSurveyResultsVisible] = useState(false);
  const [smsData, setSmsData] = useState(undefined);
  const [guardian, setGuardian] = useState(undefined);
  const [activeGoals, setActiveGoals] = useState([]);

  useEffect(() => {
    axios.get(`/ln/young_persons/anxiety_results/${ young_person_id }`).then(({ data }) => {
      // TODO Combine all of these into a single useState because the API returns the data as one object
      setGoalResults(data.goals);
      setGBOResponses(data.gbos);
      setCorsSurveys(data.cors_surveys)
      setCors(data.cors);
      setName(`${ data.first_name } ${ data.last_name }`);
      setSmsData(data.sms_data);
      setGuardian(data.guardian);
      setActiveGoals(data.active_goals);
    });
  }, [young_person_id]);

  if (!(goalResults && gboResponses)) {
    return null;
  }

  const toggleSurveyResultsVisible = () => setSurveyResultsVisible(!surveyResultsVisible);

  const SurveyResults = () => {
    return (
      <ol>
        { goalResults.map((goal, j) => (
          <Grid container direction="column" key={ j }>
            <Grid container direction='column' wrap="nowrap">
              <li>
                <Grid container direction="column" wrap="nowrap">
                  <StyledGoalSpace align='left' color='textSecondary'
                              id={ goal.url_name }>
                    { goal.goal_name } - { goal.date }
                  </StyledGoalSpace>
                  <ol>
                    { goal.surveys.map(({ question, answer }, index) => (
                      <Grid container direction="row" key={ index }>
                        <Grid size={{ xs: 6 }}>
                          <li>
                            <StyledQuestionTypography align='left' color='textPrimary'>{ question }</StyledQuestionTypography>
                          </li>
                        </Grid>
                        <Grid size={{ xs: 6 }}>
                          <Grid container direction='row'>
                            <Grid size={{xs:12}}>
                              {
                                answer === 'Never' ?
                                  <StyledUnderlineTypography color='textPrimary'>{ t(translationKey.LabelNever) }</StyledUnderlineTypography> :
                                  <StyledNoUnderlineTypography color='textSecondary'>{ t(translationKey.LabelNever) }</StyledNoUnderlineTypography>
                              }
                            </Grid>
                            <Grid size={{xs:12}}>
                              {
                                answer === 'Sometimes' ?
                                  <StyledUnderlineTypography color='textPrimary'>{ t(translationKey.LabelSometimes) }</StyledUnderlineTypography> :
                                  <StyledNoUnderlineTypography color='textSecondary'>{ t(translationKey.LabelSometimes) }</StyledNoUnderlineTypography>
                              }
                            </Grid>
                            <Grid size={{xs:12}}>
                              {
                                answer === 'Often' ?
                                  <StyledUnderlineTypography color='textPrimary'>{ t(translationKey.LabelOften) }</StyledUnderlineTypography> :
                                  <StyledNoUnderlineTypography color='textSecondary'>{ t(translationKey.LabelOften) }</StyledNoUnderlineTypography>
                              }
                            </Grid>
                            <Grid size={{xs:12}}>
                              {
                                answer === 'Always' ?
                                  <StyledUnderlineTypography color='textPrimary'>{ t(translationKey.LabelAlways) }</StyledUnderlineTypography> :
                                  <StyledNoUnderlineTypography color='textSecondary'>{ t(translationKey.LabelAlways) }</StyledNoUnderlineTypography>
                              }
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )) }
                  </ol>
                </Grid>
              </li>
              <Typography align='right' color='textSecondary'>{t(translationKey.TitleFinalScore, { score: goal.final_score})}</Typography>
            </Grid>
          </Grid>
        )) }
      </ol>
    );
  };

  return (
    <Container component="main" maxWidth="md">
      <StyledCard>
        <CardContent>
          <StyledGBOContainerGrid container direction='column' alignItems='flex-start' justify='center'>

            <StyledBottomBorder container direction="row" justify="space-between" alignItems="flex-start" sx={{ width: '100%' }}>
              <StyledHeadingGrid size={{ xs: 11 }}>
                <Typography variant="h5" align="left">
                  {t(translationKey.TitleGoalBasedOutcomes, { name: name })}
                </Typography>
              </StyledHeadingGrid>
              <ExitButton />
            </StyledBottomBorder>

            <StyledHeadingGrid size={{ xs: 12 }}>
              <Typography variant="span" align="left" color='textPrimary'>
                {t(translationKey.TitleClosenessToGoal)}
              </Typography>
            </StyledHeadingGrid>
            <StyledQuestionGrid size={{ xs: 12 }}>
              <Typography align="left" color='textPrimary'>
                {t(translationKey.ScaleZeroToTen)}
              </Typography>
            </StyledQuestionGrid>
            <StyledItalicGrid size={ {xs:12} }>
              <Typography align="left" color='textSecondary'>
                {t(translationKey.LabelScaleZeroExplanation)}
              </Typography>
              <Typography align="left" color='textSecondary'>
                {t(translationKey.LabelScaleTenExplanation)}
              </Typography>
            </StyledItalicGrid>
            <Grid size={{ xs: 12 }}><GBOResults responses={gboResponses} /></Grid>
          </StyledGBOContainerGrid>

          { activeGoals && activeGoals.length > 0 &&
             <Table size="small" aria-label={ t(translationKey.AccessibilityLabelADenseTable) }>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left" ><strong>#</strong></StyledTableCell>
                  <StyledTableCell align="left"
                             ><strong>{ t(translationKey.LabelGoalName) }</strong></StyledTableCell>
                  <StyledTableCell align="left"
                             ><strong>{ t(translationKey.LabelGoalStartedOn) }</strong></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { activeGoals.map((goal, i) => {
                  const dateToDisplay = dayjs(goal.started_at).format("DD MMM YYYY");
                  return (
                    <TableRow key={ i }>
                      <StyledTableCell component="th" scope="row">
                        { i + 1 }
                      </StyledTableCell>
                      <StyledTableCell align="left">{ goal.name }</StyledTableCell>
                      <StyledTableCell align="left">{ dateToDisplay }</StyledTableCell>
                    </TableRow>
                  );
                }) }
              </TableBody>
            </Table>
          }

          <GBOInformation/>

          {!cors &&<Grid container direction='column' alignItems='flex-start' justify='center'>
            <StyledHeadingGrid size={{ xs: 12 }}>
              <Typography variant="h5" align="left">
                {t(translationKey.TitleGuardianAnxietySurveyResults, { name: name })}
              </Typography>
            </StyledHeadingGrid>
            <Grid size={{ xs: 12 }}>
              <Typography variant='h6' align='left' color='textSecondary'>{t(translationKey.TitleCompletedSurveysColon)}</Typography>
            </Grid>
            <Grid size={{ xs: 12 }}><GuardianAnxietySurveyResults results={goalResults} /></Grid>
            <Table size="small" aria-label={t(translationKey.AccessibilityLabelADenseTable)}>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left"><strong>#</strong></StyledTableCell>
                  <StyledTableCell align="left"><strong>{t(translationKey.LabelSurveyName)}</strong></StyledTableCell>
                  <StyledTableCell align="left"><strong>{t(translationKey.LabelDate)}</strong></StyledTableCell>
                  <StyledTableCell align="left"><strong>{t(translationKey.LabelScore)}</strong></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { goalResults.map((goal, i) => {
                  const date = new Date(goal.date);
                  const dateString = date.toDateString();
                  const parts = dateString.split(' ');
                  const goalDate = [parts[2], parts[1], parts[3]].join(' ');
                  return (
                    <TableRow key={ i }>
                      <StyledTableCell component="th" scope="row">
                        { i + 1 }
                      </StyledTableCell>
                      <StyledTableCell align="left">{ goal.goal_name }</StyledTableCell>
                      <StyledTableCell align="left">{ goalDate }</StyledTableCell>
                      <StyledTableCell align="left">{ goal.final_score }</StyledTableCell>
                    </TableRow>
                  );
                }) }
              </TableBody>
            </Table>


            <StyledButton variant="contained" color="#4D9BE9" disableElevation
                    onClick={ () => toggleSurveyResultsVisible() }>{t(translationKey.ButtonHideOrViewCompletedSurveys, { option: surveyResultsVisible ? 'HIDE' : 'VIEW'})}</StyledButton>

            { surveyResultsVisible ? <SurveyResults /> : null }

          </Grid>}


          {
          cors &&
          <Grid container direction='column' alignItems='flex-start' justify='center'>
          <StyledHeadingGrid size={{ xs: 12 }}>
            <Typography variant="h6" align="left">
              {t(translationKey.TitleCorsResult, { name: name })}
            </Typography>
            <Typography align="left" style={{color: 'grey'}}>
              {t(translationKey.TitleCompletedSurveysColon)}
            </Typography>
          </StyledHeadingGrid>
            <Grid size={{ xs: 12 }} container direction='row'>
              {guardian &&
                <CORSSurveyResults item results={corsSurveys} smsData={smsData} youngPersonId={young_person_id} guardianFirstName={guardian.first_name}/>
              }
            </Grid>
            <Table style={{marginTop: "1em"}} size="small" aria-label={t(translationKey.AccessibilityLabelADenseTable)}>
            <TableHead>
              <TableRow>
                <StyledTableCell align="left"><strong>#</strong></StyledTableCell>
                <StyledTableCell align="left"><strong>{t(translationKey.LabelChildCorsCompletedOn)}</strong></StyledTableCell>
                <StyledTableCell align="left"><strong>{t(translationKey.LabelScore)}</strong></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {corsSurveys.map((survey, i) => {
                const date = new Date(survey.survey_date);
                const dateString = date.toDateString();
                const parts = dateString.split(' ');
                const goalDate = (i === 0 ) ? 'Baseline - ' + [parts[2], parts[1], parts[3]].join(' ') : [parts[2], parts[1], parts[3]].join(' ');
                return (
                  <TableRow key={i}>
                    <StyledTableCell component="th" scope="row">
                      {i + 1}
                    </StyledTableCell>
                    <StyledTableCell align="left">{goalDate}</StyledTableCell>
                    <StyledTableCell align="left">{survey.total}</StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>

          <StyledShowHideButton variant="contained" color="primary"
                          onClick={ () => {
                            setShowResults(!showResults)
                          } }>
            {t(translationKey.ButtonHideOrViewCompletedSurveys, { option: showResults ? 'HIDE' : 'SHOW'})}
          </StyledShowHideButton>

          {showResults &&
            corsSurveys.map((survey, i) => {
              const date = new Date(survey.survey_date);
              const dateString = date.toDateString();
              const goalDate = (i === 0 )
              ? t(translationKey.LabelCorsBaselineCompletedOn, { date: dateString })
              : t(translationKey.LabelCorsCompletedOn, { date: dateString })
              return (
                <div key={i}>
                  <Typography align="left" style={{ fontWeight: 600, width: '500px' }}> {i + 1}. {goalDate} </Typography>
                  <Table>
                    <TableBody>
                    {
                      Object.entries(survey.answers).map(([key, value], index) =>
                        <TableRow key={index}>
                          <StyledTableCell style={{border: 'none', padding: 0, paddingLeft: '25px'}} align="left">{key.trim()}</StyledTableCell>
                          <StyledTableCell style={{border: 'none', padding: 0}} align="left">{value.trim()}</StyledTableCell>
                        </TableRow>
                        )
                    }
                    <Typography align="left">{t(translationKey.LabelTotalSurveyScore, { total: survey.total })}</Typography>
                    </TableBody>
                  </Table>
                </div>
              );
            })
          }

          <CORSInformationCard/>
        </Grid>
          }

        </CardContent>
      </StyledCard>
      <Footer />
    </Container>
  );
}
