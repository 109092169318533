import React from 'react';

import { VictoryAxis, VictoryLine, VictoryLabel, VictoryLegend, VictoryChart } from 'victory';
import { withSize } from 'react-sizeme';
import { numberTextColor as blue, darkgreen as green } from '../Styles';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../utilities/localisation/translationKeys';
import { tickSteps } from '../services/helpers';
import { StyledCentreCircularProgress, StyledDivRoot } from '../StyledComponents';

const CotsYoungPersonPerformanceChart = (props) => {

  const { t } = useTranslation();

  if (!props.data) {
    return <StyledDivRoot>
      <StyledCentreCircularProgress />
    </StyledDivRoot>;
  }

  const reducedPerformanceValues = props.data.reduce((acc, item) => {
    if(item.performance_mean > 0) {
      acc.push(item.performance_mean);
    }
    if(item.performance_median > 0) {
      acc.push(item.performance_median);
    }
    return acc;
  }, []);
  const xAxisValues = props.data.map(item => item.game);
  const tickValuesPerformance = tickSteps(reducedPerformanceValues, 0, num => parseFloat(num.toFixed(2)));
  return (
    <StyledDivRoot >
      <VictoryChart
        height={ 340 }
        width={ 1100 }
        domain={{y:[tickValuesPerformance[0], tickValuesPerformance[tickValuesPerformance.length - 1]]}}
        >
          <VictoryAxis
            label={t(translationKey.LabelDuels)}
            tickValues={ xAxisValues }
            style={{
              grid: { stroke: "#818e99", strokeWidth: 0.5 },
            }}
          />

          {/* Dependent axis for line graphs. */}
          <VictoryAxis dependentAxis
            label={t(translationKey.LabelHrvInMs)}
            axisLabelComponent = {
              <VictoryLabel dy={-10}/>
            }
            tickValues={ tickValuesPerformance }
            orientation='left'
             style={{
            grid: { stroke: "#818e99", strokeWidth: 0.5 },
            }}
          />

          <VictoryLine
            data={ props.data }
            x='game'
            y='performance_median'
            style={ { data: { stroke: blue } } }
          />
          <VictoryLine
            data={ props.data }
            x='game'
            y='performance_mean'
            style={ { data: { stroke: green } } }
          />

          <VictoryLegend x={430} y={320}
            gutter={20}
            orientation="horizontal"
            data={[
              { name: t(translationKey.LabelMean), symbol: { fill: green } },
              { name: t(translationKey.LabelMedian), symbol: { fill: blue } },
            ]}
          />
        </VictoryChart>
    </StyledDivRoot>
  );
};

export default withSize()(CotsYoungPersonPerformanceChart);
