import React, { useState, useEffect } from 'react';
import { getChain } from '../../../services/helpers';
import { Grid2 as Grid, Typography } from '@mui/material';

import {
  greyBorderColor,
} from '../../../Styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { VideogameAssetOutlined as GameAsset, Timer, FavoriteBorderOutlined as Heart } from '@mui/icons-material';
import CotsYoungPersonPerformanceChart from '../../CotsYoungPersonPerformanceChart';
import ContactDetails from './ContactDetails';
import UsageGraph from '../UsageGraph';
import ExitButton from '../../ExitButton';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../../utilities/localisation/translationKeys';
import {
  TypographyAverageTime,
  TypographyHoursAndMinutes,
  TypographyNumberSpan,
} from '../../Typography/TypographyNumberSpan';
import { useLocation } from 'react-router-dom';

export function CotsYoungPersonReport(props) {
    const { t } = useTranslation();
    const location = useLocation();

    const rowData = location.state.userData;
    const [userData, setUserData] = useState(rowData);

    const gameTime = getChain(userData, 'stats', 'game_time');
    const lastUsedDateString = gameTime && gameTime.lastLoggedIn && new Date(gameTime.lastLoggedIn).toLocaleDateString();
    const totalTime = getChain(gameTime, 'timeSpent');
    const totalHours = gameTime ? Math.floor(totalTime / 60) : '';
    const totalMinutes = gameTime ? Math.round(totalTime - (totalHours * 60)) : '';
    const improving = getChain(gameTime, 'improving');

    const graph_data = getChain(userData, 'performance_data');
    const first_graph_data = getChain(graph_data, 'first_graph');
    const recent_graph_data = getChain(graph_data, 'recent_graph');

    useEffect(() => {
      setTimeout(window.print, 2000);
    }, []);

    return(
      <Grid
      container
      spacing={0}
      direction="row"
      alignItems="center"
      justify="center"
      key={ userData.id }>

        <Grid container id="unzoom" component="main" xs={11} style={ { paddingTop: '30px' } }
              spacing={ 3 }>
          { userData.first_play && <>
            <Grid size={{ xs: 11 }}>
              <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
                <CardContent>
                  <Grid container>
                    <Grid size={{ xs: 5 }}><Typography color="textPrimary">{t(translationKey.TitlePlayerGameTime, { player: userData.first_name })}</Typography></Grid>
                    <Grid size={{ xs: 5 }}><Typography color="textSecondary"
                                                    align="right"> { t(translationKey.TitleLastUsedDate, { lastUsedDateString : lastUsedDateString || '' })  }</Typography></Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid size={{ xs: 1 }}>
              <ExitButton />
            </Grid>
            <Grid size={{ xs: 3 }}>
              <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
                <CardContent>
                  <Typography align='left' color="textPrimary">{t(translationKey.LabelTotalDuels)}</Typography>
                  <Grid align='left' container>
                    <Grid size={{ xs: 10 }}><TypographyNumberSpan>{ gameTime.totalDuels }</TypographyNumberSpan></Grid>
                    <Grid size={{ xs: 2 }}><GameAsset style={ { fontSize: '40px' } } /></Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid size={{ xs: 3 }}>
              <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
                <CardContent>
                  <Typography align='left' color="textPrimary">{t(translationKey.LabelAverageSessionTime)}</Typography>
                  <Grid align='left' container>
                    <Grid size={{ xs: 10 }}>
                      <TypographyAverageTime average={gameTime.timeAverage} />
                    </Grid>
                    <Grid size={{ xs: 2 }}><Timer style={ { fontSize: '40px' } } /></Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid size={{ xs: 3 }}>
              <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
                <CardContent>
                  <Typography align='left' color="textPrimary">{t(translationKey.LabelTotalTimePlaying)}</Typography>
                  <Grid align='left' container>
                    <Grid size={{ xs: 10 }}>
                      <TypographyHoursAndMinutes totalHours={totalHours} totalMinutes={totalMinutes} />
                    </Grid>
                    <Grid size={{ xs: 2 }}><Timer style={ { fontSize: '40px' } } /></Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid size={{ xs: 3 }}>
              <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
                <CardContent>
                  <Typography align='left' color="textPrimary">{t(translationKey.LabelPlayerPerformance)}</Typography>
                  <Grid align='left' container>
                    <Grid size={{ xs: 10 }}>
                        <TypographyNumberSpan>
                        {(() => {
                          switch (improving) {
                            case 1: return t(translationKey.LabelImproving);
                            case 0: return t(translationKey.LabelNotImproving);
                            default: return t(translationKey.LabelInsufficientData);
                          }
                        })()}
                        </TypographyNumberSpan>
                      </Grid>
                    <Grid size={{ xs: 2 }}><Heart style={ { fontSize: '40px' } } /></Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <UsageGraph playerName={userData.first_name} userData={userData} setUserData={(userData) => {
              setUserData(userData);
            } } chartUrl={`/cots/young_persons/${ userData.id }/usage_chart`} />
            <Grid size={{ xs: 12 }}>
              <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
                <CardContent>
                  <Grid container>
                    <Grid size={{ xs: 12 }}>
                      <Typography align='left' color="textPrimary" style={{marginRight: '5px'}}>{t(translationKey.LabelPerformanceOverFirst20)}</Typography>
                    </Grid>
                  </Grid>
                  <CotsYoungPersonPerformanceChart data={ first_graph_data }/>
                  <Grid size={{ xs: 12 }}>
                      <Typography align='left' color="textPrimary" style={{marginRight: '5px'}}>{t(translationKey.LabelPerformanceOverLast20)}</Typography>
                    </Grid>
                  <CotsYoungPersonPerformanceChart data={ recent_graph_data }/>
                </CardContent>
              </Card>
            </Grid>

          </> }
          <Grid size={{ xs: 12 }}>
            <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
              <CardContent>
                <Grid container>
                  <Grid size={{ xs: 10 }}>
                    <Typography color="textSecondary">{t(translationKey.LabelContact)}</Typography>
                  </Grid>
                  <Grid size={{ xs: 2 }}>
                  </Grid>
                </Grid>
                <ContactDetails { ...userData } />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    )
}
