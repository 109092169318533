import { Card, CircularProgress, Grid2, Select, Typography, Button } from "@mui/material";
import { styled } from "@mui/styles";
import { dropdownTextColor } from "./Styles";
import { helperTextColor } from "./constants";

export const NoWrapTypography = styled(Typography)(({ theme }) => ({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }))

export const StyledCentreCircularProgress = styled(CircularProgress)(({ theme }) => ({
   margin: 'auto',
}))

export const StyledCentreCircularProgress40 = styled(CircularProgress)(({ theme }) => ({
    margin: '40px auto',
 }))

export const StyledSelectBase = styled(Select)(({ theme }) => ({
    color: dropdownTextColor,
    border: `1px solid ${ dropdownTextColor }`,
    fontSize: '14px',
    padding: '2px 2px',
    minWidth: '160px',
    '&:focus': {
      backgroundColor: 'white',
      borderRadius: '4px',
      border: `1px solid ${ dropdownTextColor }`,
      outlineStyle: 'none',
    }
}))

export const StyledSelectBaseReport = styled(StyledSelectBase)(({ theme }) => ({
    fontSize: "18px",
    minWidth: "200px"
}))

export const StyledDivRoot = styled('div')(({ theme }) => ({
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 350
}))

export const StyledTitleTypographyBase = styled(Typography, {
})(({ theme }) => ({
    fontWeight: 'bold',
    marginBottom: "1em"
}))

export const StyledTitleFAQ = styled(Typography)(({ theme }) => ({
    padding: '20px',
    paddingTop: '50px',
}))

export const StyledSubtitleTypographyBase = styled(Typography)(({ theme }) => ({
    color: '#2D9BF0',
    textAlign: 'left'
}))

export const StyledErrorContainerBase = styled(Grid2)(({ theme }) => ({
    margin: '1em 0',
    display: 'grid',
    placeItems: 'start start'
}))

export const StyledErrorLinkBase = styled('a')(({ theme }) => ({
    color: '#c9218e',
    fontWeight: 'bold'
}))

export const StyledErrorContentBase = styled(Typography)(({ theme }) => ({
    fontSize: '1rem'
}))

export const StyledSubDetailSection = styled(Typography)(({ theme }) => ({
    fontSize: '1rem',
    fontWeight: 'bolder',
    fontStyle: 'italic'
}))

export const StyledSubGrid = styled(Grid2)(({ theme }) => ({
    boxShadow: 'box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
}))

export const StyledSubGridCard = styled(Card)(({ theme }) => ({
    boxShadow: 'box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
}))

export const StyledHelperText = styled(Typography)(({ theme }) => ({
    color: helperTextColor,
    fontSize: '12px',
    paddingLeft: '14px',
    paddingTop: '4px',
}))

export const StyledDetailedSection = styled(Typography)(({ theme }) => ({
    marginTop: '45px',
    fontWeight: 'normal',
}))

export const StyledContent = styled(Typography)(({ theme }) => ({
    textAlign: 'left',
}))

export const StyledSubmitButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(3, 0, 2),
}))



