import React from 'react';
import { Container, Grid2 as Grid, CssBaseline } from '@mui/material';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../utilities/localisation/translationKeys';

const StyledBackground = styled(Container)(({ theme }) => ({
  width: '100vw',
  position: 'relative',
}));

const StyledLogo = styled('img')(({ theme }) => ({
  position: 'absolute',
  top: 10,
  left: 0,
  width: '100px',
  height: 'auto',
}));

const StyledTextContainer = styled(Grid)({
  marginTop: 160
});

const StyledHeaderText = styled(Typography)({
  fontWeight: 'bold',
});

const StyledText = styled(Grid)({
  marginTop: 25,
  marginBottom: 35,
});

const StyledOwlImage = styled('img')({
  width: '550px',
  height: 'auto',
});

const GlobalStyles = styled('div')(({ theme }) => ({
  body: {
    backgroundColor: theme.palette.common.white,
  },
}));



export default function MaintenancePage() {
  const { t } = useTranslation();

  return (
    <StyledBackground component="main" maxWidth="xl">
      <CssBaseline />
      <GlobalStyles />
      <Grid container direction="column">
        <Grid>
          <StyledLogo src='/BfB_logo_blue.png' alt='logo'/>
        </Grid>
        <StyledTextContainer container direction='column'>
          <Grid>
            <StyledHeaderText variant="h4">
              {t(translationKey.TitleBackOnlineSoon)}
            </StyledHeaderText>
          </Grid>
          <StyledText>
            <Typography>
              {t(translationKey.TextHubDownForMaintenance)}
              <br />
              {t(translationKey.TextMaintenanceLength)}
            </Typography>
          </StyledText>
          <StyledOwlImage style={ { margin: 'auto' } } src="/maintenance_owl.png" alt="Maintenance"/>
        </StyledTextContainer>
      </Grid>
    </StyledBackground>
  );
}
