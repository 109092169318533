import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/styles';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import { Wc, Event, PersonOutline, People, ContactPhone, ContactMail, Assignment as AssignmentIcon} from '@mui/icons-material';
import style from '../../../../Styles';
import Button from '@mui/material/Button';
import { axios } from '../../../../services/networkRequest';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../../../utilities/localisation/translationKeys';
import { StyledDivRoot } from '../../../../StyledComponents';

const contactFontColor = '#4C4C71';
const iconColor = '#C2C3C4';

const StyledDiv = styled(StyledDivRoot)(({ theme }) => ({
  height: 'auto',
}))

const ContactDetails = (props) => {
  const guardian = props.guardians[0] || {};
  const dateString = new Date(props.dob).toLocaleDateString();
  const { t } = useTranslation();
  return (
    <StyledDiv>
      <Grid container spacing={ 1 } style={ { marginTop: '8px' } }>
        <Grid size={{ xs: 4 }} style={ { borderRight: '1px solid #aaa' } }>
          <Grid container spacing={ 1 }>
            <Grid size={{ xs: 3 }}><Event htmlColor={ iconColor } style={ { margin: '8px' } } /></Grid>
            <Grid size={{ xs: 9 }}>
              <Typography color='textSecondary'>{ `${ props.first_name } D.O.B.` }</Typography>
              <Typography style={ { color: contactFontColor } }>{ dateString }</Typography>
            </Grid>
            <Grid size={{ xs: 3 }}><Wc htmlColor={ iconColor } style={ { margin: '8px' } } /></Grid>
            <Grid size={{ xs: 9 }}>
              <Typography color='textSecondary'>{t(translationKey.LabelGender)}</Typography>
              <Typography style={ { color: contactFontColor } }>{ props.gender }</Typography>
            </Grid>
            <Grid size={{ xs: 3 }}><AssignmentIcon htmlColor={ iconColor } style={ { margin: '8px' } } /></Grid>
            <Grid size={{ xs: 9 }}>
              <Typography color='textSecondary'>{t(translationKey.LabelServiceUserReference)}</Typography>
              <Typography style={ { color: contactFontColor } }>{ props.service_user_reference }</Typography>
            </Grid>
            {
              props.isSelfSignUpDist && props.custom_entry_one_query && (
                <>
                  <Grid size={{ xs: 3 }}><AssignmentIcon htmlColor={ iconColor } style={ { margin: '8px' } } /></Grid>
                  <Grid size={{ xs: 9 }}>
                    <Typography color='textSecondary'>{props.custom_entry_one_query}</Typography>
                    <Typography style={ { color: contactFontColor } }>{ props.custom_entry_one_response }</Typography>
                  </Grid>
                </>
              )
            }
            {
              props.isSelfSignUpDist && props.custom_entry_two_query && (
                <>
                  <Grid size={{ xs: 3 }}><AssignmentIcon htmlColor={ iconColor } style={ { margin: '8px' } } /></Grid>
                  <Grid size={{ xs: 9 }}>
                    <Typography color='textSecondary'>{props.custom_entry_two_query}</Typography>
                    <Typography style={ { color: contactFontColor } }>{ props.custom_entry_two_response }</Typography>
                  </Grid>
                </>
              )
            }
            {
              props.isSelfSignUpDist && props.custom_entry_three_query && (
                <>
                  <Grid size={{ xs: 3 }}><AssignmentIcon htmlColor={ iconColor } style={ { margin: '8px' } } /></Grid>
                  <Grid size={{ xs: 9 }}>
                    <Typography color='textSecondary'>{props.custom_entry_three_query}</Typography>
                    <Typography style={ { color: contactFontColor } }>{ props.custom_entry_three_response }</Typography>
                  </Grid>
                </>
              )
            }
          </Grid>

        </Grid>
        <Grid size={{ xs: 3 }}>
          <Grid container spacing={ 1 }>
            <Grid size={{ xs: 3 }}><PersonOutline htmlColor={ iconColor } style={ { margin: '8px' } } /></Grid>
            <Grid size={{ xs: 9 }}>
              <Typography color='textSecondary'>{t(translationKey.TitleGuardianName)}</Typography>
              <Typography
                style={ { color: contactFontColor } }>{ (guardian.first_name || guardian.last_name) ? `${ guardian.first_name } ${ guardian.last_name }` : t(translationKey.ErrorMissingName) }</Typography>
            </Grid>
            <Grid size={{ xs: 3 }}><People htmlColor={ iconColor } style={ { margin: '8px' } } /></Grid>
            <Grid size={{ xs: 9 }}>
              <Typography color='textSecondary'>{t(translationKey.TitleGuardianRelationship)}</Typography>
              <Typography
                style={ { color: contactFontColor } }>{ guardian.relationship || t(translationKey.ErrorMissingRelationship) }</Typography>
            </Grid>
            {
              props.isSelfSignUpDist && guardian.lumi_nova_visibility_response && (
                <>
                  <Grid size={{ xs: 3 }}><AssignmentIcon htmlColor={ iconColor } style={ { margin: '8px' } } /></Grid>
                  <Grid size={{ xs: 9 }}>
                    <Typography color='textSecondary'>{t(translationKey.LabelMarketingVisibility)}</Typography>
                    <Typography
                      style={ { color: contactFontColor } }>{ guardian.lumi_nova_visibility_response }</Typography>
                  </Grid>
                </>
              )
            }
          </Grid>
        </Grid>
        <Grid size={{ xs: 3 }}>
          <Grid container spacing={ 1 }>
            <Grid size={{ xs: 3 }}><ContactPhone htmlColor={ iconColor } style={ { margin: '8px' } } /></Grid>
            <Grid size={{ xs: 9 }}>
              <Typography color='textSecondary'>{t(translationKey.LabelMobile)}</Typography>
              <Typography
                style={ { color: contactFontColor } }>{ guardian.telephone || t(translationKey.ErrorMissingPhoneNumber) }</Typography>
            </Grid>
            <Grid size={{ xs: 3 }}><ContactMail htmlColor={ iconColor } style={ { margin: '8px' } } /></Grid>
            <Grid size={{ xs: 9 }}>
              <Typography color='textSecondary'>{t(translationKey.TitleEmail)}</Typography>
              <Typography
                style={ { color: contactFontColor } }>{ guardian.email || t(translationKey.ErrorMissingEmail) }</Typography>
            </Grid>
          </Grid>
        </Grid>
        {!props.started &&
        <Grid size={{ xs: 2 }}>
          <Grid container spacing={ 1 }>
            <ThemeProvider theme={ style }>
              <Button variant="contained" color="primary"
                      onClick={ () => {

                        confirmAlert({
                          title: t(translationKey.AlertTitleResendGuardianInvite, {firstname : guardian.first_name, lastname : guardian.last_name}),
                          message: t(translationKey.AlertBodyResendGuardianInvite),
                          buttons: [
                            {
                              label: t(translationKey.ChoiceLabelYes),
                              onClick: () => {
                                axios.post(`/ln/resend_invite/${guardian.id}/${props.id}`);
                              },
                            },
                            {
                              label: t(translationKey.ButtonCancel),
                              onClick: () => {
                              },
                            },
                          ],
                        });
                      } }>
                {t(translationKey.ButtonResendInvite)}
              </Button>
            </ThemeProvider>
          </Grid>
        </Grid>
        }
      </Grid>
    </StyledDiv>
  );
};

export default ContactDetails;
