import React, { useState } from 'react';
import { styled } from '@mui/styles';
import { VictoryAxis, VictoryLine, VictoryScatter, VictoryChart, VictoryTheme } from 'victory';
import { withSize } from 'react-sizeme';
import { Button, Input, Typography } from '@mui/material';
import { barColor } from '../../Styles';
import Grid from '@mui/material/Grid2';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';
import { axios } from '../../services/networkRequest';
import { genericAlert } from '../../services/helpers';
import ContentCopyIcon from '@mui/icons-material/FileCopy';
import { confirmAlert } from 'react-confirm-alert';
import { StyledCentreCircularProgress, StyledDivRoot } from '../../StyledComponents';

const StyledKey = styled('div')(({ theme }) => ({
  marginTop: '64px',
  border: '1px solid black',
  width: '30%',
  padding: 5
}))

const StyledDot = styled('div')(({ theme }) => ({
  height: "10px",
  width: "10px",
  backgroundColor: barColor,
  borderRadius: "50%",
  marginLeft: '24px',
  marginTop: '4px'
}))

const StyledShowHideButton = styled(Button)(({ theme }) => ({
  width: 'fit-content',
  marginLeft: 'auto',
  marginTop: '1em',
  marginBottom: '1em',
  display: 'inline-block',
  textAlign: 'right',
  float: 'right',
  // marginBottom: '16px',
  // marginTop: '16px',
  '&:hover': {
    backgroundColor: '#0079C6',
  },
  '&:active': {
    backgroundColor: 'white',
    borderColor: 'white',
    color: '#0079C6',
  },
}))


const StyledDiv = styled(StyledDivRoot)(({ theme }) => ({
  height: 230,
  width: 600,
  paddingBottom: 200,
  marginBottom: 20
}))


const CORSSurveyResults = (props) => {
  const [isCopied, setIsCopied] = useState(false);

  const { t } = useTranslation();
  if (!props.results) {
    return <StyledDiv>
      <StyledCentreCircularProgress />
    </StyledDiv>;
  }

  const chartData = props.results.map((item, index) => {
  const date = new Date(item.survey_date);
  const dateString = date.toDateString();
  const parts = dateString.split(' ');

  const axisLabel = parts[2] + ' ' + parts[1];
  return {
      day: index + 1,
      axisLabel,
      total: Number(item.total)
    }
  });

  const dayValues = chartData.map(item => item.day);
  const labels = chartData.map(item => item.axisLabel);
  let tickValues = [0, 5, 10, 15, 20, 25, 30, 35, 40];

  const { smsData, youngPersonId, guardianFirstName } = props
  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    return await navigator.clipboard.writeText(text);
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(smsData.survey_link)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <StyledDiv>
      <Grid style={{ width: '100%' }}>
        <VictoryChart
          height={ 270 }
          width={ props.size.width }
          domainPadding={ 16 }
          theme={ VictoryTheme.material }
        >
          <VictoryAxis crossAxis
                      tickValues={ dayValues }
                      tickFormat={ labels }
                      label={t(translationKey.LabelDate)}
                      style={ { axisLabel: { padding: 35 }, grid: { stroke: 'none' } } }
          />
          <VictoryAxis dependentAxis crossAxis
                      tickValues={tickValues}
                      label={t(translationKey.TitleUnnamedCorsResults)}
                      style={ { axisLabel: { padding: 40 } } }
          />

          <VictoryLine
              y={() => 28}
              style={ { data : {
                  strokeDasharray: "5,5",
                  stroke: 'limegreen'
              } } }
          />

          <VictoryLine
            tickValues={ dayValues }
            style={ { data: { stroke: barColor } } }
            data={ chartData }
            x={t(translationKey.LabelDay)}
            y={t(translationKey.LabelTotal)}
          />
          <VictoryScatter
            tickValues={ dayValues }
            style={ { data: { fill: barColor } } }
            data={ chartData }
            x={t(translationKey.LabelDay)}
            y={t(translationKey.LabelTotal)}
          />
        </VictoryChart>
        <Grid container >
          <Grid size={{ xs: 5 }}>
            <StyledKey>
              <Typography align="left" style={{fontWeight: 600, fontSize: '12px', marginLeft: '10px', textTransform: 'uppercase'}}>{t(translationKey.TitleKey)}</Typography>
              <Grid container direction={'row'} style={{ marginBottom: '12px' }}>
                <Grid size={{ xs: 4 }}>
                  <div style={{ color: 'limegreen', fontWeight: 600 }}>- - -</div>
                </Grid>
                <Grid size={{ xs: 8 }}>
                  <Typography align="left" style={{ fontSize: '12px' }}>{t(translationKey.TitleCutOffForCarerCorsReport)}</Typography>
                </Grid>
              </Grid>
              <Grid container direction={'row'}>
                <Grid size={{ xs: 4 }}>
                  <StyledDot></StyledDot>
                </Grid>
                <Grid size={{ xs: 8 }}>
                  <Typography align="left" style={{ fontSize: '12px', textTransform: 'uppercase' }}>{t(translationKey.TitleCors)}</Typography>
                </Grid>
              </Grid>
            </StyledKey>
          </Grid>
          <Grid size={{ xs: 7 }} container direction='column' align="right" style={{marginTop: "64px"}}>
            { smsData &&
              <>
                <Typography align="right">{t(translationKey.LabelSMSDataNextSMS, {next_sms_date: smsData.next_scheduled ? smsData.next_scheduled : t(translationKey.LabelSMSNoneScheduled) })}</Typography>
                <Typography align="right">{t(translationKey.LabelSMSDataPreviousSMS, {previous_sms_date:  smsData.most_recent ? smsData.most_recent : t(translationKey.LabelSMSNoneSent) })}</Typography>
                { youngPersonId && <StyledShowHideButton
                  align="right"
                  variant="contained"
                  color="primary"
                  onClick={ () => {
                    genericAlert({
                      title: t(translationKey.LabelSMSDataResendCORSSMS),
                      message:  t(translationKey.LabelSMSDataResendCORSSMSMessage, {guardian_name: guardianFirstName}),
                      positiveTitle: t(translationKey.Confirm),
                      positiveCallback: () => axios.get(`/ln/resend_cors/${ youngPersonId }`).then(() => {
                        confirmAlert({
                          message: t(translationKey.LabelSMSDataResendCORSSMSConfirmation),
                          buttons: [
                            {
                              label: t(translationKey.LabelOk)
                            },
                          ],
                        });
                      }),
                      negativeTitle: t(translationKey.LabelCancel),
                    });
                  } }
                >
                  {t(translationKey.LabelSMSDataResendCORSSMS)}
                </StyledShowHideButton> }
                <Grid>
                  <Typography style={{display: 'inline', fontWeight: 'bold'}}>{t(translationKey.LabelSMSDataCORSURL)}</Typography>
                  {/* Read only input to hold the young persons CORS survey URL */}
                  <Input
                    style={{padding: '.5em',
                      backgroundColor: '#E6E6E6',
                      borderRadius: '.5em',
                      fontSize: '15px',
                      marginRight: '.5em'
                    }}
                    type="text"
                    value={smsData.survey_link}
                    readOnly />
                  <Button
                    onClick={handleCopyClick}
                    style={{
                      backgroundColor: '#E7EBF2',
                      borderRadius:'.5em',
                      width: '8em',
                      height: '2.5em',
                      padding: '1.75em'
                    }}
                  >
                    {!isCopied && <ContentCopyIcon style={{verticalAlign: 'middle', marginRight: '.5em'}} /> }
                    <span style={{fontSize: '15px', verticalAlign: 'middle'}}>{isCopied ? t(translationKey.LabelSMSDataURLCopied) : t(translationKey.LabelSMSDataURLCopy)}</span>
                  </Button>
                </Grid>
              </>
            }
          </Grid>
        </Grid>

      </Grid>

    </StyledDiv>
  );
};

export default withSize()(CORSSurveyResults);
