import React, { useState, useEffect, useCallback } from 'react';
import { CssBaseline, TextField, Typography, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import { styled } from '@mui/styles';
import { Link, useNavigate } from 'react-router-dom';
// import { useNavigate, Link } from "react-router"
import { axios, catchAxios } from '../../services/networkRequest';
import { Formik } from 'formik';
import { emailRegex, ROLES } from '../../services/helpers';
import Error from '../Error';
import RequiredInfoText from '../RequiredInfoText';
import { Games } from '../../constants';
import { Trans, useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';
import CloseIcon from '@mui/icons-material/Close';
import styles from '../../Styles'
import { ThemeProvider } from '@mui/material/styles';
import { StyledSubmitButton } from '../../StyledComponents';
import { useDispatch, useSelector } from 'react-redux';
import { login, pickGame } from '../../store/UserSlice';

const StyledLogo = styled('img')(({ theme }) => ({
  position: 'absolute',
  left: 32,
  width: '100px',
  height: 'auto',
}));

const StyledPaper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  "&:hover, &.MuiIconButton-root": {backgroundColor: "transparent"}
}));

const GlobalStyles = styled('div')(({ theme }) => ({
  body: {
    backgroundColor: theme.palette.common.white,
  },
}));

const Login = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);

  const ua = window.navigator.userAgent;
  const isIE = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
  const url = props.isPointPersonLogin ? '/login/point_person' : '/login';
  const forgotPasswordUrl = props.isPointPersonLogin ? '/point_person/forgot_password' : '/forgot_password';
  const [tfaModalOpen, setTFAModal] = useState(false);
  const [timer, setTimer] = useState(60);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("")
  const [otpSubmitting, setOtpSubmitting] = useState(false);
  const [email, setEmail] = useState("");

  const timeOutCallback = useCallback(() => {
    setTimer(currTimer => currTimer - 1);
  }, []);

  useEffect(() => {
    if(timer > 0) {
      const timeoutId = setTimeout(timeOutCallback, 1000);
      return () => clearTimeout(timeoutId)
    }
  }, [timer, timeOutCallback]);

  const resetTimer = () => {
    if(timer === 0) {
      setTimer(60)
    }
  }
  
  const handle2FAResend = () => {
    console.log("Timer Reset and OTP resent");
    axios.post("/resend-otp", {email: email})
    .then(data => {
      console.log(data.data);
      
    })
    .catch(err => {
      console.log(err);
    })
    resetTimer()
  }

  const handleOTPSubmit = () => {
    setOtpSubmitting(true)
    axios.post("/verify-otp", {email: email, otp: otp})
    .then(data => {
      dispatch(login({ email: email, ...data.data }))
      if(props.isPointPersonLogin) 
      {
        navigate('/point_person/dashboard');
      } 
      else 
      {
        redirectToPage(data.data, null)
      }   
      setOtpSubmitting(false)
    })
    .catch(err => {
      if(err.response.data.error)
      {
        setOtpError(err.response.data.error)
        if(err.response.data.error === "You have entered too many incorrect codes. Please try again later")
        {
          setOtpSubmitting(true)
        }
        else
        {
          setOtpSubmitting(false)
        }
      }
      else
        setOtpError(t(translationKey.TitleSorryProblem))

    })    
  }

  const handle2FAClose = () => {
    setTFAModal(false)
  }

  const handleOTPFieldChange = (e) => {
    const value = e.target.value;

    if (/^[1-9]\d{0,5}$/.test(value) || value === "") 
    {
      setOtp(value)
      setOtpError("")
    }
    else
    {
      setOtpError("Invalid OTP")
    } 
  }

  useEffect(function () {
    function loadPage(role) {
      if(role) {
        if (role === ROLES.admin) {
          navigate('/', {replace: true});
        } else if (role === ROLES.point_person) {
          navigate('/point_person/dashboard', {replace: true});
        } else {
          navigate('/game_choice', {replace: true});
        }
      }
    }

    if (user) {
      const { role } = user;

      loadPage(role);
    } else {
      axios.get('/get_user')
        .then(({ data }) => {
          dispatch(login(data));
          loadPage(data.role);
        })
        .catch(err => {
          console.log(err);
        });;
    }
  }, [user, navigate, dispatch]);

  const redirectToPage = (data, setFieldError) => {
    if (data.games) {
      if (data.games.length > 1) {
        navigate('/game_choice');
      } else if (data.games.length > 0) {
        const redirect = (game) => {
          dispatch(pickGame(game.shortGame));
          navigate('/')
        }
        redirect(Games[data.games[0]]);
      } else {
        setFieldError && setFieldError('network', t(translationKey.ErrorNoGameAccess));
      }
    } 
    else 
    {
      navigate('/');
    }
  }

  return (
    <ThemeProvider theme={styles}>

      {/* <StyledBackground component="main" maxWidth="xs"> */}
      <Container component="main" maxWidth="xs" style={{width: "100vw", height: "100vh"}}>
        <StyledLogo src='/BfB_logo_blue.png' alt='logo'/>

        { props.isPointPersonLogin ?
          <Link to="/login">{t(translationKey.TitleDistributorAdminSignIn)}</Link> :
          <Link to="/reports">{t(translationKey.TitleOrganisationAdminSignIn)}</Link>
        }

        <CssBaseline />
        <GlobalStyles />
        <StyledPaper>
          { isIE && <div>
            <img src='/redslime.png' alt='red slime' height="100px" />
            <br /><br />
            <Typography component="h1" variant="h4">
              {t(translationKey.ErrorUnsupportedBrowser)}
            </Typography>
            <br />
            <Typography>
              {t(translationKey.ErrorSuggestedBrowsers)}
              <br />
              <Trans
                i18nKey={translationKey.LinkNeedSupport}
                // eslint-disable-next-line
                components={[<a href="mailto:support@bfb-labs.com" />]}
              />
            </Typography>
            <br />
          </div> }

          <Typography component="h1" variant="h5">
            {props.isPointPersonLogin ? t(translationKey.TitleOrganisationAdminSignIn) : t(translationKey.TitleDistributorAdminSignIn)}
          </Typography>
          <RequiredInfoText />
          <Formik
            initialValues={ { email: '', password: '' } }
            validate={ values => {
              const errors = {};
              if (values.email && !emailRegex.test(values.email)) {
                errors.email = t(translationKey.ErrorInvalidEmail);
              }
              return errors;
            } }
            onSubmit={ ({ email, password }, { setSubmitting, setFieldError }) => {
              setSubmitting(true);
              setEmail(email)
              axios.post(url, { email, password }).then(data => {
                if(data.data.hasOwnProperty("otp_sent") && data.data.otp_sent === true)
                {
                  setTFAModal(true);
                }
                else
                {
                  dispatch(login({ email: email, ...data.data }));
                  if(props.isPointPersonLogin) 
                  {
                    navigate('/point_person/dashboard');
                  } 
                  else 
                  {
                    redirectToPage(data.data, setFieldError)
                  }
                }
              })
              .catch(err => {
                catchAxios(setFieldError)(err);
                setSubmitting(false);
              });
            }}
          >
            { ({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
              <form onSubmit={ handleSubmit }>
                <Error message={ errors['network'] } />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  type="email"
                  fullWidth
                  id="email"
                  label={t(translationKey.LabelEmailAddress)}
                  name="email"
                  autoComplete="email"
                  onChange={ handleChange }
                  onBlur={ handleBlur }
                  value={ values.email }
                  autoFocus
                />
                { errors.email && touched.email && errors.email }
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label={t(translationKey.LabelPassword)}
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={ handleChange }
                  onBlur={ handleBlur }
                  value={ values.password }
                />
                { errors.password && touched.password && errors.password }

                <Link to={forgotPasswordUrl}>{t(translationKey.LinkForgotPassword)}</Link>
                <StyledSubmitButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={ isSubmitting }
                  style={{marginTop: "24px", marginBottom: "16px"}}
                >
                  {t(translationKey.ButtonSignIn)}
                </StyledSubmitButton>
              </form>
            ) }
          </Formik>
        </StyledPaper>
        <Dialog
          open={tfaModalOpen}
          onClose={handle2FAClose}
        >
          <div style={{display: "flex", justifyContent: "space-between"}}>
            <DialogTitle>{t(translationKey.Title2FAHeading)}</DialogTitle>
            <StyledIconButton onClick={handle2FAClose} disableRipple={true}>
              <CloseIcon />
            </StyledIconButton>
          </div>
          <DialogContent>
            <DialogContentText>
            {t(translationKey.Title2FADescription)}
            </DialogContentText>
            <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="tfa-code"
                  label={t(translationKey.Label2FATextField)}
                  name="tfa-code"
                  autoFocus
                  onChange={handleOTPFieldChange}
                  helperText={otpError}
                  error={otpError.length > 0}
                  slotProps={{
                    htmlInput: { maxlength: 6, pattern: "[0-9]", inputMode: "numeric" },
                    formHelperText: { style: { color: "red" },
                  }}}
                  value={otp}
                  disabled={otpSubmitting}
                />
          </DialogContent>
          <DialogActions>
            <StyledSubmitButton
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={otpSubmitting}
              onClick={handleOTPSubmit}
            >
              {t(translationKey.Label2FAButton)}
            </StyledSubmitButton>
          </DialogActions>
          <DialogContent>
            <div>
              {t(translationKey.Label2FAResendHelp)}
              <u onClick={() => {if(timer === 0) handle2FAResend()}} >{t(translationKey.Label2FAResend)}</u>
              <b style={{color: "red"}}>{ timer < 1 ? "" : ` (in ${timer} second/s)`}</b>
            </div>
          </DialogContent>
        </Dialog>
      </Container>
    </ThemeProvider>
  );
}

export default Login;
