import Grid from '@mui/material/Grid2';
import Card from '@mui/material/Card';
import { greyBorderColor } from '../../Styles';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { months } from '../../constants';
import MenuItem from '@mui/material/MenuItem';
import MonthlyUsage from './MonthlyUsage';
import DailyUsage from './DailyUsage';
import React, { useEffect, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { axios } from '../../services/networkRequest';
import { getChain, subtractMonth } from '../../services/helpers';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';
import { StyledSelectBase } from '../../StyledComponents';

function UsageGraph(props) {
  const { playerName, userData, setUserData, chartUrl } = props;

  const currentDate = new Date();
  const month = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear();

  let startDate = new Date(userData.first_play);
  if (startDate.getDate() >= currentDate.getDate()) {
    startDate.setDate(currentDate.getDate() - 1);
  }
  const usageIntervals = [];
  let usageIndex = 0;
  for(let date = currentDate; date >= startDate; subtractMonth(date)) {
    const itemYear = date.getFullYear();
    const itemMonth = date.getMonth() + 1;
    if (itemYear === year - 1 && itemMonth === 12 && month === 1) {
      usageIntervals[usageIndex] = { year: itemYear, month: itemMonth };
      usageIndex++;
      usageIntervals[usageIndex] = { year: itemYear };
      usageIndex++;
    } else if (itemYear < year) {
      if (usageIndex > 0 && usageIntervals[usageIndex-1].year !== itemYear) {
        usageIntervals[usageIndex] = { year: itemYear };
        usageIndex++;
      }
    } else {
      usageIntervals[usageIndex] = { year: itemYear, month: itemMonth };
      usageIndex++;
    }
  }
  const [timePeriod, setTimePeriod] = useState(JSON.stringify({ month, year }));
  const usageIsMonthly = !JSON.parse(timePeriod).month;
  const usage = useMemo(() => getChain(userData, 'usage_data', timePeriod), [userData, timePeriod]);
  const timePeriodObj = useMemo(() => timePeriod ? JSON.parse(timePeriod) : null, [timePeriod]);
  const { t } = useTranslation();

  useEffect(() => {
    let url = chartUrl;
    if (timePeriodObj) {
      if (timePeriodObj.month) {
        url += `?month=${ timePeriodObj.month }`;
        if (timePeriodObj.year) {
          url += `&year=${ timePeriodObj.year }`;
        }
      } else if (timePeriodObj.year) {
        url += `?year=${ timePeriodObj.year }`;
      }
    }

    if (!usage) {
      axios.get(url)
        .then(({ data }) => {
          setUserData((prev) => ({
            ...prev,
            usage_data: {
              ...prev.usage_data,
              [timePeriod]: data,
            },
          }))
        });
    }
  }, [setUserData, timePeriodObj, timePeriod, usage, chartUrl]);

  return (
    <Grid size={{ xs: 12 }}>
      <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
        <CardContent>
          <Grid container sx={{display: 'flex', justifyContent: 'space-between' }}>
            <Grid size={{ xs: 9 }} sx={{width: 'auto'}}>
              <Typography align='left'
                color="textPrimary">{t(translationKey.LabelPlayersGameUsage, { player: playerName, timeframe: (usageIsMonthly ? 'monthly' : 'daily')})}</Typography>
            </Grid>
            <Grid size={{ xs: 3 }} sx={{ flexDirection: 'row', display: 'flex', width: 'auto', alignItems: "center" }}>
              <Typography align='right' color="textPrimary" style={ { marginRight: '5px' } }>{t(translationKey.LabelFilterBy)}</Typography>
              <StyledSelectBase
                sx={{ "& .MuiSelect-select": {
                  paddingTop: '5px', paddingBottom: "5px"
                } }}
                value={ timePeriod }
                onChange={ event => {
                  setTimePeriod(event.target.value);
                } }
                variant='outlined'
              >
                { usageIntervals.map((item, index) => {
                  let itemString;
                  if (index === 0) {
                    itemString = t(translationKey.LabelCurrentMonth, { month:  months[item.month - 1] });
                  } else if (index === 1) {
                    itemString = t(translationKey.LabelPreviousMonth, { month:  months[item.month - 1] });
                  } else if (item.month) {
                    itemString = months[item.month - 1];
                  } else {
                    itemString = item.year.toString();
                  }
                  const value = JSON.stringify({ month: item.month, year: item.year });
                  return (<MenuItem key={ index } value={ value }>{ itemString }</MenuItem>);
                }) }
              </StyledSelectBase>
            </Grid>
          </Grid>
          { usageIsMonthly ? <MonthlyUsage usage={ usage } /> : <DailyUsage usage={ usage } /> }
        </CardContent>
      </Card>
    </Grid>
  );
}

UsageGraph.propTypes = {
  playerName: PropTypes.string.isRequired,
  userData: PropTypes.object.isRequired,
  setUserData: PropTypes.func.isRequired,
  chartUrl: PropTypes.string.isRequired,
};

export default UsageGraph;
