import React from 'react';
import { styled } from '@mui/styles';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import { Wc, Event, ContactPhone, Assignment as AssignmentIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../../utilities/localisation/translationKeys';
import { StyledDivRoot } from '../../../StyledComponents';


const contactFontColor = '#4C4C71';
const iconColor = '#C2C3C4';

const StyledDiv = styled(StyledDivRoot)(({ theme }) => ({
  height: 120
}))


const ContactDetails = (props) => {
  const dateString = new Date(props.dob).toLocaleDateString();
  const { t } = useTranslation();
  return (
    <StyledDiv>
      <Grid container spacing={ 1 } style={ { marginTop: '8px' } }>
        <Grid size={{ xs: 4 }} style={ { borderRight: '1px solid #aaa' } }>
          <Grid container spacing={ 1 }>
            <Grid size={{ xs: 3 }}><Event htmlColor={ iconColor } style={ { margin: '8px' } } /></Grid>
            <Grid size={{ xs: 9 }}>
              <Typography color='textSecondary'>{ `${ props.first_name } D.O.B.` }</Typography>
              <Typography style={ { color: contactFontColor } }>{ dateString }</Typography>
            </Grid>
            <Grid size={{ xs: 3 }}><AssignmentIcon htmlColor={ iconColor } style={ { margin: '8px' } } /></Grid>
            <Grid size={{ xs: 9 }}>
              <Typography color='textSecondary'>{t(translationKey.LabelServiceUserReference)}</Typography>
              <Typography style={ { color: contactFontColor } }>{ props.service_user_reference }</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid size={{ xs: 4 }} style={ { borderRight: '1px solid #aaa' } }>
          <Grid container spacing={ 1 }>
          <Grid size={{ xs: 3 }}><Wc htmlColor={ iconColor } style={ { margin: '8px' } } /></Grid>
            <Grid size={{ xs: 9 }}>
              <Typography color='textSecondary'>{t(translationKey.LabelGender)}</Typography>
              <Typography style={ { color: contactFontColor } }>{ props.gender }</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid size={{ xs: 4 }}>
          <Grid container spacing={ 1 }>
            <Grid size={{ xs: 3 }}><ContactPhone htmlColor={ iconColor } style={ { margin: '8px' } } /></Grid>
            <Grid size={{ xs: 9 }}>
              <Typography color='textSecondary'>{t(translationKey.LabelMobile)}</Typography>
              <Typography
                style={ { color: contactFontColor } }>{ props.telephone || t(translationKey.ErrorMissingPhoneNumber) }</Typography>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </StyledDiv>
  );
};

export default ContactDetails;
