import React, { useState, useEffect } from 'react';
import { Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Link, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import { axios } from '../../services/networkRequest';
import { ThemeProvider } from '@mui/material/styles';
import { tableThemer } from '../../themes';
import { Delete } from '@mui/icons-material';
import { deleteAlert, getChain } from '../../services/helpers';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { activeColor, greyBorderColor, listBackgroundColor, notActiveColor } from '../../Styles';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';
import { useNavigate } from 'react-router-dom';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';

const Organisations = (props) => {

  const [organisations, setOrganisations] = useState([]);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const columns = [
      {
        accessorKey: "id",
        header: "#",
        cell: rowData => <Typography color="textPrimary" align={"left"}>{rowData.getValue()}</Typography>,
      },
      {
        accessorKey: "name",
        header: t(translationKey.LabelOrganisationName),
        cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
      },
      {
        accessorKey: "point_person.name",
        header: t(translationKey.TitlePointPersonName),
        cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
      },
      {
        accessorKey: "point_person.email",
        header: t(translationKey.TitlePointPersonEmail),
        cell: ({ row }) => (
          <Link href={`mailto://${getChain(row.original, "point_person", "email")}`}>
            {getChain(row.original, "point_person", "email")}
          </Link>
        ),
      },
      {
        header: t(translationKey.TitleCreatedAt),
        accessorFn: rowData =>  rowData.created_at && new Date(rowData.created_at).toDateString(),
        cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>
      },
      {
        header: t(translationKey.TitleValidUntil),
        accessorFn: rowData =>  rowData.ln_expiration_date && new Date(rowData.ln_expiration_date).toDateString(),
        cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>
      },
      {
        header: "Actions",
        cell: ({ row }) => (
          <IconButton onClick={() => deleteOrganisation(row.original)}>
            <Delete />
          </IconButton>
        ),
      },
    ]

    const table = useReactTable({
      data: organisations || [],
      columns,
      getCoreRowModel: getCoreRowModel(),
    });

  const loadData = () => {
    axios.get('organisations')
      .then(orgs => setOrganisations(orgs.data));
  }

  useEffect(() => {
    loadData()
  }, [])

  const handleOrganisationReq = ({ id }) => {
    axios.get(`/organisation/${ id }`)
      .then(res => {
        navigate(`/organisation/${ id }`, {state:{ organisation: res.data }});
      });
  }

  const deleteOrganisation = (org) => {
    deleteAlert(org.name, `/organisation/${ org.id }`, () => {
      navigate('/organisations');
    });
  }

  const getRowBorderColour = expiryDate => new Date(expiryDate) - new Date() > 0 ? activeColor : notActiveColor;

  return (
    <Container component="main" maxWidth="lg">
      <Button mb={ 10 } align="right" variant="contained" color="primary"
              onClick={ () => navigate('/organisation/create') }>{t(translationKey.ButtonCreateOrganisation)}</Button>
      <br />
      <br />
      <ThemeProvider theme={ tableThemer(organisations) }>
        <Paper>
          <TableContainer>
            <Table>
              <TableHead style={{ backgroundColor: listBackgroundColor }}>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <TableCell key={header.id}>
                        {flexRender(header.column.columnDef.header, header.getContext()) }
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody>
                {table.getRowModel().rows.map((row, index) => (
                  <React.Fragment key={row.id}>
                    <TableRow 
                      onClick={() => handleOrganisationReq(row.original)} 
                      style={{ cursor: "pointer", borderBottom: `1px solid ${greyBorderColor}`, borderLeft: `20px solid ${ getRowBorderColour(row.original.ln_expiration_date) }`, borderRight: `1px solid ${ greyBorderColor }`, borderTop: `1px solid ${ greyBorderColor }`}}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <TableCell key={cell.id} style={{ padding: '10px' }}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </TableCell>
                      ))}
                    </TableRow>
                    {/* Spacer Row */}
                    {index !== table.getRowModel().rows.length - 1 && (
                      <TableRow sx={{ height: "10px", backgroundColor: listBackgroundColor }}>
                        <TableCell colSpan={columns.length} sx={{padding: 0, height: "10px"}}/>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </ThemeProvider>
    </Container>
  );
}

export default Organisations;
