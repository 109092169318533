import React from 'react';
import { Grid2 as Grid, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';

const StyledInformationCard = styled(Grid)(({ theme }) => ({
  backgroundColor: '#EBEBEB',
  paddingLeft:'10px',
  paddingRight:'10px',
  paddingBottom:'10px',
  marginBottom:'16px',
  marginTop: '8px'
}));

const StyledInformationCardTextClasses = {
  fontStyle: 'italic',
  textAlign: 'left',
  color: 'rgba(0, 0, 0, 0.54)',
  fontSize: '0.8rem',
}

const StyledInformationCardText = styled(Typography)(({ theme }) => ({
  ...StyledInformationCardTextClasses
}));

const StyledInformationCardLink = styled('a')(({ theme }) => ({
  ...StyledInformationCardTextClasses
}));

const StyledInformationCardOL = styled('ol')(({ theme }) => ({
  ...StyledInformationCardTextClasses
}));

const StyledInformationCardLI = styled('li')(({ theme }) => ({
  ...StyledInformationCardTextClasses
}));

export function GBOInformation() {
  const { t } = useTranslation();

    return (
        <StyledInformationCard container spacing="2">
          <Grid>
            <StyledInformationCardText>{t(translationKey.TitleGboInformation)}</StyledInformationCardText>
          </Grid>

            <Grid>
              <StyledInformationCardText>
                {t(translationKey.TextGboInformationOne)}
              </StyledInformationCardText>
            </Grid>

            <Grid>
              <StyledInformationCardText>
                {t(translationKey.TextGboInformationTwo)}
              </StyledInformationCardText>
            </Grid>

            <Grid>
              <StyledInformationCardText display="inline">
                {t(translationKey.LabelLearnMore)}
              </StyledInformationCardText>
              <StyledInformationCardLink href="https://www.corc.uk.net/outcome-experience-measures/goal-based-outcomes-gbo/" target='_blank' rel="noopener noreferrer">
                https://www.corc.uk.net/outcome-experience-measures/goal-based-outcomes-gbo/
              </StyledInformationCardLink>
            </Grid>
          </StyledInformationCard>
    )
}

export function CORSInformationCard() {
    const { t } = useTranslation();

    return(
        <StyledInformationCard container spacing="2">
          <Grid>
            <StyledInformationCardText>
              {t(translationKey.TitleCorsInformation)}
            </StyledInformationCardText>
          </Grid>

            <Grid>
                <StyledInformationCardText>
                  {t(translationKey.TextCorsInformationOne)}
                </StyledInformationCardText>
            </Grid>

            <Grid>
                <StyledInformationCardOL >
                    <StyledInformationCardLI>{t(translationKey.ListCorsMe)}</StyledInformationCardLI>
                    <StyledInformationCardLI>{t(translationKey.ListCorsFamily)}</StyledInformationCardLI>
                    <StyledInformationCardLI>{t(translationKey.ListCorsSchool)}</StyledInformationCardLI>
                    <StyledInformationCardLI>{t(translationKey.ListCorsEverything)}</StyledInformationCardLI>
                </StyledInformationCardOL>
            </Grid>

            <Grid>
              <StyledInformationCardText>
                {t(translationKey.TextCorsInformationTwo)}
              </StyledInformationCardText>
            </Grid>

            <Grid>
              <StyledInformationCardText>
                {t(translationKey.TextCorsInformationThree)}
              </StyledInformationCardText>
            </Grid>

            <Grid size={{ xs: 12 }}>
              <StyledInformationCardText>{t(translationKey.TitleCutOffScores)}</StyledInformationCardText>
            </Grid>

            <Grid>
              <StyledInformationCardText>
                {t(translationKey.TextCutOffScoresInformation)}
              </StyledInformationCardText>
            </Grid>

            <Grid>
              <StyledInformationCardText display="inline">
                {t(translationKey.LabelLearnMore)}
              </StyledInformationCardText>
              <StyledInformationCardLink href="https://www.corc.uk.net/outcome-experience-measures/outcome-rating-scale-ors-child-outcome-rating-scale-cors/ " target='_blank'
               rel="noopener noreferrer">
                https://www.corc.uk.net/outcome-experience-measures/outcome-rating-scale-ors-child-outcome-rating-scale-cors/
              </StyledInformationCardLink>
            </Grid>
        </StyledInformationCard>
    )
}
