import React from 'react';
import { Grid2 as Grid, Container, Typography, Link } from '@mui/material';
import { styled } from '@mui/styles';
import { getChain } from '../../../services/helpers';
import { useViewport, Width } from '../../../utilities/viewport';
import { BorderContainer } from '../../Organisations/InfoDisplay/OrganisationAndPointPersonDetails';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../../utilities/localisation/translationKeys';
import { StyledContent, StyledSubDetailSection, StyledSubGrid, StyledSubtitleTypographyBase } from '../../../StyledComponents';

const StyledDetailedSection = styled(Typography)(({ theme }) => ({
  fontSize: '1.2rem',
  fontWeight: 'bold',
}))

export function EntityDetails({ entity }) {
  const { t } = useTranslation();

  const ln_keys = getChain(entity, 'ln_game_keys') || 0;
  const cots_keys = getChain(entity, 'cots_game_keys') || 0;

  const { width } = useViewport();
  const mobileView = width <= Width.Phone;

  const url = getChain(entity, "settings", 'self_sign_up_settings', 'url');
  const signupLink = url && `${process.env.REACT_APP_SIGNUP_URL}${url}`;
  return (
    <Grid container>
      <StyledSubGrid style={ { width: '100%' } }>
        <Container style={ { margin: '1rem 0', maxWidth: 'none' } }>
          <StyledDetailedSection component="h1" align="left" variant="body1">
            {t(translationKey.TitleEntity)}
          </StyledDetailedSection>
          <Grid container style={ { margin: '1rem 0' } } spacing={ 1 }>

            <Grid size={ {xs: mobileView ? 12 : 6} }>
              <Item title={t(translationKey.TitleName)}>{ getChain(entity, 'name') }</Item>
              <Item title={t(translationKey.TitleAddress)}>{ getChain(entity, 'address') }</Item>
              <Item title={t(translationKey.TitlePointPersonName)}>{ getChain(entity, 'point_person_name') }</Item>
              <Item title={t(translationKey.TitlePointPersonEmail)}>{ getChain(entity, 'point_person_email') }</Item>
              <Item title={t(translationKey.TitlePointPersonPhone)}>{ getChain(entity, 'point_person_phone') }</Item>
            </Grid>

            <Grid size={ {xs: mobileView ? 12 : 6} }>
              {
                ln_keys > 0 &&
                <BorderContainer>
                  <StyledSubDetailSection component="h3" align="left" variant="body1">
                    {t(translationKey.LabelLumiNova)}
                  </StyledSubDetailSection>
                  <Item title={t(translationKey.LabelLicencesAllocated)}>{ ln_keys }</Item>
                  {
                    signupLink && <Item title="Signup URL"><Link target="_blank" rel="noopener" href={signupLink}>{ signupLink }</Link></Item>
                  }
                </BorderContainer>
              }
              <div style={ { margin: '1rem' } } />
              {
                cots_keys > 0 &&
                <BorderContainer>
                  <StyledSubDetailSection component="h3" align="left" variant="body1">
                    {t(translationKey.LabelChampionsOfTheShengha)}
                  </StyledSubDetailSection>
                  <Item title={t(translationKey.LabelLicencesAllocated)}>{ cots_keys }</Item>
                </BorderContainer>
              }
            </Grid>
          </Grid>
        </Container>
      </StyledSubGrid>
    </Grid>
  )
}

function Item({ title, children }) {

  return (
    <Grid size={{ xs: 12 }} style={ { marginTop: '0.5rem' } }>
      <StyledSubtitleTypographyBase ><strong>{ title }</strong></StyledSubtitleTypographyBase>
      <StyledContent>{ children }</StyledContent>
    </Grid>
  )
}
