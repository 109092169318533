import { createSlice } from '@reduxjs/toolkit';
import { axios } from '../services/networkRequest';

const loadUser = () => {
  const userString = localStorage.getItem('user');
  let user = null;
  if (userString) {
    try {
        user = JSON.parse(userString);
        if (user.token) {
            axios.defaults.headers.common['Authorization'] = 'bearer ' + user.token;
        }
        else {
            user = null;
        }      
    } catch (error) {
      user = null;
    }
  }
  return user;
};

const initialState = {
    user: loadUser(),
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        login: (state, action) => {
            state.user = action.payload;
            localStorage.setItem('user', JSON.stringify(action.payload));
        },
        logout: (state) => {
            state.user = null;
            localStorage.removeItem('user');
            axios.defaults.headers.common['Authorization'] = null;
        },
        retrieveUser: (state) => {
            state.user = loadUser();
        },
        pickGame: (state, action) => {
        
            if (state.user) {
                state.user.currentGame = action.payload;
                localStorage.setItem('user', JSON.stringify(state.user));
            }
        },
    },
});

export const { login, logout, retrieveUser, pickGame } = userSlice.actions;
export default userSlice.reducer;
