import React from 'react';
import DistributorForm from './DistributorForm';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';
import { useLocation } from 'react-router-dom';

function DistributorEditPage(props){
  const { t } = useTranslation();
  const location = useLocation();
return(
  <DistributorForm
    { ...props }
    path={ `/distributor/${ location.state.distributor.id }` }
    distributor={ location.state.distributor }
    title={t(translationKey.TitleEditDistributorName, { name: location.state.distributor.name})}
  />
  )
}

export default DistributorEditPage;
