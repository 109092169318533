import React, { useEffect, useState } from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';
import { Card, CardContent, Container, Grid2 as Grid, Typography } from '@mui/material';
import { axios, CancelToken } from '../services/networkRequest';
import { getChain, identity } from '../services/helpers';
import { barColor, lightBlueCardColor, numberTextColor } from '../Styles';
import { format } from 'date-fns';
import 'react-confirm-alert/src/react-confirm-alert.css';
import DashboardReport from './DashboardReport';
import Footer from './content/Footer';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../utilities/localisation/translationKeys';
import { NoWrapTypography } from '../StyledComponents';

function Dashboard(props) {
  const isPrint = useMediaQuery('print');
  const { t } = useTranslation();
  const [dashData, setDashData] = useState();

  useEffect(() => {
    let cancel;
    const cancelToken = new CancelToken(c => {
      cancel = c;
    });

    axios.get('/ln/dashboard', {
      cancelToken,
    }).then(({ data }) => {
      setDashData(data);
    }).catch(identity);

    return () => {
      cancel();
    };
  }, []);

  const expiration_date = getChain(dashData, 'active_until');
  return (
    <Container component="main" maxWidth="lg">
      <Grid container spacing={ 2 }>
        <Grid size={ {xs: 8} }>
          <Typography color="textPrimary" align="left" variant='h5' style={ { fontWeight: 600, marginLeft: '10px' } }>
            {isPrint ? t(translationKey.TitleLumiNovaDashboardReport) : t(translationKey.TitleDashboard)}
          </Typography>
        </Grid>
        <Grid size={{xs: 4}}>
          <Typography color="textPrimary" align="right"
                      style={ { fontSize: '12px' } }>{t(translationKey.LabelLicensesProvidedBy, { name: getChain(dashData, 'organisation_name') || '' })}</Typography>
          { expiration_date && <Typography color="textSecondary" align="right"
                                           style={ { fontSize: '12px' } }>
            {t(translationKey.LabelActiveUntil, { date: format(new Date(expiration_date), 'EEEE, do MMMM yyyy') })}</Typography> }
        </Grid>
        <Grid size={{xs: 4 }}>
          <Card style={ { backgroundColor: lightBlueCardColor } }>
            <CardContent>
              <Grid container>
                <Grid size={{xs: 12}}>
                  <NoWrapTypography color="textPrimary" align="left">
                    {t(translationKey.LabelTotalLicensesAllocatedTo)}
                  </NoWrapTypography></Grid>
                <Grid size={{xs: 12}}>
                  <NoWrapTypography style={ { color: numberTextColor } }align="left">
                    { dashData ? dashData.entity_name : '' }
                  </NoWrapTypography>
                </Grid>
                <Grid size={{xs: 12}}>
                  <Typography style={ { color: numberTextColor, fontSize: '36px', fontWeight: 800 } } align="left">
                    { dashData ? dashData.ln_assigned_licenses : 0 }
                  </Typography>
                </Grid>
                <Grid size={{xs: 12}}>
                  <NoWrapTypography style={{ color: 'black', fontSize: "12px" }} align="left">
                    {t(translationKey.LabelTotalLicensesUsed, { used: (dashData ? dashData.total_used : 0) })}
                  </NoWrapTypography>
                </Grid>
                <Grid size={{xs: 12}}><div style={{ width: "100%", height: "20px", border: `1px solid ${barColor}` }}><div style={{ width: dashData ? `${(dashData.total_used / dashData.ln_assigned_licenses) * 100}%` : "0%", height: "20px", backgroundColor: barColor }}/></div></Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid size={{xs: 8}}>
          <Grid container spacing={3}>
            <Grid size={{xs:4}}>
              <Card style={{ backgroundColor: lightBlueCardColor }}>
                <CardContent>
                  <Grid container>
                    <Grid size={{xs:12}}><NoWrapTypography color="textPrimary" align="left">{t(translationKey.LabelTotalLicensesAvailable)}</NoWrapTypography></Grid>
                    <Grid size={{xs:12}}><Typography style={{ color: numberTextColor, fontSize: "60px", fontWeight: 800, paddingBottom: "28px" }}>{dashData ? dashData.ln_assigned_licenses - dashData.total_used : 0}</Typography></Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid size={{xs:4}}>
              <Card style={{ backgroundColor: lightBlueCardColor }}>
                <CardContent>
                  <Grid container>
                    <Grid size={{xs:12} }><NoWrapTypography color="textPrimary" align="left">{t(translationKey.LabelLicensesUsedByYou)}</NoWrapTypography></Grid>
                    <Grid size={{xs:12} }><Typography style={{ color: numberTextColor, fontSize: "60px", fontWeight: 800, paddingBottom: "28px" }}>{dashData ? dashData.your_licenses : 0}</Typography></Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid size={{xs:4}}>
              <Card style={{ backgroundColor: lightBlueCardColor }}>
                <CardContent>
                  <Grid container>
                    <Grid size={{xs:12}}><NoWrapTypography color="textPrimary" align="left">{t(translationKey.LabelActivePlayersThisWeek)}</NoWrapTypography></Grid>
                    <Grid size={{xs:12}}><Typography style={{ color: numberTextColor, fontSize: "60px", fontWeight: 800, paddingBottom: "28px" }}>{dashData ? dashData.yp_playing : 0}</Typography></Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div style={{ width: "100%", height: "4px", backgroundColor: numberTextColor, marginTop: "10px" }} />
      <DashboardReport />
      <Footer />
    </Container>
  );
}

export default Dashboard;
