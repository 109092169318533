import React, { useEffect, useState } from 'react';
import { Delete, RestoreFromTrash } from '@mui/icons-material';
import { Button, Container, IconButton, Link, Paper, Tab, Tabs, Typography, TableContainer, Table, TableCell, TableBody, TableHead, TableRow } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { tableThemer } from '../../themes';
import { axios } from '../../services/networkRequest';
import { deleteAlert } from '../../services/helpers';
import { activeColor, deactivatedColor, greyBorderColor, listBackgroundColor } from '../../Styles';

import 'react-confirm-alert/src/react-confirm-alert.css';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';
import { useNavigate } from 'react-router-dom';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useSelector } from 'react-redux';

function Admins(props) {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);
  const [admins, setAdmins] = useState();
  const [invites, setInvites] = useState();
  const [tab, setTab] = useState(0);
  const { t } = useTranslation();

  const getData = () => {
    if (tab === 0) return admins?.active || [];
    if (tab === 1) return invites || [];
    if (tab === 2) return admins?.deleted || [];
    return [];
  };

  const activeColumns = [
    {
      header: '#',
      accessorKey: 'id',
      cell: rowData => <Typography color="textPrimary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.TitleUserName),
      accessorKey: 'name',
      cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.TitleUserEmail),
      accessorKey: 'email',
      cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.TitleCreatedAt),
      accessorFn: rowData =>  rowData.created_at && new Date(rowData.created_at).toDateString(),
      cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: "Actions",
      cell: ({ row }) =>
        row.original.email !== user?.email && (
          <IconButton onClick={() => deleteAdmin(row.original)}>
            <Delete />
          </IconButton>
        ),
    },

  ];
  const invitedColumns = [
    {
      header: '#',
      accessorKey: 'id',
      cell: rowData => <Typography color="textPrimary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.TitleUserEmail),
      accessorKey: 'email',
      cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.TitleUserUniqueLink),
      accessorKey: 'unique_link',
      cell: ({ row }) => (
        <Link href={row.original.unique_link }>
          { row.original.unique_link }
        </Link>
      ),
    },
    {
      header: t(translationKey.TitleCreatedAt),
      accessorFn: rowData =>  rowData.created_at && new Date(rowData.created_at).toDateString(),
      cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: "Actions",
      cell: ({ row }) => (
        <IconButton onClick={() => deleteAdminInvite(row.original)}>
          <Delete />
        </IconButton>
      ),
    },
  ];
  const deletedColumns = [
    {
      header: '#',
      accessorKey: 'id',
      cell: rowData => <Typography color="textPrimary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.TitleUserName),
      accessorKey: 'name',
      cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.TitleUserEmail),
      accessorKey: t(translationKey.FieldEmail),
      cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.TitleDeactivatedOn),
      accessorKey: 'deleted_at',
      cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: "Actions",
      cell: ({ row }) => (
        <IconButton onClick={() => restoreAdmin(row.original)}>
          <RestoreFromTrash />
        </IconButton>
      ),
    }
  ];

  const activeTable = useReactTable({
    data: getData(),
    columns: activeColumns,
    getCoreRowModel: getCoreRowModel()
  });

  const invitedTable = useReactTable({
    data: getData(),
    columns: invitedColumns,
    getCoreRowModel: getCoreRowModel()
  });

  const deletedTable = useReactTable({
    data: getData(),
    columns: deletedColumns,
    getCoreRowModel: getCoreRowModel()
  });

  function deleteAdmin({ name, id }) {
    deleteAlert(name, `/admin/${ id }`, getAdmins);
  }

  function restoreAdmin({ name, id }) {
    confirmAlert({
      title: (t(translationKey.ConfirmRestoreName, { name: name })),
      message: t(translationKey.AlertIsUserSure),
      buttons: [
        {
          label: t(translationKey.ChoiceLabelYes),
          onClick: () => {
            axios.post(`/admin/restore/${ id }`)
              .then(getAdmins);
          },
        },
        {
          label: t(translationKey.ChoiceLabelNo),
          onClick: () => {
          },
        },
      ],
    });
  }

  function getAdmins() {
    axios.get('/admins')
      .then(({ data }) => setAdmins(data));
  }

  function getAdminInvites() {
    axios.get('/invites/admin')
      .then(({ data }) => setInvites(data));
  }

  function deleteAdminInvite({ name, id }) {
    deleteAlert(name, `/invites/admin/${ id }`, getAdminInvites);
  }

  useEffect(() => {
    getAdmins();
    getAdminInvites();
  }, []);
  // useEffect(() => {
  // }, []);

  return (
    <Container component="main" maxWidth="lg">
      <Button mb={ 10 } align="right" variant="contained" color="primary"
              onClick={ () => navigate('/admin/invite') }>{t(translationKey.ButtonInviteNewUser)}</Button>
      <br />
      <br />
      <Tabs
        value={ tab }
        onChange={ (event, value) => {
          setTab(value);
        }}
        sx={{
          "& .MuiTabs-indicator": {
            backgroundColor: "#f50057",
          },

        }}>
        <Tab label={t(translationKey.TabActive)} value={ 0 } sx={{  color: 'initial',
          "&.Mui-selected": {
            color: 'initial',
          },}}/>
        <Tab label={t(translationKey.TabInvited)} value={ 1 } sx={{  color: 'initial',
          "&.Mui-selected": {
            color: 'initial',
          },}}/>
        <Tab label={t(translationKey.TabDeleted)} value={ 2 } sx={{  color: 'initial',
          "&.Mui-selected": {
            color: 'initial',
          },}}/>
      </Tabs>
      <ThemeProvider theme={
        tableThemer(((i) => [admins && admins.active, invites, admins && admins.deleted][i])(tab))
      }>
        { tab === 0 &&
        <Paper>
          <TableContainer>
            <Table>
              <TableHead style={{ backgroundColor: listBackgroundColor }}>
                {activeTable.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <TableCell key={header.id}>
                        {flexRender(header.column.columnDef.header, header.getContext()) }
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody>
                {activeTable.getRowModel().rows.map((row, index) => (
                  <React.Fragment key={row.id}>
                    <TableRow
                      style={{ borderBottom: `1px solid ${greyBorderColor}`, borderLeft: `20px solid ${ activeColor }`, borderRight: `1px solid ${ greyBorderColor }`, borderTop: `1px solid ${ greyBorderColor }`}} 
                    >
                      {row.getVisibleCells().map((cell) => (
                        <TableCell key={cell.id} style={{ padding: '10px' }}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </TableCell>
                      ))}
                    </TableRow>
                    {/* Spacer Row */}
                    {index !== activeTable.getRowModel().rows.length - 1 && (
                      <TableRow sx={{ height: "10px", backgroundColor: listBackgroundColor }}>
                        <TableCell colSpan={activeColumns.length} sx={{padding: 0, height: "10px"}}/>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
                {activeTable.getRowModel().rows.length === 0 && (
                  <TableRow
                    style={{ border: `1px solid ${greyBorderColor}`}} 
                  >
                  <TableCell colSpan={activeColumns.length} sx={{padding: 0, height: "10px"}}>
                    <Typography color="textSecondary" align={"center"}>No records to display</Typography>
                  </TableCell>
                </TableRow>

                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        }
        { tab === 1 &&
        <Paper>
          <TableContainer>
            <Table>
              <TableHead style={{ backgroundColor: listBackgroundColor }}>
                {invitedTable.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <TableCell key={header.id}>
                        {flexRender(header.column.columnDef.header, header.getContext()) }
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody>
                {invitedTable.getRowModel().rows.map((row, index) => (
                  <React.Fragment key={row.id}>
                    <TableRow
                      style={{ borderBottom: `1px solid ${greyBorderColor}`, borderLeft: `20px solid ${ greyBorderColor }`, borderRight: `1px solid ${ greyBorderColor }`, borderTop: `1px solid ${ greyBorderColor }`}} 
                    >
                      {row.getVisibleCells().map((cell) => (
                        <TableCell key={cell.id} style={{ padding: '10px' }}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </TableCell>
                      ))}
                    </TableRow>
                    {/* Spacer Row */}
                    {index !== invitedTable.getRowModel().rows.length - 1 && (
                      <TableRow sx={{ height: "10px", backgroundColor: listBackgroundColor }}>
                        <TableCell colSpan={invitedColumns.length} sx={{padding: 0, height: "10px"}}/>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
                {invitedTable.getRowModel().rows.length === 0 && (
                  <TableRow
                    style={{ border: `1px solid ${greyBorderColor}`}} 
                  >
                  <TableCell colSpan={invitedColumns.length}>
                    <Typography color="textSecondary" align={"center"}>No records to display</Typography>
                  </TableCell>
                </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        }
        { tab === 2 &&
        <Paper>
          <TableContainer>
            <Table>
              <TableHead style={{ backgroundColor: listBackgroundColor }}>
                {deletedTable.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <TableCell key={header.id}>
                        {flexRender(header.column.columnDef.header, header.getContext()) }
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody>
                {deletedTable.getRowModel().rows.map((row, index) => (
                  <React.Fragment key={row.id}>
                    <TableRow
                      style={{ borderBottom: `1px solid ${greyBorderColor}`, borderLeft: `20px solid ${ deactivatedColor }`, borderRight: `1px solid ${ greyBorderColor }`, borderTop: `1px solid ${ greyBorderColor }`}} 
                    >
                      {row.getVisibleCells().map((cell) => (
                        <TableCell key={cell.id} style={{ padding: '10px' }}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </TableCell>
                      ))}
                    </TableRow>
                    {/* Spacer Row */}
                    {index !== deletedTable.getRowModel().rows.length - 1 && (
                      <TableRow sx={{ height: "10px", backgroundColor: listBackgroundColor }}>
                        <TableCell colSpan={deletedColumns.length} sx={{padding: 0, height: "10px"}}/>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
                {deletedTable.getRowModel().rows.length === 0 && (
                  <TableRow
                    style={{ border: `1px solid ${greyBorderColor}`}} 
                  >
                    <TableCell colSpan={deletedColumns.length} >
                      <Typography color="textSecondary" align={"center"}>No records to display</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        }
      </ThemeProvider>
    </Container>
  );
}

export default Admins;
