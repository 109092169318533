import React, { useState, useEffect } from 'react';
import { IconButton, Link, Paper, Tab, Tabs, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { tableThemer } from '../../themes';
import { Delete, RestoreFromTrash } from '@mui/icons-material';
import * as PropTypes from 'prop-types';
import { activeColor, deactivatedColor, invitedColor, listBackgroundColor, greyBorderColor } from '../../Styles';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'; 

export default function DistributorList({ distributors, invitees, deleteDistributor, deleteInvitee, restoreDistributor }) {
  const [tab, setTab] = useState(0);
  const { t } = useTranslation();
  const [deletedDistributors, setDeletedDistributors] = useState(distributors.filter(x => x.deleted_at !== null));
  const [liveDistributors, setLiveDistributors] = useState(distributors.filter(x => x.deleted_at === null));


  // const deletedDistributors = distributors.filter(x => x.deleted_at !== null);
  // const liveDistributors = distributors.filter(x => x.deleted_at === null);
  useEffect(() => {
    setDeletedDistributors(distributors.filter(x => x.deleted_at !== null))
    setLiveDistributors(distributors.filter(x => x.deleted_at === null))
  }, [distributors])

  const liveDistributorsColumns = [
    {
      header: '#',
      accessorKey: 'id',
      cell: rowData => <Typography color="textPrimary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.LabelDistributorName),
      accessorKey: 'name',
      cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.LabelDistributorEmail),
      accessorKey: 'email',
      cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.TitleCreatedAt),
      accessorFn: rowData =>  rowData.created_at && new Date(rowData.created_at).toDateString(),
      cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: "Actions",
      cell: ({ row }) => (
          <IconButton onClick={() => deleteDistributor(row.original)}>
            <Delete />
          </IconButton>
        ),
    },

  ];
  const inviteesColumns = [
    {
      header: '#',
      accessorKey: 'id',
      cell: rowData => <Typography color="textPrimary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      id: t(translationKey.TitleInviteeName),
      header: t(translationKey.TitleInviteeName),
      accessorFn: item => `${ item.first_name } ${ item.last_name }`,
      cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.TitleInviteeEmail),
      accessorKey: 'email',
      cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.TitleInviteeLink),
      accessorKey: 'unique_link',
      cell: ({ row }) => (
        <Link href={ row.original.unique_link }>
          { row.original.unique_link }
        </Link>
      ),
    },
    {
      header: t(translationKey.TitleInvitedAt),
      accessorFn: rowData =>  rowData.created_at && new Date(rowData.created_at).toDateString(),
      cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: "Actions",
      cell: ({ row }) => (
        <IconButton onClick={() => deleteInvitee(row.original)}>
          <Delete />
        </IconButton>
      ),
    },
  ];
  const deletedDistributorsColumns = [
    {
      header: '#',
      accessorKey: 'id',
      cell: rowData => <Typography color="textPrimary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.LabelDistributorName),
      accessorKey: 'name',
      cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.LabelDistributorEmail),
      accessorKey: "email",
      cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.TitleDeactivatedOn),
      accessorFn: rowData =>  rowData.deleted_at && new Date(rowData.deleted_at).toDateString(),
      cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: "Actions",
      cell: ({ row }) => (
        <IconButton onClick={() => restoreDistributor(row.original)}>
          <RestoreFromTrash />
        </IconButton>
      ),
    }
  ];

  const liveDistributorsTable = useReactTable({
    data: liveDistributors,
    columns: liveDistributorsColumns,
    getCoreRowModel: getCoreRowModel()
  });

  const inviteesTable = useReactTable({
    data: invitees,
    columns: inviteesColumns,
    getCoreRowModel: getCoreRowModel()
  });

  const deletedDistributorsTable = useReactTable({
    data: deletedDistributors,
    columns: deletedDistributorsColumns,
    getCoreRowModel: getCoreRowModel()
  });



  return (
    <div>
      <Tabs
        value={ tab }
        onChange={ (event, value) => setTab(value) }>
        <Tab label={t(translationKey.TabActive)} value={ 0 } />
        <Tab label={t(translationKey.TabInvited)} value={ 1 } />
        <Tab label={t(translationKey.TabDeactivated)} value={ 2 } />
      </Tabs>
      <ThemeProvider theme={ tableThemer(((i) => [liveDistributors, invitees, deletedDistributors][i])(tab)) }>
        { tab === 0 &&
        <Paper>
          <TableContainer>
            <Table>
              <TableHead style={{ backgroundColor: listBackgroundColor }}>
                {liveDistributorsTable.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <TableCell key={header.id}>
                        {flexRender(header.column.columnDef.header, header.getContext()) }
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody>
                {liveDistributorsTable.getRowModel().rows.map((row, index) => (
                  <React.Fragment key={row.id}>
                    <TableRow
                      style={{ cursor: 'pointer', borderBottom: `1px solid ${greyBorderColor}`, borderLeft: `20px solid ${ activeColor }`, borderRight: `1px solid ${ greyBorderColor }`, borderTop: `1px solid ${ greyBorderColor }`}} 
                    >
                      {row.getVisibleCells().map((cell) => (
                        <TableCell key={cell.id} style={{ padding: '10px' }}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </TableCell>
                      ))}
                    </TableRow>
                    {index !== liveDistributorsTable.getRowModel().rows.length - 1 && (
                      <TableRow sx={{ height: "10px", backgroundColor: listBackgroundColor }}>
                        <TableCell colSpan={liveDistributorsColumns.length} sx={{padding: 0, height: "10px"}}/>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
                {liveDistributorsTable.getRowModel().rows.length === 0 && (
                  <TableRow
                    style={{ border: `1px solid ${greyBorderColor}`}} 
                  >
                    <TableCell colSpan={liveDistributorsColumns.length} >
                      <Typography color="textSecondary" align={"center"}>No records to display</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        }
        { tab === 1 &&
        <Paper>
          <TableContainer>
            <Table>
              <TableHead style={{ backgroundColor: listBackgroundColor }}>
                {inviteesTable.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <TableCell key={header.id}>
                        {flexRender(header.column.columnDef.header, header.getContext()) }
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody>
                {inviteesTable.getRowModel().rows.map((row, index) => (
                  <React.Fragment key={row.id}>
                    <TableRow 
                      style={{ cursor: 'pointer', borderBottom: `1px solid ${greyBorderColor}`, borderLeft: `20px solid ${ invitedColor }`, borderRight: `1px solid ${ greyBorderColor }`, borderTop: `1px solid ${ greyBorderColor }`}} 
                    >
                      {row.getVisibleCells().map((cell) => (
                        <TableCell key={cell.id} style={{ padding: '10px' }}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </TableCell>
                      ))}
                    </TableRow>
                    {/* Spacer Row */}
                    {index !== inviteesTable.getRowModel().rows.length - 1 && (
                      <TableRow sx={{ height: "10px", backgroundColor: listBackgroundColor }}>
                        <TableCell colSpan={inviteesColumns.length} sx={{padding: 0, height: "10px"}}/>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
                {inviteesTable.getRowModel().rows.length === 0 && (
                  <TableRow
                    style={{ border: `1px solid ${greyBorderColor}`}} 
                  >
                    <TableCell colSpan={inviteesColumns.length} >
                      <Typography color="textSecondary" align={"center"}>No records to display</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        }
        { tab === 2 &&
        <Paper>
          <TableContainer>
            <Table>
              <TableHead style={{ backgroundColor: listBackgroundColor }}>
                {deletedDistributorsTable.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <TableCell key={header.id}>
                        {flexRender(header.column.columnDef.header, header.getContext()) }
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody>
                {deletedDistributorsTable.getRowModel().rows.map((row, index) => (
                  <React.Fragment key={row.id}>
                    <TableRow
                      style={{ cursor: 'pointer', borderBottom: `1px solid ${greyBorderColor}`, borderLeft: `20px solid ${ deactivatedColor }`, borderRight: `1px solid ${ greyBorderColor }`, borderTop: `1px solid ${ greyBorderColor }`}} 
                    >
                      {row.getVisibleCells().map((cell) => (
                        <TableCell key={cell.id} style={{ padding: '10px' }}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </TableCell>
                      ))}
                    </TableRow>
                    {/* Spacer Row */}
                    {index !== deletedDistributorsTable.getRowModel().rows.length - 1 && (
                      <TableRow sx={{ height: "10px", backgroundColor: listBackgroundColor }}>
                        <TableCell colSpan={deletedDistributorsColumns.length} sx={{padding: 0, height: "10px"}}/>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
                {deletedDistributorsTable.getRowModel().rows.length === 0 && (
                  <TableRow
                    style={{ border: `1px solid ${greyBorderColor}`}} 
                  >
                    <TableCell colSpan={deletedDistributorsColumns.length} >
                      <Typography color="textSecondary" align={"center"}>No records to display</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        }
      </ThemeProvider>
    </div>);
}

DistributorList.propTypes = {
  distributors: PropTypes.arrayOf(PropTypes.object).isRequired,
  invitees: PropTypes.arrayOf(PropTypes.object).isRequired,
  deleteDistributor: PropTypes.func.isRequired,
  deleteInvitee: PropTypes.func.isRequired,
  clickDistributor: PropTypes.func.isRequired,
  restoreDistributor: PropTypes.func,
};
