import { styled } from '@mui/styles';
import React from 'react';
import { getChain } from '../services/helpers';
import { Box, Card, Container, Grid2 as Grid, IconButton, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CustomSupportInfo } from './Error';
import { StyledErrorContentBase, StyledTitleTypographyBase } from '../StyledComponents';

const StyledGameLogo = styled('img')(({ theme }) => ({
  width: '100%',
  maxWidth: '10rem',
  margin: '1rem 0',
}));

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: '1rem',
}));

const StyledModal = styled(Box)(({ theme }) => ({
  background: '#ffffff',
  padding: '0 1rem 2rem 1rem',
}));

const StyledModalContainer = styled(Modal)(({ theme }) => ({
  display: 'grid',
  placeItems: 'center',
}));

export default function ErrorModal({ open, handleClose, customSupportInfo, errorResponse }) {
  const showCustomSupportInfo = getChain(customSupportInfo, 'enable');

  return (
    <StyledModalContainer
      open={ open }
      onClose={ handleClose }
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <StyledCard>
        <StyledModal sx={ { width: '100%', maxWidth: 700 } }>
          <Container component="main" maxWidth="md">
            <Grid container justify="center">
              <Grid size={{ xs: 12 }}>
                <div style={ { display: 'grid', placeItems: 'end', width: '100%' } }>
                  <IconButton aria-label="close"
                              onClick={ handleClose }>
                    <CloseIcon />
                  </IconButton>
                </div>
              </Grid>

              <Grid container justify="center">
                <StyledGameLogo src="/ln_logo_transparent_background.png" alt="Lumi Nova logo" />
              </Grid>

              <Grid container style={ { display: 'block', textAlign: 'center' } }>
                <StyledTitleTypographyBase component="h3" variant="h6">
                  { errorResponse.title }
                </StyledTitleTypographyBase>
                <StyledErrorContentBase component="p" variant="h6">
                  { errorResponse.body }
                </StyledErrorContentBase>
              </Grid>

              { customSupportInfo && showCustomSupportInfo &&
              <CustomSupportInfo customSupportInfo={ customSupportInfo } /> }

            </Grid>
          </Container>
        </StyledModal>
      </StyledCard>
    </StyledModalContainer>
  );
}