import React from 'react';
import { styled } from '@mui/styles';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryTheme } from 'victory';
import { withSize } from 'react-sizeme';
import { barColor } from '../../Styles';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';
import { StyledCentreCircularProgress, StyledDivRoot } from '../../StyledComponents';


const StyledDiv = styled(StyledDivRoot)(({ theme }) => ({
  height: 230,
  width: 600,
  paddingBottom: 30
}))

const GuardianAnxietySurveyResults = (props) => {
  const { t } = useTranslation();

  if (!props.results) {
    return <StyledDiv>
      <StyledCentreCircularProgress />
    </StyledDiv>;
  }

  const chartData = props.results.map((item, index) => {
    // Axis label hackery from first item date to avoid keeping lookup tables
    const date = new Date(item.date);
    const dateString = date.toDateString();
    const parts = dateString.split(' ');
    const axisLabel = item.goal_name + ' - ' + parts[2] + ' ' + parts[1];
    return {
        day: index + 1,
        axisLabel,
        finalScore: item.final_score
    }
  });

  const dayValues = chartData.map(item => item.day);
  const labels = chartData.map(item => item.axisLabel);
  let tickValues = [0, 5, 10, 15, 20];

  return (
    <StyledDiv>
      <VictoryChart
        height={ 270 }
        width={ props.size.width }
        domainPadding={ 50 }
        theme={ VictoryTheme.material }
      >
        <VictoryAxis crossAxis
                     tickValues={ dayValues }
                     tickFormat={ labels }
                     fixLabelOverlap={chartData.length > 3}
                     style={ { grid: { stroke: 'none' }, tickLabels: { fontSize: '10px' } } }
        />
        <VictoryAxis dependentAxis crossAxis
                     tickValues={tickValues}
                     label={t(translationKey.LabelGuardianSurveyScasScore)}
                     style={ { axisLabel: { padding: 35 } } }
        />
        <VictoryBar
          tickValues={ dayValues }
          style={ { data: { fill: barColor, width: Math.min(80, props.size.width / (chartData.length + 4)) } } }
          data={ chartData }
          x={t(translationKey.LabelDay)}
          y={t(translationKey.LabelFinalScore)}
        />
      </VictoryChart>
    </StyledDiv>
  );
};

export default withSize()(GuardianAnxietySurveyResults);
