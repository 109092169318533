import React, { useEffect, useState } from 'react';
import { styled } from '@mui/styles';
import { ThemeProvider } from '@mui/material/styles';
import { tableThemer } from '../../../themes';
import { Card, CardContent, Container, Grid2 as Grid, Paper, Typography, Table, TableContainer, TableBody, TableHead, TableRow, TableCell } from '@mui/material';
import { axios } from '../../../services/networkRequest';
import MenuItem from '@mui/material/MenuItem';
import { VideogameAssetOutlined as GameAsset, Timer } from '@mui/icons-material';
import MonthlyUsage from '../MonthlyUsage';
import DailyUsage from '../DailyUsage';
import GBOResults from '../../Distributors/GBOResults';
import GuardianAnxietySurveyResults from '../../Distributors/GuardianAnxietySurveyResults';
import { greyBorderColor, listBackgroundColor, numberTextColor } from '../../../Styles';
import { getChain, subtractMonth } from '../../../services/helpers';
import { months } from '../../../constants';
import ExitButton from '../../ExitButton';
import { GBOInformation, CORSInformationCard } from '../../Distributors/GraphInformationCards';
import CORSSurveyResults from '../../Distributors/CORSSurveyResults';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../../utilities/localisation/translationKeys';
import { TypographyAverageTime, TypographyHoursAndMinutes } from '../../Typography/TypographyNumberSpan';
import { useLocation, useParams } from 'react-router-dom';
import { StyledSelectBase } from '../../../StyledComponents';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';

const order = new Proxy({
  'complete': 1,
  'started': 2,
  'incomplete': 3,
}, {
  get: (target, key) => target[key] || 4,
});

const sorting = progress => {
  return progress && progress.sort((a, b) => order[a.status] - order[b.status]);
};
const StyledGBOContainerGrid = styled(Grid)(({ theme }) => ({
  paddingBottom: '60px',
}));

const StyledHeadingGrid = styled(Grid)(({ theme }) => ({
  marginBottom: '12px',
}));

const StyledItalicGrid = styled(Grid)(({ theme }) => ({
  fontStyle: 'italic',
}));

const StyledQuestionGrid = styled(Grid)(({ theme }) => ({
  paddingRight: '25px',
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: '1px solid grey',
}));

const StyledChangeData = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  display: 'inline-block',
  marginTop: '6px',
  marginBottom: '6px',
}));

const StyledShowHideButton = styled(Button)(({ theme }) => ({
  display: 'inline-block',
  textAlign: 'right',
  float: 'right',
  marginBottom: '16px',
  marginTop: '16px',
  '&:hover': {
    backgroundColor: '#0079C6',
  },
  '&:active': {
    backgroundColor: 'white',
    borderColor: 'white',
    color: '#0079C6',
  },
}));

const StyledBottomBorder = styled(Grid)(({ theme }) => ({
  borderBottom: '1px solid #ccc',
}));



export default function YoungPersonReport(props) {
  const location = useLocation();
  const params = useParams();
  const rowData = location.state;
  const youngPersons = [rowData];
  const young_person_id = params.id;
  const [goalResults, setGoalResults] = useState(undefined);
  const [gboResponses, setGBOResponses] = useState(undefined);
  const [corsSurveys, setCorsSurveys] = useState(undefined);
  const [name, setName] = useState(undefined);
  const currentDate = new Date();
  const month = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear();
  const [userData, setUserData] = useState(rowData);
  const [timePeriod, setTimePeriod] = useState(JSON.stringify({ month, year }));
  const [showResults, setShowResults] = useState(true);
  const { t } = useTranslation();

  const usage = getChain(userData, 'usage_data', timePeriod);

  const columns = [
    {
      id: t(translationKey.TitlePlayingTheGame),
      header: t(translationKey.TitlePlayingTheGame),
      accessorFn: rowData => `${rowData.first_name} ${rowData.last_name}`,
      cell: rowData => <Typography color="textPrimary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.TitleInvited),
      accessorFn: rowData =>  rowData.created_at && new Date(rowData.created_at).toDateString(),
      cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.TitleStartedGame),
      accessorFn: rowData =>  rowData.first_play && new Date(rowData.first_play).toDateString(),
      cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.TitleChallenges),
      accessorKey: 'counts.challenges',
      cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.TitleGoals),
      accessorKey: 'counts.goals',
      cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
    },
    {
      header: t(translationKey.TitleGameKey),
      field: 'game_key',
      cell: rowData => <Typography color="textSecondary" align={"left"}>{rowData.getValue()}</Typography>,
    },
  ]

  const table = useReactTable({
    data: youngPersons ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    axios.get(`/ln/young_persons/anxiety_results/${ young_person_id }`).then(({ data }) => {
      setGoalResults(data.goals);
      setGBOResponses(data.gbos);
      setCorsSurveys(data.cors_surveys);
      setName(`${ data.first_name } ${ data.last_name }`);
    });
  }, [young_person_id]);

  useEffect(() => {
    if (!usage) {
      let url = `/ln/young_persons/${ userData.id }/chart`;
      if (timePeriod) {
        const timePeriodObj = JSON.parse(timePeriod);
        if (timePeriodObj.month) {
          url += `?month=${timePeriodObj.month}`;
          if (timePeriodObj.year) {
            url += `&year=${timePeriodObj.year}`;
          }
        } else if (timePeriodObj.year) {
          url += `?year=${timePeriodObj.year}`;
        }
      }

      axios.get(url)
        .then(({ data }) => {
          userData['usage_data'] = {
            ...userData['usage_data'],
            [timePeriod]: data,
          };
          const newUserData = { ...userData };
          setUserData(newUserData);
        });
    }
  }, [userData, timePeriod, usage]);

  useEffect(() => {
    setTimeout(window.print, 2000);
  }, []);

  const player = userData.first_name;
  const progress = sorting(getChain(userData, 'stats', 'progress'));
  const gameTime = getChain(userData, 'stats', 'game_time');
  const lastUsedDateString = gameTime && gameTime.lastLoggedIn && new Date(gameTime.lastLoggedIn).toLocaleDateString();
  const averageTime = gameTime ? gameTime.timeAverage : '';
  const totalTime = gameTime && gameTime.timeSpent;
  const totalSessions = gameTime && averageTime > 0 ? Math.round(totalTime / averageTime) : '';
  const totalHours = gameTime ? Math.floor(totalTime / 60) : '';
  const totalMinutes = gameTime ? Math.round(totalTime - (totalHours * 60)) : '';
  let startDate = new Date(userData.first_play);
  if (startDate.getDate() >= currentDate.getDate()) {
    startDate.setDate(currentDate.getDate() - 1);
  }
  const usageIntervals = [];
  let usageIndex = 0;
  for (let date = currentDate; date >= startDate; subtractMonth(date)) {
    const itemYear = date.getFullYear();
    const itemMonth = date.getMonth() + 1;
    if (itemYear === year - 1 && itemMonth === 12 && month === 1) {
      usageIntervals[usageIndex] = { year: itemYear, month: itemMonth };
      usageIndex++;
      usageIntervals[usageIndex] = { year: itemYear };
      usageIndex++;
    } else if (itemYear < year) {
      if (usageIndex > 0 && usageIntervals[usageIndex - 1].year !== itemYear) {
        usageIntervals[usageIndex] = { year: itemYear };
        usageIndex++;
      }
    } else {
      usageIntervals[usageIndex] = { year: itemYear, month: itemMonth };
      usageIndex++;
    }
  }
  const usageIsMonthly = !JSON.parse(timePeriod).month;


  if (!(goalResults && gboResponses)) {
    return null;
  }

  return (
    <Container id="unzoom" component="main" maxWidth="md"
               style={ { backgroundColor: 'white', paddingBottom: '40px', paddingLeft: '0px', paddingRight: '0px' } }>
      <StyledGBOContainerGrid container direction='column' alignItems='flex-start' justify='center'>
        <Grid container size={ {xs:12} }>
          <Grid size={{ xs: 11 }}>
            <Typography variant="h5" align="left" style={ { marginBottom: '10px' } }>
              {t(translationKey.TitleLumiNovaYoungReport)}
            </Typography>
          </Grid>
          {rowData.service_user_reference &&
          <Grid size={{ xs: 11 }}>
            <Typography variant="h6" align="left" style={ { marginBottom: '30px' } }>
              {t(translationKey.LabelServiceUserRefDetailed, { ref: rowData.service_user_reference})}
            </Typography>
          </Grid>
          }
          <ExitButton />
        </Grid>
        <ThemeProvider theme={ tableThemer(youngPersons) }>
          <Paper>
            <TableContainer>
              <Table>
                <TableHead  style={{ backgroundColor: listBackgroundColor }}>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <TableRow key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <TableCell key={header.id}>
                          {flexRender(header.column.columnDef.header, header.getContext()) }
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableHead>
                <TableBody>
                  {table.getRowModel().rows.map((row, index) => (
                    <React.Fragment key={row.id}>
                      <TableRow
                        style={{ cursor: 'pointer', border: `1px solid ${greyBorderColor}` }}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <TableCell key={cell.id} style={{ padding: '10px' }}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </TableCell>
                        ))}
                      </TableRow>
                      {/* Spacer Row */}
                      {index !== table.getRowModel().rows.length - 1 && (
                        <TableRow sx={{ height: "10px", backgroundColor: "transparent" }}>
                          <TableCell colSpan={columns.length} sx={{padding: 0, height: "10px"}}/>
                        </TableRow>
                      )}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </ThemeProvider>

        <Grid container style={{ width: 'inherit', margin: 0, paddingTop: '30px', textAlign: 'left' }}
          spacing={1}>
          {userData.first_play && <>
            <Grid size={{ xs: 12 }}>
              <Grid container>
                <Grid size={{ xs: 6 }}><Typography color="textPrimary">{t(translationKey.TitlePlayerGameTime, { player })}</Typography></Grid>
                <Grid size={{ xs: 6 }}><Typography color="textSecondary"
                  align="right">{(t(translationKey.TitleLastUsedDate, { lastUsedDateString : lastUsedDateString|| '' }))}</Typography></Grid>
              </Grid>
            </Grid>
            <Grid size={{ xs: 4 }}>
              <Card style={{ border: `1px solid ${greyBorderColor}` }}>
                <CardContent>
                  <Typography color="textPrimary">{t(translationKey.TitleTotalGameSessions)}</Typography>
                  <Grid container>
                    <Grid size={{ xs: 10 }}><Typography
                      style={{ fontSize: '30px', color: numberTextColor }}>{totalSessions}</Typography></Grid>
                    <Grid size={{ xs: 2 }}><GameAsset style={{ fontSize: '40px' }} /></Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid size={{ xs: 4 }}>
              <Card style={{ border: `1px solid ${greyBorderColor}` }}>
                <CardContent>
                  <Typography color="textPrimary">{t(translationKey.LabelAverageSessionTime)}</Typography>
                  <Grid container>
                    <Grid size={{ xs: 10 }}>
                      <TypographyAverageTime average={averageTime} />
                    </Grid>
                    <Grid size={{ xs: 2 }}><Timer style={{ fontSize: '40px' }} /></Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid size={{ xs: 4 }}>
              <Card style={{ border: `1px solid ${greyBorderColor}` }}>
                <CardContent>
                  <Typography color="textPrimary">{t(translationKey.LabelTotalTimePlaying)}</Typography>
                  <Grid container>
                    <Grid size={{ xs: 10 }}>
                      <TypographyHoursAndMinutes totalMinutes={totalMinutes} totalHours={totalHours} />
                    </Grid>
                    <Grid size={{ xs: 2 }}><Timer style={{ fontSize: '40px' }} /></Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid size={{ xs: 12 }}>
              <Card style={{ border: `1px solid ${greyBorderColor}` }}>
                <CardContent>
                  <Grid container>
                    <Grid size={{ xs: 8 }}>
                      <Typography color="textPrimary">{t(translationKey.LabelPlayersGameUsage, { player: player, timeframe: (usageIsMonthly ? 'monthly' : 'daily')})}</Typography>
                    </Grid>
                    <Grid size={{ xs: 1 }}>
                      <Typography align='right' color="textPrimary" style={{ marginRight: '5px' }}>{t(translationKey.LabelFilterBy)}</Typography>
                    </Grid>
                    <Grid size={{ xs: 3 }}>
                      <StyledSelectBase
                        value={timePeriod}
                        onChange={event => {
                          setTimePeriod(event.target.value);
                          setUserData({ ...userData, usage_data: null });
                        }}
                        variant='outlined'
                      >
                        {usageIntervals.map((item, index) => {
                          let itemString;
                          if (index === 0) {
                            itemString = `Current Month (${months[item.month - 1]})`;
                          } else if (index === 1) {
                            itemString = `Previous Month (${months[item.month - 1]})`;
                          } else if (item.month) {
                            itemString = months[item.month - 1];
                          } else {
                            itemString = item.year.toString();
                          }
                          return (<MenuItem value={JSON.stringify({ month: item.month, year: item.year })}>{itemString}</MenuItem>)
                        })}
                      </StyledSelectBase>
                    </Grid>
                  </Grid>
                  {usageIsMonthly ? <MonthlyUsage usage={usage} /> : <DailyUsage usage={usage} />}
                </CardContent>
              </Card>
            </Grid>

            {progress && progress.length > 0 && <Grid size={{ xs: 12 }}>
            <Typography color="textPrimary">{t(translationKey.TitlePlayerGameProgress, { player })}</Typography>
            <ol>
            {progress.map(goal => {
              let i = 0;
              const exposures = goal.inGame.concat(goal.outOfGame);
              return (<>
                <li>{goal.title}</li>
                { exposures.length > 0 &&
                <div>
                <Table size="small" aria-label={t(translationKey.AccessibilityLabelADenseTable)}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left"><strong>#</strong></StyledTableCell>
                      <StyledTableCell align="left"><strong>{t(translationKey.LabelDate)}</strong></StyledTableCell>
                      <StyledTableCell align="left"><strong>{t(translationKey.LabelChallenge)}</strong></StyledTableCell>
                      <StyledTableCell align="left"><strong>{t(translationKey.LabelBeforeChallenge)}</strong></StyledTableCell>
                      <StyledTableCell align="left"><strong>{t(translationKey.LabelDuringChallenge)}</strong></StyledTableCell>
                      <StyledTableCell align="left"><strong>{t(translationKey.LabelIfAskedToDoItAgain)}</strong></StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {exposures.map(exposure => {
                  return exposure.reflections && exposure.reflections.map(reflection => {
                  i++;
                  const date = new Date(reflection.date);
                  const dateString = date.toLocaleDateString();
                  return (
                    <TableRow key={i}>
                      <StyledTableCell component="th" scope="row">
                        {i + 1}
                      </StyledTableCell>
                      <StyledTableCell align="left">{dateString}</StyledTableCell>
                      <StyledTableCell align="left">{exposure.name}</StyledTableCell>
                      <StyledTableCell align="left">{reflection.results[0].score}</StyledTableCell>
                      <StyledTableCell align="left">{reflection.results[1].score}</StyledTableCell>
                      <StyledTableCell align="left">{reflection.results[2].score}</StyledTableCell>
                    </TableRow>
                  );
                  });
                })}
                </TableBody>

              </Table>
              {goal.change_date && <StyledChangeData>{t(translationKey.LabelGoalProgressEndedOn)}</StyledChangeData>} {goal.change_date && new Date(goal.change_date).toLocaleDateString()}
              <br></br>
              {goal.change_reason && <StyledChangeData>{t(translationKey.LabelReasonColon)}</StyledChangeData> } {goal.change_reason }
              </div>
            }
                </>);
            })}
            </ol>

            </Grid>}
          </>}
        </Grid>

        <StyledBottomBorder container direction="row" justify="space-between" alignItems="flex-start">
          <StyledHeadingGrid size={{ xs: 12 }}>
            <Typography variant="h6" align="left">
              {t(translationKey.TitleGoalBasedOutcomes, { name })}
            </Typography>
          </StyledHeadingGrid>
        </StyledBottomBorder>

        <StyledHeadingGrid size={{ xs: 12 }}>
          <Typography variant="span" align="left" color='textPrimary'>
            {t(translationKey.TitleClosenessToGoal)}
              </Typography>
        </StyledHeadingGrid>
        <StyledQuestionGrid size={{ xs: 12 }}>
          <Typography align="left" color='textPrimary'>
            {t(translationKey.ScaleZeroToTen)}
              </Typography>
        </StyledQuestionGrid>
        <StyledItalicGrid size={{ xs: 12 }}>
          <Typography align="left" color='textSecondary'>
            {t(translationKey.LabelScaleZeroExplanation)}
              </Typography>
          <Typography align="left" color='textSecondary'>
            {t(translationKey.LabelScaleTenExplanation)}
              </Typography>
        </StyledItalicGrid>
        <Grid size={{ xs: 12 }}><GBOResults responses={gboResponses} /></Grid>
      </StyledGBOContainerGrid>

      <GBOInformation/>

      {!userData['cors'] &&
      <Grid container direction='column' alignItems='flex-start' justify='center'>
        <StyledHeadingGrid size={{ xs: 12 }}>
          <Typography variant="h6" align="left">
            {t(translationKey.TitleGuardianAnxietySurveyResults, { name })}
              </Typography>
        </StyledHeadingGrid>
        <Grid size={{ xs: 12 }}><GuardianAnxietySurveyResults results={goalResults} /></Grid>
        <Table size="small" aria-label={t(translationKey.AccessibilityLabelADenseTable)}>
          <TableHead>
            <TableRow>
              <StyledTableCell align="left"><strong>#</strong></StyledTableCell>
              <StyledTableCell align="left"><strong>{t(translationKey.LabelSurveyName)}</strong></StyledTableCell>
              <StyledTableCell align="left"><strong>{t(translationKey.LabelDate)}</strong></StyledTableCell>
              <StyledTableCell align="left"><strong>{t(translationKey.LabelScore)}</strong></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {goalResults.map((goal, i) => {
              const date = new Date(goal.date);
              const dateString = date.toDateString();
              const parts = dateString.split(' ');
              const goalDate = [parts[2], parts[1], parts[3]].join(' ');
              return (
                <TableRow key={i}>
                  <StyledTableCell component="th" scope="row">
                    {i + 1}
                  </StyledTableCell>
                  <StyledTableCell align="left">{goal.goal_name}</StyledTableCell>
                  <StyledTableCell align="left">{goalDate}</StyledTableCell>
                  <StyledTableCell align="left">{goal.final_score}</StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Grid>}

      {
        userData['cors'] && corsSurveys && corsSurveys.length > 0 &&
        <Grid container direction='column' alignItems='flex-start' justify='center'>
        <StyledHeadingGrid size={{ xs: 12 }}>
          <Typography variant="h6" align="left">
            {t(translationKey.TitleCorsResult, { name })}
          </Typography>
          <Typography align="left" style={{color: 'grey'}}>
            {t(translationKey.TitleCompletedSurveysColon)}
          </Typography>
        </StyledHeadingGrid>
        <Grid size={{ xs: 12 }}><CORSSurveyResults results={corsSurveys} /></Grid>
        <Table size="small" aria-label={t(translationKey.AccessibilityLabelADenseTable)}>
          <TableHead>
            <TableRow>
              <StyledTableCell align="left"><strong>#</strong></StyledTableCell>
              <StyledTableCell align="left"><strong>{t(translationKey.LabelChildCorsCompletedOn)}</strong></StyledTableCell>
              <StyledTableCell align="left"><strong>{t(translationKey.LabelScore)}</strong></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {corsSurveys.map((survey, i) => {
              const date = new Date(survey.survey_date);
              const dateString = date.toDateString();
              const parts = dateString.split(' ');
              const goalDate = (i === 0 ) ? t(translationKey.LabelBaseline) + [parts[2], parts[1], parts[3]].join(' ') : [parts[2], parts[1], parts[3]].join(' ');
              return (
                <TableRow key={i}>
                  <StyledTableCell component="th" scope="row">
                    {i + 1}
                  </StyledTableCell>
                  <StyledTableCell align="left">{goalDate}</StyledTableCell>
                  <StyledTableCell align="left">{survey.total}</StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <StyledShowHideButton variant="contained" color="primary"
                        onClick={ () => {
                          setShowResults(!showResults)
                        } } style={{textTransform: 'uppercase'}}>
                  {showResults ? t(translationKey.ButtonHide) : t(translationKey.ButtonShow)} {t(translationKey.ButtonCompletedSurveys)}
        </StyledShowHideButton>

        {showResults &&
          corsSurveys.map((survey, i) => {
            const date = new Date(survey.survey_date);
            const dateString = date.toDateString();
            const goalDate = (i === 0 )
            ? t(translationKey.LabelCorsBaselineCompletedOn, { date: dateString})
            : t(translationKey.LabelCorsCompletedOn, { date: dateString})
            return (
              <div>
                <Typography align="left" style={{ fontWeight: 600, width: '500px' }}> {i + 1}. {goalDate} </Typography>
                <Table>
                  <TableBody>
                  {
                    Object.entries(survey.answers).map(([key, value]) =>
                      <TableRow>
                        <StyledTableCell style={{border: 'none', padding: 0, paddingLeft: '25px'}} align="left">{key.trim()}</StyledTableCell>
                        <StyledTableCell style={{border: 'none', padding: 0}} align="left">{value.trim()}</StyledTableCell>
                      </TableRow>
                      )
                  }
                  <Typography align="left">{t(translationKey.LabelTotalSurveyScore, { total: survey.total })}</Typography>
                  </TableBody>
                </Table>
              </div>
            );
          })
        }

        <CORSInformationCard/>
      </Grid>
      }


    </Container>
  );
}
