import React from 'react';
import { axios, catchAxios } from '../../services/networkRequest';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import { Formik } from 'formik';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { getChain } from '../../services/helpers';
import * as PropTypes from 'prop-types';
import Error from '../Error';
import RequiredInfoText from '../RequiredInfoText';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import update from 'immutability-helper';
import { helperTextColor } from '../../constants';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../utilities/localisation/translationKeys';
import { useNavigate } from 'react-router-dom';

const phoneUtil = PhoneNumberUtil.getInstance();

function DistributorForm({ distributor, title, path }) {
  const method = distributor ? 'post' : 'put';
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Typography component="h1" variant="h5">
        { title }
      </Typography>
      <RequiredInfoText />
      <Formik
        initialValues={ {
          name: getChain(distributor, 'name'),
          address: getChain(distributor, 'address'),
          email: getChain(distributor, 'email'),
          phone: getChain(distributor, 'phone'),
        } }
        validate={ values => {
          const errors = {};
          if (!values.name) {
            errors.name = t(translationKey.ErrorSpecifyDistributorName);
          }
          if (!values.address) {
            errors.address = t(translationKey.ErrorSpecifyDistributorAddress);
          }
          if (!values.email) {
            errors.email = t(translationKey.ErrorRequired);
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = t(translationKey.ErrorInvalidEmail);
          }
          if (!values.phone) {
            errors.phone = t(translationKey.ErrorSpecifyPhoneNumber);
          } else {
            try {
              const number = phoneUtil.parseAndKeepRawInput(values.phone, 'GB');
              if (!(phoneUtil.isPossibleNumber(number) && phoneUtil.isValidNumber(number))) {
                errors.phone = t(translationKey.ErrorValidPhoneNumber);
              }
            } catch (err) {
              errors.phone = t(translationKey.ErrorValidPhoneNumber);
            }
          }

          return errors;
        } }
        onSubmit={ (values, { setSubmitting, setFieldError }) => {
          setSubmitting(true);
          // Convert phone number into international format
          const number = phoneUtil.parseAndKeepRawInput(values.phone, 'GB');
          const internationalNumber = phoneUtil.format(number, PhoneNumberFormat.E164);
          const updatedValues = update(values, {
            phone: { $set: internationalNumber },
          });
          axios[method](path, updatedValues)
            .then(() => {
              navigate(-1);
            })
            .catch(err => {
              catchAxios(setFieldError)(err);
              setSubmitting(false);
            });
        } }
      >
        { ({
             errors,
             values,
             touched,
             handleChange,
             handleBlur,
             handleSubmit,
           }) => (
          <form onSubmit={ handleSubmit }>
            <Error message={ errors['network'] } />
            <TextField
              type="text"
              variant="outlined"
              margin="normal"
              fullWidth
              autoFocus
              label={t(translationKey.LabelDistributorName)}
              value={ values.name }
              required
              name='name'
              onChange={ handleChange }
              onBlur={ handleBlur }
              helperText={ errors.name && touched.name && errors.name }
              slotProps={{ formHelperText: { style: { color: helperTextColor } } }}
            />

            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name='address'
              type="text"
              value={ values.address }
              label={t(translationKey.LabelDistributorAddress)}
              required
              onChange={ handleChange }
              onBlur={ handleBlur }
              helperText={ errors.address && touched.address && errors.address }
              slotProps={{ formHelperText: { style: { color: helperTextColor } } }}
            />

            <TextField
              type="text"
              variant="outlined"
              margin="normal"
              fullWidth
              value={ values.email }
              label={t(translationKey.LabelDistributorAddress)}
              required
              name='email'
              onChange={ handleChange }
              onBlur={ handleBlur }
              helperText={ errors.email && touched.email && errors.email }
              slotProps={{ formHelperText: { style: { color: helperTextColor } } }}
            />

            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              type="tel"
              value={ values.phone }
              label={t(translationKey.LabelDistributorPhone)}
              required
              name='phone'
              onChange={ handleChange }
              onBlur={ handleBlur }
              helperText={ errors.phone && touched.phone && errors.phone }
              slotProps={{ formHelperText: { style: { color: helperTextColor } } }}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary">{t(translationKey.ButtonSubmit)}</Button>
          </form>) }
      </Formik>
    </Container>
  );
}

DistributorForm.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  distributor: PropTypes.object,
};

export default DistributorForm;
